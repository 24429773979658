<template>
    <router-link
        class="text-none"
        :to="{ name: 'UsersEdit', params: { id: driver.id } }"
        @click="$emit('closeMenu')">
        <article class="about-driver-info">
            <User width="16" height="16" />
            <div class="text-container">
                <p class="text">{{ driver.fio }}</p>
                <p class="text">Водитель миксера</p>
            </div>
        </article>
    </router-link>
</template>

<script>
import { User } from '@element-plus/icons-vue';

export default {
    name: "AboutDriverInfo",
    components: { User },
    props: ['driver'],
}
</script>
