<template>
    <div>
        <div class="textMedium textSM neutral900 mb-10">{{ event === 'smartMixCreate' ? 'Отгрузка создана на БСУ' : 'Ошибка создания отгрузки на БСУ'}}</div>
        <div>
            <div class="neutral900 textRegular textXS mb-5" v-for="(value, index) in values" :key="index">
                <span class="neutral300 mr-5">{{ value.name }}:</span>
                <span class="text-line-through mr-5 neutral100" v-if="value.oldData">{{ value.oldData }}</span>
                <span>{{ value.data }}</span>
            </div>
        </div>
    </div>
</template>

<script>

import functionsMixin from "@/mixins/functionsMixin";

export default {
    name: "MixOrderDetailsGroupLogSmartMixCreate",
    props: ['log', 'event'],
    mixins: [functionsMixin],
    computed: {
        values() {
            let elems = []

            this.log.values.filter(el => el.new).forEach(el => {
                let name = null
                let data = null
                let oldData = null

                switch (el.key) {
                    case 'comment':
                        name = 'Комментарий';
                        data = el.value;
                        break;
                    case 'doc':
                        name = '№ в программе оператора';
                        data = el.value;
                        break;
                }

                if (name) {
                    elems.push({
                        name: name,
                        data: data ?? '-',
                        oldData: oldData ?? null
                    })
                }
            })

            return elems
        }
    }
}
</script>

<style scoped>

</style>
