<template>
    <Auth title="Заказы" :tabs="tabs">
        <div class="card dispatch-order-groups-create">
            <div class="card-body">

                <div v-if="!loadingButtons.distribute">
                    <div class="primary500 textLG textMedium mt-15 mb-15">
                        Новый заказ
                    </div>

                    <MixOrderGroupCreateForm
                        v-model="form"
                        :mixers="mixers"
                        :disable-form="disableForm"
                        @getOrderGroupInfoCreate="getOrderGroupInfoCreate"
                    />

                    <hr class="mb-30"/>

                    <el-button
                        v-loading="loadingButtons.create"
                        :disabled="loadingButtons.create"
                        type="primary"
                        @click="createOrderGroup('unconfirmed')"
                    >
                        Сохранить неподтвеждёным
                    </el-button>

                    <el-button
                        v-if="form.delivery_type === 'delivery'"
                        v-loading="loadingButtons.distribute"
                        :disabled="loadingButtons.distribute"
                        @click="distributeOrder"
                    >
                        Подтвердить и распределить
                    </el-button>

                    <el-button
                        v-if="form.delivery_type === 'take-away'"
                        @click="createOrderGroup('confirmed')"
                    >
                        Сохранить и подтвердить
                    </el-button>
                </div>
                <div v-else>
                    <DistributeLoader :total="form.total"/>
                </div>
            </div>
        </div>
    </Auth>
</template>
<script>
import {
    mapState,
    mapStores,
    mapActions
} from 'pinia';
import {useCommonStore} from "@/store/common";

import tableMixin from '@/mixins/tableMixin'
import functionsMixin from '@/mixins/functionsMixin'
import iconsMixin from "@/mixins/iconsMixin";
import mobileCheckMixin from "@/mixins/mobileCheckMixin";
import paymentMethods from "@/mixins/paymentMethods";

import moment from 'moment/moment'
import Auth from '@/views/layouts/Auth.vue'
import {useMixStore} from '@/store/mix';

import {httpService} from "@/services/http.service";
import {useOrderGroupsStore} from "@/store/orderGroups";
import mixOrderGroupFormData from "@/mixins/mixOrderGroupFormData";
import MixOrderGroupCreateForm from "@/views/components/MixOrderGroup/MixOrderGroupCreateForm.vue";
import CRMTabs from "@/mixins/tabs/CRMTabs";
import DistributeLoader from "@/views/components/MixOrderGroup/DistributeLoader.vue";

export default {
    name: 'CRMOrderCreate',
    mixins: [tableMixin, functionsMixin, iconsMixin, mobileCheckMixin, CRMTabs, paymentMethods, mixOrderGroupFormData],
    inject: ['api'],
    components: {
        DistributeLoader,
        MixOrderGroupCreateForm,
        Auth,
    },
    data() {
        return {
            mixers: [],
            loadingButtons: {
                create: false,
                distribute: false,
            },
            moreInfo: true,
            vehicles: [],
            orders: [],
            timeInfo: {
                toObject: 0,
                return: 0,
                loading: 0,
                unloading: 0
            },
            disableForm: false
        }
    },
    created() {
        if (!this.$route.params?.change) {
            this.clearOrderGroupCreateFormData()
        }

        this.form = this.orderGroupForm
        this.form.date_shipment = this.filters['filter[date]']

        if (this.form.first_order_time_delivery) {
            this.form.first_order_time_delivery = moment(this.form.first_order_time_delivery).format('HH:mm:ss')
            this.form.date_shipment = moment(this.form.date_shipment)
        } else {
            this.form.date_shipment = this.getDate
        }

        this.getSchedules()
    },
    watch: {
        'form': {
            handler: function () {
                if (this.form.buyer_company_id && this.form.company_manager_id) {
                    this.pullCompanies()
                }
            },
            deep: true,
            immediate: true
        },
    },
    methods: {
        ...mapActions(useOrderGroupsStore, ['setOrderGroupCreateFormData', 'clearOrderGroupCreateFormData']),
        distributeOrder(data) {
            this.loadingButtons.distribute = true
            let formData = {...data, ...this.form}

            if (formData.first_order_time_delivery) {
                formData.first_order_time_delivery = moment(`${this.form.date_shipment.substring(0, 10)} ${formData.first_order_time_delivery}`).format('YYYY-MM-DD HH:mm:ss')
            }
            // else {
            //     formData.first_order_time_delivery = moment(this.form.date_shipment).format('YYYY-MM-DD')
            // }

            formData.status = 'confirmed'

            this.setOrderGroupCreateFormData(formData)

            httpService().post(this.api.dispatch.orderGroups.storeDistribute, {
                account_modules_id: this.$route.params.id,
                module_key: this.$route.meta.moduleKey,
                form: formData
            }).then(({data}) => {
                // const { id } = data.data
                this.$router.push({
                    name: 'CRMDistributeOrderGroup', params: {
                        id: this.$route.params.id,
                        hash: data.jobHash
                    }
                });

            }).catch((error) => {
                if (this.form.first_order_time_delivery !== undefined) {
                    this.form.first_order_time_delivery = moment(formData.first_order_time_delivery).format('HH:mm')
                    if (this.form.first_order_time_delivery == '00:00') {
                        this.form.first_order_time_delivery = null
                    }
                } else {
                    this.form.first_order_time_delivery = null
                }

                const {errors} = error.response.data
                for (let key in errors) {
                    if (Object.prototype.hasOwnProperty.call(errors, key)) {
                        errors[key].forEach(errorMessage => {
                            this.$message({
                                message: errorMessage,
                                showClose: true,
                                type: 'error'
                            });
                        });
                    }
                }
                this.loadingButtons.distribute = false
            })
        },
        createOrderGroup(status) {

            this.loadingButtons.create = true
            let formData = this.form


            if (formData.first_order_time_delivery) {
                formData.first_order_time_delivery = moment(`${this.form.date_shipment.substring(0, 10)} ${formData.first_order_time_delivery}`).format('YYYY-MM-DD HH:mm:ss')
            }
            // else {
            //     formData.first_order_time_delivery = moment(this.form.date_shipment).format('YYYY-MM-DD')
            // }

            if (formData.delivery_type !== 'delivery') {
                formData.delivery_address_id = null
                formData.company_manager_id = null
                formData.first_order_time_delivery = null
                formData.type_delivery = 'withoutInterval'
                formData.time_unloading = null
                formData.axle = null
                formData.max_load = false
                formData.pump = false
                formData.pump_vehicle_id = null
                formData.pump_price = null
                formData.pump_cost_type = null
                formData.custom_mixers_enabled = false
            }

            formData.status = status

            httpService().post(this.api.dispatch.orderGroups.createOrderGroups, {
                account_modules_id: this.$route.params.id,
                module_key: this.$route.meta.moduleKey,
                form: formData
            }).then(() => {

                this.$message({
                    message: 'Заказ создан',
                    showClose: true,
                    type: 'success'
                })

                this.filters['filter[date]'] = moment(formData.date_shipment).format('YYYY-MM-DD')

                this.clearOrderGroupCreateFormData()

                setTimeout(() => this.$router.push({name: 'CRMOrders', params: {id: this.$route.params.id}}), 50)
            }).catch((error) => {

                if (this.form.first_order_time_delivery !== undefined) {
                    this.form.first_order_time_delivery = moment(formData.first_order_time_delivery).format('HH:mm')
                    if (this.form.first_order_time_delivery == '00:00') {
                        this.form.first_order_time_delivery = null
                    }
                } else {
                    this.form.first_order_time_delivery = null
                }

                const {errors} = error.response.data
                for (let key in errors) {
                    if (Object.prototype.hasOwnProperty.call(errors, key)) {
                        errors[key].forEach(errorMessage => {
                            this.$message({
                                message: errorMessage,
                                showClose: true,
                                type: 'error'
                            });
                        });
                    }
                }
                this.loadingButtons.create = false
            })
        },
        getSchedules() {
            httpService().post(this.api.dispatch.schedules.getPosts, {
                account_modules_id: this.$route.params.id,
                module_key: this.$route.meta.moduleKey,
                date: this.filters['filter[date]']
            }).then((response) => {
                let data = response.data;

                if (data.success) {
                    this.mixers = response.data.posts
                    if (!this.$route.params?.change) {
                        this.form.mixer_ids = response.data.mixer_ids
                        this.form.mix_ids = response.data.account_module_mix_ids
                        this.form.account_module_ids = response.data.account_module_mix_ids
                    }
                }
            })
        },
        getOrderGroupInfoCreate(buyerCompanyId) {
            this.disableForm = true
            let accountModulesId = this.$route.params.id
            const free_unload_time = this.companies.find((c) => c.id == this.form.buyer_company_id)?.free_unload_time

            httpService().post(this.api.dispatch.getLast, {
                account_modules_id: accountModulesId,
                module_key: this.$route.meta.moduleKey,
                buyer_company_id: buyerCompanyId
            }).then((response) => {
                let {data} = response.data
                if (data && !this.form.good_id) {
                    this.form.good_id = data.good_id
                    if (data.good_price && data.good_price !== 0) this.form.good_price = data.good_price
                    if (data.delivery_price && data.delivery_price !== 0) this.form.delivery_price = data.delivery_price
                    this.form.payment_method = data.payment_method
                    this.form.good = data.good
                    this.form.companyGoods = data.goods
                    this.form.delivery_address_id = data.delivery_address_id
                    this.form.company_manager_id = data.company_manager_id
                    this.form.seller_company_id =  this.form.seller_company_id ?? data.seller_company_id
                    this.form.carrier_company_id =  this.form.carrier_company_id ?? data.carrier_company_id
                    this.form.time_unloading = data.time_unloading
                    this.form.contract_id = data.contract_id
                    this.form.invoice_id = data.invoice_id
                }
            }).finally(() => {
                console.log('time_unloading', this.form.time_unloading);
                this.form.time_unloading = this.form.time_unloading ?? free_unload_time
                this.disableForm = false
            })
        }
    },
    computed: {
        ...mapState(useCommonStore, {
            companies: 'getCompanies',
            user: 'getUser',
            goods: 'getGoods',
        }),
        ...mapState(useOrderGroupsStore, {
            getDate: 'getDate',
            filters: 'getFilters',
            orderGroupForm: 'getOrderGroupCreateForm',
        }),
        ...mapStores(useMixStore),
    },
}
</script>
<style scoped lang="scss">
</style>

<style lang="scss">

</style>
