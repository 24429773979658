import moment from 'moment/moment'

export default {
    data() {
        return {
            activeTab: 'delivery',
            form: {
                delivery_price_manual_edit: false,
                contract_id: null,
                invoice_id: null,
                companyGoods: [],
                good_name: null,
                payment_method: null,
                delivery_price: null,
                time_free_unloading: null,
                pay_time_unloading: null,
                time_unloading: null,
                gidrolotok: false,
                good_price: null,
                constructive: null,
                lab: false,
                spec_good_id: null,
                delivery_price_type: 'auto',
                good_id: null,
                manager_id: null,
                carrier_company_id: null,
                date_shipment: moment().utcOffset(0, true).startOf('day'),
                delivery_type: 'delivery',
                individual: false,
                buyer_company_id: null,
                total: null,
                comment: null,
                orders_count: 0,
                delivery_address_id: null,
                company_manager_id: null,
                delivery_difficulty: 1,
                delivery_difficulty_new: 1,
                delivery_difficulty_old: 1,
                prepayment: null,
                pump: false,
                pump_vehicle_id: null,
                pump_price: null,
                pump_cost_type: null,
                comment_pump_driver: null,
                mixers: [],
                seller_company_id: null,
                type_delivery: 'withoutInterval',
                type_mixers_count: 'many',
                mix_ids: [],
                mixer_max_volume: null,
                axle: null,
                mix_service_tariff_id: null,
                pump_service_tariff_id: null,
                downtime_service_tariff_id: null
            },
        }
    },
}
