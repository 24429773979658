<template>
    <div class="mobileTableDate d-flex">
        <div class="mobileTableDateTime" v-if="!title">
            <TimeIcon/>
            {{ time ? time :date ? getDateFromUnix(date, 'HH:mm') : '' }}
        </div>
        <div class="mobileTableDateTime" v-else>{{ title }}</div>
        <div class="mobileTableDateDate">
            <DateIcon/>
            {{ day ? day : date ? getDateFromUnix(date, dateformat) : '' }}
        </div>
    </div>
</template>

<script>
import functionsMixin from '@/mixins/functionsMixin'
import TimeIcon from '@/views/components/Icons/TimeIcon'
import DateIcon from '@/views/components/Icons/DateIcon'

export default {
    name: "MobileTableDate",
    components: {DateIcon, TimeIcon},
    props: [
        'date',
        'time',
        'day',
        'title',
        'format'
    ],
    mixins: [
        functionsMixin
    ],
    data() {
        return {
            dateformat: this.format ?? 'DD.MM.YY'
        }
    }
}
</script>

<style scoped>

</style>
