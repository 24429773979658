<template>
    <div class="widget-card cashbox-widget">
        <div class="widget-card-title">{{ title }}
            <el-button class="widget-reload" :loading="loadingButton" @click="onUpdate" :icon="icons.refresh"
                       circle></el-button>
        </div>
        <div class="widget-card-body" v-loading="loading">
            <div class="widget-header-text">
                <h2>
                    <number
                        ref="count"
                        :to="total.balance"
                        :format="theFormat"
                        :duration="1"
                        :delay="0"
                        easing="Power4.easeOut"/>
                    руб.
                </h2>
                <h5>Статистика за неделю</h5>
                <div class="weekAll mb-5">
                    <span>Поступлений на общую сумму</span>
                    <span class="income">+ <number
                        ref="count"
                        :to="total.incomeAll"
                        :format="theFormat"
                        :duration="1"
                        :delay="0"
                        easing="Power4.easeOut"/> руб.</span>
                </div>
                <div class="weekAll mb-20">
                    <span>Списаний на общую сумму</span>
                    <span class="spending">- <number
                        ref="count"
                        :to="total.spendingAll"
                        :format="theFormat"
                        :duration="1"
                        :delay="0"
                        easing="Power4.easeOut"/> руб.</span>
                </div>
                <h5 class="mb-5">Статистика за неделю по дням</h5>
            </div>
            <div v-if="(total.incomeAll + total.spendingAll) > 0 || firstUpdate === 0">
                <div class="cashbox-chart">
                    <v-chart v-model="chart" class="chart" :option="option" autoresize/>
                </div>
                <div class="col cashboxScroll">
                    <div class="operation" v-for="(value, index) in values" :key="index">
                        <h3>{{ getDateFromUnix(value.date, 'DD.MM') }}</h3>
                        <div class="item" v-for="(operation, index) in value.operations" :key="index">
                            <div>
                                <p class="title">{{ operation.name }}</p>
                                <p class="description">{{ operation.comment ?? '-' }}</p>
                            </div>
                            <div>
                                <p v-if="operation.income" class="value income">+
                                    {{ theFormat(operation.value) }}
                                    руб.</p>
                                <p v-else class="value spending">-
                                    {{ theFormat(operation.value) }}
                                    руб.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div v-if="(total.incomeAll + total.spendingAll) === 0 && firstUpdate === 1">
                <div class="no-data-block">
                    <NoDataIcon/>
                    <p>
                        Денежных операций не найдено<br>Информация актуальна с 00:00 до {{ time }}
                    </p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import VChart from 'vue-echarts'
import NoDataIcon from '../../components/NoDataIcon'
import functionsMixin from '@/mixins/functionsMixin'
import { httpService } from '@/services/http.service'
import iconsMixin from "@/mixins/iconsMixin";

export default {
    props: [
        'id',
    ],
    inject: [
        'api'
    ],
    components: {
        VChart,
        NoDataIcon
    },
    mixins: [
        functionsMixin, iconsMixin
    ],
    created() {
        this.loadingButton = true
        this.widgetGet(this.id)
        window.setInterval(() => {
            this.loadingButton = false
        }, 800)
        this.counterInterval = setInterval(
            function () {
                this.updateWidget()
            }.bind(this), Math.floor(Math.random() * (10000 - 8000 + 1)) + 8000)
    },
    updated() {

    },
    beforeUnmount: function () {
        clearInterval(this.counterInterval)
    },
    data() {
        return {
            data: [],
            loadingButton: true,
            loading: true,
            total: {
                balance: null,
                incomeAll: null,
                spendingAll: null
            },
            chartDetails: {
                days: [],
                weekIncome: [],
                weekSpending: []
            },
            values: [],
            chart: 1,
            count: 0,
            time: null,
            countUnit: null,
            firstUpdate: 0,
            title: 'Денежный учет'
        }
    },
    methods: {
        theFormat(value) {
            let val = (value / 1).toFixed(0).replace('.', ',')
            return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
        },
        widgetGet(id) { // метод получения данных виджета с api
            // обращаемся в api, передаем id виджета
            httpService().post(this.api.widget.getOne, {
                id: id
            }).then((response) => {
                    // получаем массив с api
                    let data = response.data
                    if (data.success)

                        this.total.balance = data.data.total.balance
                    this.total.incomeAll = data.data.total.incomeAll
                    this.total.spendingAll = data.data.total.spendingAll

                    this.chartDetails.days = data.data.chartDetails.days.reverse()
                    this.chartDetails.weekIncome = data.data.chartDetails.weekIncome.reverse()
                    this.chartDetails.weekSpending = data.data.chartDetails.weekSpending.reverse()
                    this.values = data.data.values
                    this.title = data.widgetName
                    this.firstUpdate = 1
                    this.time = data.time

                    this.loading = false

                }
            )
        },
        onUpdate() {
            this.loadingButton = true
            this.updateWidget()
            window.setInterval(() => {
                this.loadingButton = false
            }, 800)
        },
        updateWidget() // метод обновления виджета
        {
            this.widgetGet(this.id)
        },
    },
    computed: {
        option() {
            return {
                backgroundColor: 'transparent',
                tooltip: {
                    trigger: 'axis',
                    backgroundColor: '#181b3b',
                    borderColor: '#181b3b',
                    textStyle: {
                        color: '#fff'
                    },
                    axisPointer: {
                        type: 'shadow'
                    }
                },
                grid: {
                    left: -20,
                    right: 15,
                    bottom: 10,
                    containLabel: true,
                    height: 150
                },
                xAxis: [
                    {
                        type: 'category',
                        data: this.chartDetails.days,
                        shadowColor: 'rgba(172,34,34,0.3)',
                        borderColor: 'transparent',
                        axisTick: {
                            alignWithLabel: false
                        },
                        axisLabel: {
                            textStyle: {
                                color: '#ACAFC6'
                            }
                        },
                        axisLine: {
                            show: false
                        },
                        splitLine: {
                            show: true,
                            lineStyle: {
                                type: 'dashed',
                                color: '#2C2F4A'
                            }
                        }
                    }
                ],
                yAxis: [
                    {
                        show: false
                    }
                ],

                series: [
                    {
                        name: 'Поступления',
                        type: 'bar',
                        barWidth: 12,
                        data: this.chartDetails.weekIncome,
                        itemStyle: {
                            color: '#00cdc4',
                            shadowColor: 'rgba(0, 211, 203, 0.5)',
                            borderRadius: 4,
                            shadowBlur: 12,
                            shadowOffsetY: 0,
                        },
                    },
                    {
                        name: 'Списания',
                        type: 'bar',
                        barWidth: 12,
                        data: this.chartDetails.weekSpending,
                        itemStyle: {
                            color: '#ff5253',
                            shadowColor: 'rgba(255, 92, 94, 0.5)',
                            borderRadius: 4,
                            shadowBlur: 12,
                            shadowOffsetY: 0,
                        },
                    },
                ]
            }
        }
    },
}
</script>

<style scoped>
</style>
