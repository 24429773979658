<template>
    <div id="y-map-details" class="y-map"></div>
</template>

<script setup>
import {defineProps, onMounted, watch} from "vue";
import {loadYmap} from "vue-yandex-maps";

const props = defineProps({
    coorLatFrom: {
        default: () => null,
    },
    coorLonFrom: {
        default: () => null,
    },
    coorLatTo: {
        default: () => null,
    },
    coorLonTo: {
        default: () => null,
    },
    vehicle: {
        default: () => null,
    },
});

let map = null;

const init = async () => {
    const settings = {
        apiKey: 'c0f99ecd-989a-47c0-a38b-4a9b08e992e4',
        lang: 'ru_RU',
        coordorder: 'latlong',
        enterprise: false,
        version: '2.1'
    };
    await loadYmap({...settings, debug: true});
    // eslint-disable-next-line no-undef
    await ymaps.ready;

    // задаем центр карты по адресу доставки
    // eslint-disable-next-line no-undef
    map = new ymaps.Map(document.getElementById("y-map-details"), {
        center: [props.coorLatTo, props.coorLonTo],
        zoom: 10,
    });

    displayMapContent();
};

const displayMapContent = () => {
    if (map) {
        map.geoObjects.removeAll();

        console.log([props.coorLatTo, props.coorLonTo], [props.coorLatFrom, props.coorLonFrom])
        // eslint-disable-next-line no-undef
        ymaps.route([
            {
                type: 'wayPoint',
                point: [props.coorLatFrom, props.coorLonFrom]
            },
            {
                type: 'wayPoint',
                point: [props.coorLatTo, props.coorLonTo]
            }
        ], {
            mapStateAutoApply: true
        }).then(function (route) {
            map.geoObjects.add(route);
            route.getPaths().options.set({
                strokeColor: '#1984e6',
                opacity: 0.8
            })

            route.getWayPoints().options.set({
                visible: false
            })
        });

        // Выводим завод
        // eslint-disable-next-line no-undef
        const factoryPoint = new ymaps.Placemark([props.coorLatFrom, props.coorLonFrom], {
            iconContent: 'Завод'
        }, {
            preset: "islands#blueFactoryCircleIcon"
        });
        map.geoObjects.add(factoryPoint);

        // Выводим клиентский адрес
        // eslint-disable-next-line no-undef
        const clientPoint = new ymaps.Placemark([props.coorLatTo, props.coorLonTo], {
            iconContent: 'Объект'
        }, {
            preset: "islands#redGovernmentCircleIcon"
        });
        map.geoObjects.add(clientPoint);

        // Выводим транспортные средства на карте
        if (props.vehicle) {
            // eslint-disable-next-line no-undef
            const myPlacemark = new ymaps.Placemark([props.vehicle.latitude, props.vehicle.longitude], {
                iconContent: props.vehicle.number
            }, {
                preset: `islands#${props.vehicle.color}StretchyIcon`
            });
            map.geoObjects.add(myPlacemark);
        }
    }
};

watch(() => props.vehicle, () => {
    displayMapContent();
});

onMounted(() => init());
</script>

<style lang="scss" scoped>
.y-map {
    height: 85vh;
    width: 100%;
}
</style>

<style lang="scss">
.yandex-container {
    width: 100%;
    height: 100%;
}
</style>
