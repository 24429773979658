<template>
    <Auth title="Редактирование пользователя" :reloadNav="reloadNav">
        <div class="card" v-loading="loading">
            <div class="card-body">
                <el-form v-model="user" label-position="top" @submit.prevent>
                    <el-tabs v-model="activeTabName" class="moduleInfoTabs mb-15">
                        <el-tab-pane name="info" label="Личные данные">
                            <h3>Личные данные пользователя</h3>
                            <el-row gutter="15">
                                <el-col :sm="12">
                                    <el-form-item label="ФИО:">
                                        <el-input v-model="user.name" placeholder="Введите имя"
                                                  :disabled="roles.length === 0"></el-input>
                                    </el-form-item>
                                </el-col>
                                <el-col :sm="12">
                                    <el-form-item label="Логин:">
                                        <el-input v-model="user.login" placeholder="Введите логин"
                                                  :disabled="roles.length === 0"></el-input>
                                    </el-form-item>
                                </el-col>
                                <el-col :sm="12">
                                    <el-form-item label="Электронная почта:">
                                        <el-input v-model="user.email" placeholder="Введите почту"
                                                  :disabled="roles.length === 0"></el-input>
                                    </el-form-item>
                                </el-col>
                                <el-col :sm="12">
                                    <el-form-item label="Телефон:">
                                        <el-input v-model="user.phone" autocomplete="chrome-off"
                                                  placeholder="Введите телефон"
                                                  :disabled="roles.length === 0"></el-input>
                                    </el-form-item>
                                </el-col>
                            </el-row>
                        </el-tab-pane>

                        <el-tab-pane v-if="user.roleKey === 'mixDriver'" name="calendar" label="График работы">
                            <h3>График работы</h3>

                            <FormSelect
                                v-model="user.info.calendar"
                                label="Тип графика"
                                placeholder="Не выбрано"
                                :items="calendarTypes"
                            />

                            <FormSelect
                                v-if="user.info.calendar === '5/2'"
                                v-model="user.info.firstWorkDay"
                                label="Первый рабочий день на неделе"
                                placeholder="Не выбран"
                                :items="daysOfWeek"
                            />

                            <FormDateTimePicker
                                v-if="user.info.calendar === '2/2'"
                                v-model="user.info.dateOfWorkStart"
                                placeholder="Первый рабочий день"
                            />

                            <FormNumber
                                v-if="user.info.calendar"
                                v-model="user.info.workHoursInDay"
                                label="Кол-во часов в смену"
                                placeholder="Не указано"
                            />

                        </el-tab-pane>

                        <el-tab-pane v-if="loggedUser.role_id === 1 || loggedUser.role_id === 2" name="access" label="Доступы">
                            <h3>Настройка доступов</h3>
                                <el-row :gutter="15">
                                    <el-col :sm="8">
                                        <h4>Модули и разделы</h4>
                                        <el-checkbox-group class="inlineCheckbox" v-model="user.modules" style="line-height: 32px;">
                                            <el-checkbox v-for="module in modules" :key="module.id" :label="module.id">
                                                {{ module.name }}
                                            </el-checkbox>
                                        </el-checkbox-group>
                                        <el-checkbox v-model="user.analytics_available">
                                            Раздел аналитика
                                        </el-checkbox>
                                    </el-col>
                                    <el-col :sm="8">
                                        <h4>Отчеты</h4>
                                        <el-checkbox v-model="user.reports_available">
                                            Раздел отчёты
                                        </el-checkbox>

                                        <el-checkbox-group class="inlineCheckbox ml-10" v-if="user.reports_available"
                                                           v-model="user.reports" style="line-height: 32px;">
                                            <template v-for="report in filteredReports()" :key="report.id">
                                                <el-checkbox :label="report.id">
                                                    {{ report.name }}
                                                </el-checkbox>
                                                <el-checkbox-group class="inlineCheckbox ml-10"
                                                                   v-if="checkCheckbox(report).length"
                                                                   v-model="user.reportFunctions" style="line-height: 32px;">
                                                    <el-checkbox v-for="reportFunction in allReportFunctions" :key="reportFunction.id" :label="reportFunction.id">
                                                        {{ reportFunction.name }}
                                                    </el-checkbox>

                                                </el-checkbox-group>
                                            </template>
                                        </el-checkbox-group>
                                    </el-col>
                                </el-row>

                        </el-tab-pane>

                        <el-tab-pane v-if="loggedUser.role_id === 1" name="role" label="Роль пользователя">
                            <h3>Настройка роли пользователя в системе</h3>
                            <div>
                                <el-form-item>
                                    <el-radio-group class="userRoles" v-model="user.role" size="small"
                                                    :disabled="initialRole === 'Владелец'">
                                        <el-radio v-for="role in roles" :key="role.name" :label="role.name"
                                                  :disabled="role.name === 'Владелец'" border><span
                                            class="title">{{
                                                role.name
                                            }}</span>
                                            <span class="description">
                                                    {{ role.description }}
                                                </span></el-radio>
                                    </el-radio-group>
                                </el-form-item>
                            </div>
                        </el-tab-pane>

                        <el-tab-pane name="widgets" label="Рабочий стол">
                            <h3>Настройка рабочего стола</h3>
                            <div>
                                <WidgetSettings :modules="modules" :widgets="widgets" @userWidgets="getWidgets"/>
                            </div>
                        </el-tab-pane>

                        <el-tab-pane name="payment" label="Размер оплаты">
                            <h3>Размер оплаты</h3>
                            <div>
                                <el-row :gutter="15">
                                    <el-col :sm="12">
                                        <el-form-item label="Оплата за доставку">
                                            <el-input-number v-model="user.payment_for_delivery" min="0" type="number" placeholder="Введите оплату за доставку" controls-position="right" class="w-100" />
                                        </el-form-item>
                                    </el-col>
                                    <el-col :sm="12">
                                        <el-form-item label="Оплата за простой (ч)">
                                            <el-input-number v-model="user.payment_for_downtime" min="0" type="number" placeholder="Введите оплату за простой" controls-position="right" class="w-100" />
                                        </el-form-item>
                                    </el-col>
                                    <el-col :sm="12">
                                        <el-form-item label="Оплата за объем">
                                            <el-input-number v-model="user.payment_for_volume" min="0" type="number" placeholder="Введите оплату за объем" controls-position="right" class="w-100" />
                                        </el-form-item>
                                    </el-col>
                                    <el-col :sm="12">
                                        <el-form-item label="Оплата за км пути">
                                            <el-input-number v-model="user.payment_for_path" min="0" type="number" placeholder="Введите оплату за км пути" controls-position="right" class="w-100" />
                                        </el-form-item>
                                    </el-col>
                                </el-row>
                            </div>
                        </el-tab-pane>

                        <el-tab-pane name="password" label="Смена пароля">
                            <h3>Смена пароля</h3>
                            <div>
                                <el-row>
                                    <el-button
                                        @click="generatePassword"
                                        :loading="loading"
                                        type="primary"
                                        :plain="selectedChangePasswordType != 'auto'"
                                    >
                                        Сгенерировать автоматически
                                    </el-button>
                                    <el-button
                                        @click="showUpdatePasswordFields"
                                        :loading="loading"
                                        type="primary"
                                        :plain="selectedChangePasswordType != 'manual'"
                                    >
                                        Задать вручную
                                    </el-button>
                                </el-row>
                                <el-row v-if="showPasswordsFields" class="mt-30" gutter="15">
                                    <el-col :sm="12">
                                        <el-form-item label="Новый пароль:">
                                            <el-input
                                                v-model="user.password"
                                                placeholder="Введите пароль"
                                                autocomplete="chrome-off"
                                                :readonly="roles.length === 0 || !showPasswordVerificationField"
                                                :class="{pointer: !showPasswordVerificationField}"
                                                :type="showPasswordVerificationField ? 'password' : 'text'"
                                                :show-password="showPasswordVerificationField"
                                            ></el-input>
                                        </el-form-item>
                                    </el-col>
                                    <el-col v-if="showPasswordVerificationField" :sm="12">
                                        <el-form-item label="Подтвердите пароль:">
                                            <el-input v-model="user.passwordVerification"
                                                      placeholder="Подтвердите пароль"
                                                      autocomplete="chrome-off"
                                                      :disabled="roles.length === 0 || !user.password"
                                                      show-password></el-input>
                                        </el-form-item>
                                    </el-col>
                                </el-row>
                            </div>
                        </el-tab-pane>

                    </el-tabs>

                    <el-form-item v-if="!mobile">
                        <el-button @click="editUser" :loading="loading" type="primary">Сохранить данные
                        </el-button>
                        <el-button @click="block" :loading="loading" type="danger"
                                   v-if="loggedUser.id !== user.id && user.banned === 0">
                            Заблокировать
                        </el-button>
                        <el-button @click="unblock" :loading="loading" type="danger" plain
                                   v-if="loggedUser.id !== user.id && user.banned === 1">
                            Разблокировать
                        </el-button>
                        <el-button @click="removeUser" :loading="loading" type="danger" plain
                                   v-if="loggedUser.id !== user.id" :icon="icons.delete"/>
                    </el-form-item>

                    <el-form-item v-if="mobile && activeTabName != 'payment'">
                        <el-button @click="editUser" :loading="loading" type="primary">Сохранить данные
                        </el-button>
                        <el-button @click="block" :loading="loading" type="danger" plain
                                   v-if="loggedUser.id !== user.id && user.banned === 0">
                            Заблокировать
                        </el-button>
                        <el-button @click="unblock" :loading="loading" type="danger"
                                   v-if="loggedUser.id !== user.id && user.banned === 1">
                            Разблокировать
                        </el-button>
                        <el-button @click="removeUser" :loading="loading" type="danger" plain
                                   v-if="loggedUser.id !== user.id" :icon="icons.delete"/>
                    </el-form-item>
                </el-form>
            </div>
        </div>
    </Auth>
</template>

<script>
import Auth from '../../layouts/Auth'
import WidgetSettings from '../../components/SettingsPage/UserPage/WidgetSettings'
import {httpService} from '@/services/http.service'
import {mapState} from 'pinia'
import {useCommonStore} from '@/store/common'
import mobileCheckMixin from "@/mixins/mobileCheckMixin";
import iconsMixin from "@/mixins/iconsMixin";
import FormSelect from "@/views/components/Form/FormSelect.vue";
import FormNumber from "@/views/components/Form/FormNumber.vue";
import FormDateTimePicker from "@/views/components/Form/FormDateTimePicker.vue";

export default {
    name: 'UsersEdit',
    components: {
        FormDateTimePicker,
        FormNumber,
        FormSelect,
        WidgetSettings,
        Auth
    },
    data() {
        return {
            user: {
                id: null,
                name: null,
                email: null,
                phone: null,
                login: null,
                modules: [],
                password: null,
                passwordVerification: null,
                role: null,
                banned: null,
                widgets: [],
                info: {
                    calendar: null,
                    firstWorkDay: null,
                    workHoursInDay: 8,
                    dateOfWorkStart: null
                },
                payment_for_delivery: null,
                payment_for_downtime: null,
                payment_for_volume: null,
                payment_for_path: null,
                analytics_available: false,
                reports_available: false,
                reports: null,
                reportFunctions: null,
            },
            calendarTypes: [{
                id: '5/2',
                name: '5/2'
            }, {
                id: '2/2',
                name: '2/2'
            }],
            daysOfWeek: [{
                id: '1',
                name: 'Понедельник'
            }, {
                id: '2',
                name: 'Вторник'
            }, {
                id: '3',
                name: 'Среда'
            }, {
                id: '4',
                name: 'Четверг'
            }, {
                id: '5',
                name: 'Пятница'
            }, {
                id: '6',
                name: 'Суббота'
            }, {
                id: '7',
                name: 'Воскресенье'
            }],
            showPasswordsFields: false,
            showPasswordVerificationField: false,
            loading: true,
            modules: [],
            roles: [],
            initialRole: null,
            reloadNav: false,
            activeTabName: 'info',
            selectedChangePasswordType: null,
            widgets: [],
        }
    },
    inject: [
        'api'
    ],
    mixins: [
        mobileCheckMixin, iconsMixin
    ],
    created() {
        this.getAccountModule();
        this.getRole();
        this.getWidget();
        this.getUser();
    },
    computed: {
        ...mapState(useCommonStore, {
            loggedUser: 'getUser',
            loggedWidgets: 'getWidgets',
            allReports: 'getReports',
            allReportFunctions: 'getReportFunctions',
        })
    },
    methods: {
        getAccountModule() {
            httpService().post(this.api.account_module.get).then((response) => {
                let data = response.data

                if (data.success)
                    this.modules = data.modules

            })
        },
        getRole() {
            httpService().post(this.api.users.getRoles).then((response) => {
                let data = response.data

                if (data.success)
                    this.roles = data.roles

            })
        },
        getWidget() {
            httpService().post(this.api.settings.getWidgets).then((response) => {
                let data = response.data

                if (data.success)
                    this.widgets = data.widgets

            })
        },
        getUser() {
            this.loading = true

            httpService().post(this.api.user.get, {id: this.$route.params.id}).then((response) => {
                let data = response.data

                if (data.success) this.user = data.user
                this.user.modules = data.modules
                this.user.reports = response.data.userReportAccess.filter(report => report.report_id !== null).map(report => report.report_id)
                this.user.reportFunctions = response.data.userReportAccess.filter(report => report.report_function_id !== null).map(report => report.report_function_id)
                this.user.widgets = this.loggedWidgets
                this.initialRole = data.user.role
                this.loading = false

            }).catch(() => {
                this.$message({
                    message: 'Ошибка ответа от сервера',
                    showClose: true,
                    type: 'error'
                })
                this.$router.push({name: 'logout'})
            })
        },
        editUser() {
            this.loading = true

            httpService().post(this.api.user.edit, {
                user: this.user
            }).then((response) => {
                let data = response.data

                if (data.success){
                    this.$message({
                        message: 'Данные сохранены',
                        showClose: true,
                        type: 'success'
                    })
                    if (this.user.id === this.loggedUser.id) {
                        useCommonStore().setAnalyticsAvailable(response.data.analyticsAvailable)
                        useCommonStore().setReportsAvailable(response.data.reportsAvailable)
                        useCommonStore().setUserReportAccess(response.data.userReportAccess)
                    }
                }
                this.loading = false
                this.showPasswordsFields = false;
                this.showPasswordVerificationField = false;
                this.user.password = this.user.passwordVerification = null;

            }).catch(() => {
                this.$message({
                    message: 'Ошибка проверки валидации данных. Укажите уникальные значения e-mail и телефона',
                    showClose: true,
                    type: 'error'
                })
                this.$router.push({name: 'logout'})
            }).finally(() => {
                this.loading = false
                this.reloadNav = !this.reloadNav
            })
        },
        removeUser() {
            this.loading = true


            this.loading = true
            this.$confirm(
                'Вы действительно хотите удалить пользователя?',
                'Подтвердите удаление',
                {
                    confirmButtonText: 'Удалить',
                    cancelButtonText: 'Отменить',
                    type: 'warning',
                }
            )
                .then(() => {
                    httpService().post(this.api.user.removeUser, {
                        userId: this.user.id
                    }).then((response) => {
                        let data = response.data

                        if (data.success)
                            this.$message({
                                message: 'Пользователь удален',
                                showClose: true,
                                type: 'success'
                            })
                        this.loading = false
                        this.$router.push({name: 'UsersIndex'})

                    }).catch(() => {
                        this.$message({
                            message: 'Ошибка ответа от сервера',
                            showClose: true,
                            type: 'error'
                        })
                        this.$router.push({name: 'logout'})
                    }).finally(() => {
                        this.reloadNav = !this.reloadNav
                    })
                }).catch(() => {
                this.$message({
                    message: 'Удаление отменено',
                    showClose: true,
                    type: 'info'
                })
            })
            this.loading = false
        },
        filteredReports(){
            let filtered = this.modules.filter(item => this.user.modules.includes(item.id))
            return this.allReports.filter(report =>
                filtered.some(filter => report.module_id === filter.module_id)
            );
        },
        checkCheckbox(report){
             return this.user.reports
                ? this.user.reports.filter(item => this.allReportFunctions.some(reportFunction => item === reportFunction.report_id && report.id === reportFunction.report_id))
                : false;
        },
        block() {
            this.loading = true
            this.$confirm(
                'Вы действительно хотите заблокировать пользователя?',
                'Подтвердите блокировку',
                {
                    confirmButtonText: 'Заблокировать',
                    cancelButtonText: 'Отменить',
                    type: 'warning',
                }
            )
                .then(() => {
                    this.banUser(this.user.id, 'block')
                    this.user.banned = 1
                }).catch(() => {
                this.$message({
                    message: 'Блокировка отменена',
                    showClose: true,
                    type: 'info'
                })
            })
            this.loading = false
        },
        getWidgets({widgets}) {
            this.user.widgets = widgets
        },
        unblock() {
            this.loading = true
            this.$confirm(
                'Вы действительно хотите разблокировать пользователя?',
                'Подтвердите разблокировку',
                {
                    confirmButtonText: 'Разблокировать',
                    cancelButtonText: 'Отменить',
                    type: 'warning',
                }
            )
                .then(() => {
                    this.banUser(this.user.id, 'unblock')
                    this.user.banned = 0
                }).catch(() => {
                this.$message({
                    message: 'Разблокировка отменена',
                    showClose: true,
                    type: 'info'
                })
            })
            this.loading = false
        },
        banUser(id, action) {
            httpService().post(this.api.user.ban, {id: id, action: action}).then((response) => {
                let data = response.data

                if (data.success)
                    this.$message({
                        message: data.message,
                        showClose: true,
                        type: 'success'
                    })
            }).catch(() => {
                this.$message({
                    message: 'Ошибка ответа от сервера',
                    showClose: true,
                    type: 'error'
                })
                this.$router.push({name: 'logout'})
            })
        },
        generatePassword() {
            this.selectedChangePasswordType = 'auto';
            let password = (Math.random() + 1).toString(36).substring(6);
            this.user.password = password;
            this.user.passwordVerification = password;
            this.showPasswordsFields = true;
            this.showPasswordVerificationField = false;
            navigator.clipboard.writeText(this.user.password);
            this.$message({
                message: 'Пароль скопирован в буфер',
                type: 'success'
            });
        },

        showUpdatePasswordFields() {
            this.selectedChangePasswordType = 'manual';
            this.showPasswordsFields = true;
            this.showPasswordVerificationField = true;
            this.user.passwordVerification = this.user.password = null;
        }
    },
    watch: {
        '$route.params.settingsWidgets': {
            handler: function (settingsWidgets) {
                if (settingsWidgets) this.activeTabName = 'widgets'
            },
            deep: true,
            immediate: true
        }
    }
}
</script>
