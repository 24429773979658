import { useCommonStore } from '@/store/common'
import router from '..';

export default function adminsOnly({next}) {
    const commonStore = useCommonStore()

    const currentUser = commonStore.getUser

    if (currentUser.role_id === 1 || currentUser.role_id === 2) {
        return next();
    }

    router.push('/dashboard');
}
