<template>
    <Auth title="Заказы" :tabs="tabs" :integrations="integrations" :relations="relations">
        <div class="card">
            <div class="card-body">
                <TableHeadBlock
                    :new="true"
                    class="head"
                    v-model="search"
                    :disable-search="true"
                    :loading="loading"
                    :show-date-filter="true"
                    create-button-label="Добавить заказ"
                    :current-date="mixStore.filterDate.value"
                    @moduleGet="moduleGet"
                    @openFilterBlock="openFilterBlock"
                    @openCreateValueDrawer="$router.push({ name: 'MixOrderGroupCreate', params: { id: $route.params.id } })"
                    @changeDate="changeDate"
                >
                    <template v-slot:buttonsBlock>
                        <button class="ci-btn ci-btn_light" @click="openRequestDialog(null)">
                            {{ mobile ? 'Запрос' : 'Создать запрос' }}
                        </button>
                    </template>
                </TableHeadBlock>

                <Filter
                    :filter="filter"
                    :loading="loading"
                    :filter-show="filterShow"
                />

                <EmptyState
                    :values="displayData"
                    :filter="filter"
                    :search="search"
                    :loading="loading"
                    list-types="заказов"
                />

                <div class="orderGroupsTable orderGroupsTableScroll" v-if="displayData.length > 0">
                    <div class="orderGroupsTableScrollBody">
                        <MixPageOrdersGroupCol
                            status-name="Запросы"
                            status="unconfirmed"
                            @moduleGet="moduleGet"
                            :values="values.filter(el => el.status === 'unconfirmed')"
                            :mix-goods="mixGoods"
                            @openDialog="openRequestDialog"
                        />
                        <MixPageOrdersGroupCol
                            status-name="Заказы"
                            status="unallocated"
                            @moduleGet="moduleGet"
                            :values="values.filter(el => el.status === 'unallocated')"
                            :mix-goods="mixGoods"
                            @openDialog="openRequestDialog"
                        />
                        <MixPageOrdersGroupCol
                            status-name="В работе"
                            status="partially_distributed"
                            @moduleGet="moduleGet"
                            :values="values.filter(el => el.status === 'partially_distributed')"
                            :mix-goods="mixGoods"
                            @openDialog="openRequestDialog"
                        />
                        <MixPageOrdersGroupCol
                            status-name="Завершен"
                            status="distributed"
                            @moduleGet="moduleGet"
                            :values="values.filter(el => el.status === 'distributed')"
                            :mix-goods="mixGoods"
                            @openDialog="openRequestDialog"
                        />
                        <MixPageOrdersGroupCol
                            status-name="done"
                            status="done"
                            @moduleGet="moduleGet"
                            :values="values.filter(el => el.status === 'done')"
                            :mix-goods="mixGoods"
                            @openDialog="openRequestDialog"
                        />
                        <MixPageOrdersGroupCol
                            status-name="failed"
                            status="failed"
                            @moduleGet="moduleGet"
                            :values="values.filter(el => el.status === 'failed')"
                            :mix-goods="mixGoods"
                            @openDialog="openRequestDialog"
                        />
                    </div>
                </div>

                <el-dialog
                    v-model="mixRequestDialog"
                    :title="selectedRequestId ? 'Запрос' : 'Создание запроса'"
                    :width="mobile ? '100%' : '65%'"
                    :destroy-on-close="true"
                >
                    <MixPageRequestForm
                        :id="selectedRequestId"
                        @close="closeRequestDialog()"
                    />
                </el-dialog>
            </div>
        </div>
    </Auth>
</template>

<script>
import Filter from '../../Table/Filter/Filter'
import TableHeadBlock from '../../Table/TableHeadBlock'
import tableMixin from '../../../../mixins/tableMixin'
import mobileCheckMixin from '../../../../mixins/mobileCheckMixin'
import functionsMixin from '../../../../mixins/functionsMixin'
import {
    mapState,
    mapStores
} from 'pinia'
import {useCommonStore} from '@/store/common'
import {httpService} from '@/services/http.service'
import EmptyState from "@/views/components/EmptyState.vue";
import MixPageOrdersGroupCol from "@/views/components/MixPage/OrdersGroup/MixPageOrdersGroupCol.vue";
import moment from "moment/moment";
import {useMixStore} from '@/store/mix';
import MixPageRequestForm from "@/views/components/MixPage/OrdersGroup/MixPageRequestForm.vue";
import Auth from "@/views/layouts/Auth.vue";
import {useEchoStore} from "@/store/Echo";

export default {
    name: 'MixPageOrdersGroup',
    components: {
        Auth,
        MixPageRequestForm,
        MixPageOrdersGroupCol,
        EmptyState,
        Filter,
        TableHeadBlock,
    },
    mixins: [
        tableMixin, mobileCheckMixin, functionsMixin
    ],
    inject: [
        'api'
    ],
    data() {
        return {
            loading: true,
            values: [],
            mixGoods: [],
            doc: 0,
            selectedRequestId: null,
            filter: {
                date: {
                    value: null,
                    type: 'date'
                },
            },
            mixRequestDialog: false,
            tabs: [{
                route: 'MixOrdersGroups',
                name: 'Заказы'
            }, {
                route: 'MixOrdersGroupsOrders',
                name: 'Отгрузки'
            }, {
                route: 'MixOrdersMixers',
                name: 'Миксеры'
            }, {
                route: 'MixGroupGoods',
                name: 'Рецепты'
            }],
            moduleId: null,
            integrations: [],
            relations: [],
        }
    },
    async created() {
        await this.mixModuleGet()
        this.moduleGet()
    },
    computed: {
        ...mapState(useCommonStore, {
            goods: 'getGoods'
        }),
        ...mapStores(useMixStore),
        ...mapStores(useEchoStore),
    },
    methods: {
        openRequestDialog(id) {
            this.selectedRequestId = id
            this.mixRequestDialog = true
        },
        async mixModuleGet() {
            await httpService().post(this.api.mix.getModule, {
                account_module_reserve_id: this.$route.params.id,
                module_key: 'mix',
            }).then((response) => {
                let data = response.data

                if (data.success) {
                    this.moduleId = data.moduleId
                }

                this.integrations = data.integrations
                this.relations = data.relations
            })
        },
        moduleGet() {
            this.loading = true

            httpService().post(this.api.mix.getGoods, {
                account_module_reserve_id: this.$route.params.id,
                module_key: 'mix'
            }).then((response) => {
                let data = response.data

                if (data.success)
                    this.mixGoods = data.values
            })

            const filter = {
                ...this.filter,
                ...this.mixStore.filterDate,
            };

            if (this.filter.date.value && this.mixStore.filterDate.value) {
                httpService().post(this.api.mix.getOrderGroups, {
                    account_module_reserve_id: this.$route.params.id,
                    module_key: 'mix',
                    filter
                }).then((response) => {
                    let data = response.data

                    if (data.success)
                        this.values = data.orders
                    this.doc = data.doc
                    this.defaultSeller = data.defaultSeller
                    this.loading = false
                })
            }

        },
        closeRequestDialog() {
            this.$message({
                message: 'Данные сохранены',
                showClose: true,
                type: 'success'
            })
            this.moduleGet()
            this.mixRequestDialog = false
        },
        changeDate(date) {
            const value = moment(date).utcOffset(0, true).startOf('day');
            this.mixStore.setFilterDate(value);
        },
        filterValues() {
            this.filtered = this.values

            if (this.search) this.filtered = this.filtered.filter(data => !this.search || data.name.toLowerCase().includes(this.search.toLowerCase()))

            this.valueLength = this.filtered.length
            return this.filtered
        },
        changeOrderGroup(newOrderGroup){
            const index = this.values.findIndex(orderGroup => orderGroup.id === newOrderGroup.id);
            if (index !== -1) {
                this.values.splice(index, 1, newOrderGroup);
            }
        },
    },
    watch: {
        'filter': {
            handler: function () {
                this.moduleGet()
            },
            deep: true,
            immediate: true
        },
        'mixStore.filterDate.value': {
            handler: function (date) {
                if (date) {
                    this.filter.date.value = date;
                }
            },
            deep: true,
            immediate: true
        }
    },
    mounted() {
        if (!this.filter.date.value || this.filter.date.value === 'Invalid date') {
            if (!this.mixStore.filterDate.value || this.mixStore.filterDate.value === 'Invalid date') {
                this.mixStore.setFilterDate(moment().startOf('day'));
            }
            this.filter.date.value = this.mixStore.filterDate.value;
        }
    }
}
</script>

<style scoped>
.head {
    .dates {
        margin-left: 0 !important;
    }
}

.ci-btn {
    @extend .btn;
    display: flex;
    align-items: center;
    gap: 12px;
    line-height: 44px;
    padding: 0 20px;
    font-weight: 600;
    border-radius: 8px;

    &_light {
        background-color: $primary50;
        color: $primary600;
    }

    &_blue {
        background-color: $primary600;
        color: $white;
    }

    &_default {
        background-color: $white;
        color: $neutral600;
        border: 1px solid $neutral100
    }

    svg {
        height: 20px;
        width: 20px;
    }
}
</style>
