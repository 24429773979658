<template>
    <Auth title="Информация о товаре">
        <div class="card">
            <div class="card-body">

                <template v-if="loading">
                    <div class="text-center mt-30">
                        <ReportLoader/>

                        <h3>Загрузка данных о товаре</h3>
                    </div>
                </template>

                <template v-else>
                    <h2 class="headingBold headingSM neutral900">{{ details.name }}</h2>
                    <hr class="mb-30"/>
                    <el-form ref="form" label-position="top">

                        <el-row :gutter="30">

                            <el-col :sm="8">
                                <h4 class="headingMedium headingXS neutral700 mb-0 mt-0">Общая информация</h4>
                                <p class="textRegular textSM neutral500">Укажите общие данные о продукции</p>
                            </el-col>
                            <el-col :md="16" :lg="12">

                                <el-row :gutter="20">

                                    <el-col :sm="12">
                                        <FormInput
                                            v-model="details.name"
                                            label="Название"
                                            placeholder="Название товара"
                                        />
                                        <el-form-item label="Тип товара">
                                            <el-select v-model="details.type"
                                                       placeholder="Выберите тип товара">
                                                <el-option
                                                    v-for="type in goodTypes"
                                                    :key="type.key"
                                                    :label="type.name"
                                                    :value="type.key"/>
                                            </el-select>
                                        </el-form-item>

                                        <el-form-item label="Ед. измерения">
                                            <el-select v-model="details.unit"
                                                       placeholder="Выберите единицу измерения">
                                                <el-option
                                                    v-for="unit in units"
                                                    :key="unit.id"
                                                    :label="unit.short_name"
                                                    :value="unit.id">
                                                    <span style="float: left">{{ unit.short_name }}</span>
                                                    <span
                                                        style="float: right; color: #a2a5bf; font-size: 13px;">{{
                                                            unit.name
                                                        }}</span
                                                    >
                                                </el-option>
                                            </el-select>
                                        </el-form-item>
                                    </el-col>

                                    <el-col :sm="12">
                                        <FormSelect
                                            v-model="details.accountModuleId"
                                            label="Модуль, к которому привязан"
                                            placeholder="Выберите, если необходимо"
                                            :items="accountModules"
                                        />

                                        <el-form-item label="Интеграция, к которой привязан">
                                            <TableIntegrationFilter
                                                v-model="details.integrationId"
                                            />
                                        </el-form-item>

                                        <FormInput
                                            v-model="details.softId"
                                            v-if="details.integrationId"
                                            label="ID в программе интеграции"
                                            placeholder="ID"
                                        />

                                        <FormInput
                                            v-model="details.sku"
                                            label="Код продукции"
                                            placeholder="Код продукции"
                                        />
                                    </el-col>

                                </el-row>

                                <div class="mb-30">
                                    <el-button type="primary" @click="doEditGood" :disabled="loadingButton">Сохранить изменения</el-button>
                                    <el-button :icon="icons.delete" @click="doRemoveGood" :disabled="loadingButton"/>
                                </div>

                            </el-col>

                        </el-row>

                        <hr class="mb-30"/>

                        <el-row :gutter="30">

                            <el-col :sm="8">
                                <h4 class="headingMedium headingXS neutral700 mb-0 mt-0">Связь товара</h4>
                                <p class="textRegular textSM neutral500">Другие товары, с которым связан данный
                                    товар</p>
                            </el-col>
                            <el-col :sm="16">
                                <RelationGroupBlock
                                    :id="details.id"
                                    type="good"
                                    :parent-id="details.parentId"
                                    :account-modules="accountModules"
                                />
                            </el-col>
                        </el-row>

                        <template v-if="details.type === 'mix' || details.type === 'spec'">
                            <hr class="mb-30"/>

                            <el-row :gutter="30">

                                <el-col :sm="8">
                                    <h4 class="headingMedium headingXS neutral700 mb-0 mt-0">Дополнительная
                                        информация</h4>
                                    <p class="textRegular textSM neutral500">Информация для формирования документов и
                                        прочее</p>
                                </el-col>
                                <el-col :sm="16">

                                    <el-row :gutter="20">

                                        <el-col :sm="8">

                                            <FormSelect
                                                label="Марка бетона (М)"
                                                placeholder="М150"
                                                v-model="details.info.brand"
                                                :items="brands"
                                            />

                                            <FormSelect
                                                label="Класс бетона (В)"
                                                placeholder="В22,5"
                                                v-model="details.info.class"
                                                :items="classes"
                                            />

                                            <FormSelect
                                                label="Подвижность бетона (П)"
                                                placeholder="П4"
                                                v-model="details.info.mobility"
                                                :items="mobility"
                                            />

                                            <FormInput
                                                label="Прочность (Мпа)"
                                                placeholder="28,9"
                                                v-model="details.info.strength"
                                            />

                                            <FormSelect
                                                label="Класс материалов по уд. эфф. акт. естеств. радионуклидов"
                                                placeholder="I"
                                                v-model="details.info.classRadio"
                                                :items="classRadio"
                                            />

                                        </el-col>
                                        <el-col :sm="8">
                                            <FormInput
                                                label="Значение Аэфф (БК/л)"
                                                placeholder="370"
                                                v-model="details.info.aeff"
                                            />

                                            <FormInput
                                                label="Добавка"
                                                placeholder="Название добавки"
                                                v-model="details.info.additiveName"
                                            />

                                            <FormInput
                                                label="Масса добавки"
                                                placeholder="12"
                                                v-model="details.info.additiveWeight"
                                            />

                                            <FormInput
                                                label="№ номинального состава бетонной смеси"
                                                placeholder="6"
                                                v-model="details.info.numberComposition"
                                            />

                                            <FormInput
                                                label="Сохр. удобоукл. и других показателей, (ч-мин)"
                                                placeholder="02:00"
                                                v-model="details.info.workabilityTime"
                                            />
                                        </el-col>
                                        <el-col :sm="8">
                                            <FormInput
                                                label="Наибольшая крупность заполнителя (мм)"
                                                placeholder="20"
                                                v-model="details.info.aggregateSize"
                                            />

                                            <FormSelect
                                                label="Класс бетона, 28 суток (В)"
                                                placeholder="В22,5"
                                                v-model="details.info.class28d"
                                                :items="classes"
                                            />

                                            <FormInput
                                                label="Прочность, 28 суток (Мпа)"
                                                placeholder="28,9"
                                                v-model="details.info.strength28d"
                                            />

                                            <FormSelect
                                                label="Класс бетона, 7 суток (В)"
                                                placeholder="В22,5"
                                                v-model="details.info.class7d"
                                                :items="classes"
                                            />

                                            <FormInput
                                                label="Прочность, 7 суток (Мпа)"
                                                placeholder="28,9"
                                                v-model="details.info.strength7d"
                                            />
                                        </el-col>


                                    </el-row>

                                </el-col>

                            </el-row>
                        </template>
                    </el-form>

                    <hr class="mb-30"/>

                    <el-row :gutter="30">

                        <el-col :sm="8">
                            <h4 class="headingMedium headingXS neutral700 mb-0 mt-0">Компоненты</h4>
                            <p class="textRegular textSM neutral500">Составляющие товары, учавствуют в производстве</p>
                        </el-col>
                        <el-col :sm="16">
                            <el-table
                                empty-text="Компонентов нет"
                                :data="details.components"
                                border
                                :show-header="false"
                                class="w-100 mt-15">
                                <el-table-column
                                    prop="goodId"
                                    label="#">
                                    <template v-slot="scope">
                                        <span class="textSM textRegular">{{ getData(scope.row.goodId, 'good') }}</span>
                                    </template>
                                </el-table-column>
                                <el-table-column
                                    prop="accountModuleId"
                                    label="#">
                                    <template v-slot="scope">
                                        <div class="textMedium textSM success400">
                                            {{ formatNumber(scope.row.total) }}
                                            {{ getData(scope.row.goodId, 'good', 'unit') }}
                                        </div>
                                    </template>
                                </el-table-column>
                            </el-table>
                        </el-col>
                    </el-row>

                    <hr class="mb-30"/>

                    <el-row :gutter="30">

                        <el-col :sm="8">
                            <h4 class="headingMedium headingXS neutral700 mb-0 mt-0">Цена продажи</h4>
                            <p class="textRegular textSM neutral500">История цен продажи</p>
                        </el-col>
                        <el-col :sm="16">
                            <el-table
                                empty-text="Цен нет"
                                :data="sellPrices"
                                border
                                :show-header="false"
                                class="w-100 mt-15">
                                <el-table-column
                                    prop="price"
                                    label="Цена">
                                    <template v-slot="scope">
                                        <div class="textMedium textSM success400">
                                            {{ formatNumber(scope.row.price) }}
                                            руб.
                                        </div>
                                    </template>
                                </el-table-column>
                                <el-table-column
                                    prop="created_at"
                                    label="Дата добавления">
                                    <template v-slot="scope">
                                        <span class="textSM textRegular">{{ getDateFormDB(scope.row.created_at, 'datetimeIn') }}</span>
                                    </template>
                                </el-table-column>
                            </el-table>

                            <el-button @click="openAddPriceDialog(true)" class="mt-5" type="text" :icon="icons.plus">
                                Добавить новую цену
                            </el-button>
                        </el-col>
                    </el-row>

                </template>

            </div>
        </div>

        <el-dialog
            v-model="addPriceDialog"
            title="Добавление цены"
            :width="mobile ? '100%' : '35%'"
            :destroy-on-close="true"
        >

            <GoodsAddPrice
                :good-id="details.id"
                :selling="priceType"
                @moduleGet="getGoodDetails"
                @closeDialog="addPriceDialog=false"
            />

        </el-dialog>
    </Auth>
</template>

<script>
import Auth from '@/views/layouts/Auth.vue'
import {httpService} from '@/services/http.service'
import ReportLoader from '@/views/components/Loaders/ReportLoader'
import functionsMixin from '@/mixins/functionsMixin'
import mobileCheckMixin from '@/mixins/mobileCheckMixin'
import GoodsAddPrice from '@/views/components/SettingsPage/GoodsPage/GoodsAddPrice'
import FormInput from "@/views/components/Form/FormInput.vue";
import {mapActions, mapState} from "pinia";
import {useCommonStore} from "@/store/common";
import goodTypes from "@/mixins/goodTypes";
import FormSelect from "@/views/components/Form/FormSelect.vue";
import concreteInfo from "@/mixins/concreteInfo";
import iconsMixin from "@/mixins/iconsMixin";
import RelationGroupBlock from "@/views/components/RelationGroupBlock/RelationGroupBlock.vue";
import TableIntegrationFilter from "@/views/components/Table/TableIntegrationFilter.vue";

export default {
    name: 'GoodDetails',
    components: {
        TableIntegrationFilter,
        RelationGroupBlock,
        FormSelect, FormInput, GoodsAddPrice, ReportLoader, Auth
    },
    mixins: [functionsMixin, mobileCheckMixin, goodTypes, concreteInfo, iconsMixin],
    inject: ['api'],
    data() {
        return {
            loading: true,
            loadingButton: false,
            details: {
                name: null,
                unit: null,
                priceSell: 0,
                priceBuy: 0,
                costPrice: 0,
                priceHistory: [],
                components: [],
                type: 'good',
                accountModuleId: null,
                integrationId: null,
                softId: null,
                sku: null,
                info: {
                    class: null,
                    brand: null,
                    mobility: null,
                    strength: null,
                    classRadio: null,
                    aeff: null,
                    additiveName: null,
                    additiveWeight: null,
                    numberComposition: null,
                    workabilityTime: null,
                    aggregateSize: null,
                    class7d: null,
                    strength7d: null,
                    class28d: null,
                    strength28d: null,
                }
            },
            aboutGoods: null,
            addPriceDialog: false,
            priceType: null,
            accountModules: [],
            integrations: [],
            sellPrices: []
        }
    },
    created() {
        this.getGoodDetails()
    },
    computed: {
        ...mapActions(useCommonStore, [
            'pullGoods'
        ]),
        ...mapState(useCommonStore, {
            goods: 'getGoods',
            units: 'getUnits',
        })
    },
    methods: {
        doEditGood() {
            this.loadingButton = true
            httpService().post(this.api.good.update, {
                module: this.details,
            }).then(() => {
                this.$message({
                    message: 'Данные о товаре изменены',
                    showClose: true,
                    type: 'success'
                })
                this.loadingButton = false
            }).catch((error) => {
                this.$message({
                    message: error.response.data.message,
                    type: 'error',
                    showClose: true,
                })
                this.loadingButton = false
            })
        },
        getGoodDetails() {
            this.loading = true
            httpService().post(this.api.good.getOneGoodDetails, {
                id: this.$route.params.id
            }).then((response) => {
                let data = response.data

                if (data.success) {
                    this.details = data.good
                    if (!this.details.info) {
                        this.details.info = {
                            class: null,
                            brand: null,
                            mobility: null,
                            strength: null,
                            classRadio: null,
                            aeff: null,
                            additiveName: null,
                            additiveWeight: null,
                            numberComposition: null,
                            workabilityTime: null,
                            aggregateSize: null,
                            class7d: null,
                            strength7d: null,
                            class28d: null,
                            strength28d: null,
                        }
                    }
                    this.accountModules = data.accountModules
                    this.sellPrices = data.sellPrices
                    this.loading = false
                }

            }).catch(() => {
                this.$message({
                    message: 'Товар не найден',
                    showClose: true,
                    type: 'error'
                })
                this.$router.push({name: 'goods'})
            })
        },
        doRemoveGood() {
            this.$confirm(
                'Вы действительно хотите удалить данный товар?',
                'Подтвердите удаление',
                {
                    confirmButtonText: 'Удалить',
                    cancelButtonText: 'Отмена',
                    type: 'warning',
                }
            )
                .then(() => {
                    httpService().post(this.api.good.remove, {
                        goodId: this.details.id
                    }).then(() => {
                        this.$message({
                            message: 'Товар удален',
                            showClose: true,
                            type: 'success'
                        })
                        this.$router.push({name: 'goods'})
                    }).catch((error) => {
                        this.$message({
                            message: error.response.data.message,
                            type: 'error',
                            showClose: true,
                        })
                    })
                }).catch(() => {
                this.$message({
                    message: 'Удаление отменено',
                    showClose: true,
                    type: 'info'
                })
            })
        },
        openAddPriceDialog(selling) {
            this.addPriceDialog = true
            this.priceType = selling
        }
    },
    watch: {
        $route(to, from) {
            if (to.name === from.name) {
                this.getGoodDetails()
            }
        }
    },
}
</script>

<style scoped>

</style>
