<template>
    <TableHeadBlock
        v-model="search"
        :loading="loading"
        disable-filter="true"
        @moduleGet="moduleGet"
        @openFilterBlock="openFilterBlock"
        :show-user-status-buttons="true"
        createButtonLabel="Добавить пользователя"
        updateButtonLabel="Обновить пользователей"
        :user-status-selected="filterByStatus"
        @changeUserStatusSelected="changeUserStatusSelected"
        @openCreateValueDrawer="() => createUserDrawer = true"
    />

    <el-table
        v-loading="loading"
        :data="displayData"
        ref="table"
        @sortChange="sortChanged"
        empty-text="Нет информации"
        class="mt-15 w-100">
        <el-table-column
            label=""
            width="65px"
        >
            <template v-slot="scope">
                <el-button :icon="icons.arrowDown" @click="toogleExpand(scope.row)"
                           circle></el-button>
            </template>
        </el-table-column>
        <el-table-column
            prop="name"
            label="Компания"
            sortable="custom"
        >
            <template v-slot="scope">
                <div class="double">
                    <p>{{ scope.row.name }}</p>
                    <span>ИНН: {{ scope.row.inn ?? '-' }}</span>
                </div>
            </template>
        </el-table-column>
        <el-table-column
            prop="tariff"
            label="Тариф"
        />
        <el-table-column
            prop="created"
            label="Дата рег."
            sortable="custom"
        >
            <template v-slot="scope">
                {{ getDateFromUnix(scope.row.created, 'DD.MM.YY') }}
            </template>
        </el-table-column>
        <el-table-column
            prop="status"
            label="Статус"
        >
            <template v-slot="scope">
                {{ {wait: "Модерация", active: "Активный"}[scope.row.status] }}
            </template>
        </el-table-column>
        <el-table-column
            prop="users"
            label="Пользователи"
            sortable="custom"
        >
            <template v-slot="scope">
                {{ scope.row.users ? scope.row.users.length : '-' }}
            </template>
        </el-table-column>
        <el-table-column
            prop="modules"
            label="Модули"
            sortable="custom"
        >
            <template v-slot="scope">
                {{ scope.row.modules ? scope.row.modules.length : '-' }}
            </template>
        </el-table-column>
        <el-table-column type="expand" width="1">
            <template v-slot="props">

                <div style="padding-left: 75px; font-weight: 300;">

                    <p>Баланс: {{ formatNumber(props.row.balance) }}, доступ оплачен до
                        {{ getDateFromUnix(props.row.payday, 'DD.MM.YY') }}</p>

                    <p>Используемые интеграции: <span v-for="(integration, index) in props.row.integrations"
                                                      :key="index">{{ integration.key }} ({{
                            integration.object ?? '-'
                        }})<span
                            v-if="index !== props.row.integrations.length - 1">, </span></span></p>

                    <p>Используемые модули ({{ props.row.modules.length }}): <span
                        v-for="(module, index) in props.row.modules"
                        :key="index">{{ module.name }}<span
                        v-if="index !== props.row.modules.length - 1">, </span></span></p>

                    <div class="user-buttons-wrapper d-flex mb-20">
                        <el-button
                            v-if="props.row.status === 'wait'"
                            class="mr-5"
                            @click="activateUser(props.row.id)"
                            type="primary"
                        >
                            Активировать
                        </el-button>
                        <el-button
                            v-if="props.row.users[0]?.verification_token"
                            @click="doLoginByToken(props.row.users[0]?.verification_token)"
                            type="warning"
                        >
                            Войти как первый пользователь
                        </el-button>
                    </div>

                    <h3 class="tableH3" data-v-1c0a609a="">Пользователи</h3>

                    <el-table
                        v-loading="loading"
                        :data="props.row.users"
                        class="mt-15 w-100">
                        <el-table-column
                            prop="name"
                            label="ФИО"
                        />
                        <el-table-column
                            prop="role"
                            label="Роль"
                            sortable
                        />
                        <el-table-column
                            prop="email"
                            label="E-mail"
                        >
                            <template v-slot="scope">
                                {{ scope.row.email ?? '-' }}
                            </template>
                        </el-table-column>
                        <el-table-column
                            prop="phone"
                            label="Телефон"
                        >
                            <template v-slot="scope">
                                {{ scope.row.phone ?? '-' }}
                            </template>
                        </el-table-column>
                        <el-table-column
                            prop="activity_date"
                            label="Был в системе"
                            sortable
                        >
                            <template v-slot="scope">
                                {{ scope.row.activity_date ? getDateFromUnix(scope.row.activity_date) : '-' }}
                            </template>
                        </el-table-column>
                        <el-table-column label="">
                            <template v-slot="scope">
                                <el-button
                                    @click="handleImpersonateUser(scope.row.verification_token)"
                                    type="warning"
                                >
                                    Войти как пользователь
                                </el-button>
                            </template>
                        </el-table-column>

                    </el-table>

                </div>

            </template>
        </el-table-column>
    </el-table>

    <el-drawer
        v-model="createUserDrawer"
        direction="rtl"
        :size="drawerSize"
        :destroy-on-close="true"
    >

        <GodModeUserCreateDrawer @closeDrawer="closeUserCreateDrawer"/>

    </el-drawer>

    <TablePagination
        v-model="pageSize"
        :total="valueLength"
        @action="setPage"
    />
</template>

<script>
import tableMixin from '@/mixins/tableMixin'
import functionsMixin from '@/mixins/functionsMixin'
import TableHeadBlock from '@/views/components/Table/TableHeadBlock'
import TablePagination from '@/views/components/Table/TablePagination'
import {httpService} from '@/services/http.service'
import iconsMixin from '@/mixins/iconsMixin'
import mobileCheckMixin from "@/mixins/mobileCheckMixin";
import GodModeUserCreateDrawer from "@/views/components/GodModePage/GodModeUserCreateDrawer.vue";
import {authService} from "@/services/auth.service";

export default {
    name: 'GodModeUsers',
    components: {GodModeUserCreateDrawer, TablePagination, TableHeadBlock},
    mixins: [tableMixin, functionsMixin, iconsMixin, mobileCheckMixin],
    inject: ['api'],
    data() {
        return {
            loading: false,
            sortBy: 'id',
            order: 'descending',
            filterByStatus: 'active',
            createUserDrawer: false
        }
    },
    created() {
        this.moduleGet()
    },
    methods: {
        moduleGet() {
            this.loading = true

            httpService().post(this.api.admin.getUsers, {
                sortBy: this.sortBy,
                order: this.order,
                filterByStatus: this.filterByStatus,
            }).then((response) => {
                let data = response.data

                if (data.success)
                    this.loading = false
                this.values = data.values
            }).catch(() => {
                this.$message({
                    message: 'Ошибка ответа от сервера',
                    showClose: true,
                    type: 'error'
                })
            })
        },
        closeUserCreateDrawer() {
            this.createUserDrawer = false;
            this.moduleGet();
        },
        changeUserStatusSelected(status) {
            this.filterByStatus = status;
            this.moduleGet();
        },
        sortChanged({prop, order}) {
            this.sortBy = prop;
            this.order = order;
            this.moduleGet();
        },
        doLoginByToken(token) {
            if (!token) {
                return
            }

            this.loading = true
            authService().doLoginByToken(token).finally(() => {
                this.loading = false
            })
        },
        activateUser(userId) {
            this.loading = true;
            httpService().post(this.api.admin.activateUser, {
                userId,
                sortBy: this.sortBy,
                order: this.order,
                filterByStatus: this.filterByStatus,
            }).then((response) => {
                let data = response.data
                if (data.success) {
                    this.loading = false
                }
                this.values = data.values
            }).catch(() => {
                this.$message({
                    message: 'Ошибка ответа от сервера',
                    showClose: true,
                    type: 'error'
                })
            });
        },
        toogleExpand(row) {
            let $table = this.$refs.table
            $table.toggleRowExpansion(row)
        },
        filterValues() {
            this.filtered = this.values
            if (this.search) {
                this.filtered = this.filtered.filter(data => {
                    let isMatched = false;
                    if (data.name) {
                        isMatched = data.name.toLowerCase().includes(this.search.toLowerCase());
                    }

                    if (data.inn) {
                        isMatched = isMatched || data.inn.toLowerCase().includes(this.search.toLowerCase());
                    }

                    return !this.search || isMatched
                })
            }

            this.valueLength = this.filtered.length
            return this.filtered.slice(this.pageSize * this.page - this.pageSize, this.pageSize * this.page)
        },
        handleImpersonateUser(token) {
            if (!token) {
                return
            }

            this.loading = true
            authService().impersonateUser(token).finally(() => {
                this.loading = false
            })
        }
    },
}
</script>

<style scoped>

</style>
