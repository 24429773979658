<template>
    <Auth :title="title">
        <div class="card">
            <div class="card-body">
                <el-form v-model="createCompanyForm" label-width="120px" label-position="top">
                    <template v-if="createCompanyForm.inn || createCompanyForm.name">
                        <el-row gutter="20">
                            <el-col :sm="8">
                                <el-form-item label="Короткое название:">
                                    <el-input placeholder="Удобное обозначение"
                                              v-model="createCompanyForm.short_name"></el-input>
                                </el-form-item>
                            </el-col>
                        </el-row>

                        <el-tabs type="border-card">
                            <el-tab-pane label="Общее">
                                <el-row :gutter="20">
                                    <el-col :sm="8">
                                        <el-form-item label="Полное название компании:">
                                            <el-input placeholder="Введите название"
                                                      v-model="createCompanyForm.name"></el-input>
                                        </el-form-item>
                                    </el-col>
                                    <el-col :sm="8">
                                        <el-form-item label="Электронная почта:">
                                            <el-input placeholder="E-mail"
                                                      v-model="createCompanyForm.email"></el-input>
                                        </el-form-item>
                                    </el-col>
                                    <el-col :sm="8">
                                        <el-form-item label="Контактный телефон:">
                                            <el-input placeholder="Номер телефона"
                                                      v-model="createCompanyForm.phone"></el-input>
                                        </el-form-item>
                                    </el-col>
                                    <el-col :sm="24">
                                        <el-form-item label="Описание компании:">
                                            <el-input placeholder="Пометка о компании в свободной форме"
                                                      v-model="createCompanyForm.description"></el-input>
                                        </el-form-item>
                                    </el-col>
                                    <el-col :sm="8">
                                        <FormSelect
                                            v-model="createCompanyForm.type"
                                            :items="types"
                                            :no-clearable="true"
                                            label="Тип компании"
                                        />
                                    </el-col>
                                </el-row>
                            </el-tab-pane>
                            <el-tab-pane label="Юридические данные">
                                <el-row :gutter="20">
                                    <el-col :sm="8">
                                        <FormDadataINN
                                            v-model="createCompanyForm.inn"
                                            label="ИНН:"
                                            placeholder="ИНН или название компании"
                                            @info="info"
                                        />
                                    </el-col>
                                    <el-col :sm="8">
                                        <el-form-item label="КПП:">
                                            <el-input placeholder="КПП компании"
                                                      v-model="createCompanyForm.kpp"></el-input>
                                        </el-form-item>
                                    </el-col>
                                    <el-col :sm="8">

                                    </el-col>
                                    <el-col :sm="24">
                                        <FormInput
                                            v-model="createCompanyForm.address"
                                            label="Юридический адрес"
                                            placeholder="г.Ярославль, ул.Ленина, д.3"
                                        />
                                    </el-col>
                                    <el-col :sm="24">
                                        <FormInput
                                            v-model="createCompanyForm.fact_address"
                                            label="Юридический адрес"
                                            placeholder="г.Ярославль, ул.Ленина, д.3"
                                        />
                                    </el-col>
                                </el-row>
                            </el-tab-pane>
                            <el-tab-pane label="Прочие данные">
                                <el-row :gutter="20">
                                    <el-col :sm="8">
                                        <FormSelect
                                            v-model="createCompanyForm.default_payment_method"
                                            label="Метод оплаты по умолчанию"
                                            placeholder="Выберите метод"
                                            :items="paymentMethods"
                                        />
                                    </el-col>
                                    <el-col :sm="8">
                                        <FormNumber
                                            v-model="createCompanyForm.free_unload_time"
                                            label="Бесплатное время разгрузки миксера"
                                            placeholder="В минутах"
                                        />
                                    </el-col>
                                    <el-col :sm="24">
                                        <el-form-item label="Является перевозчиком" class="mb-0">
                                            <el-checkbox v-model="createCompanyForm.carrier">
                                                Да
                                            </el-checkbox>
                                        </el-form-item>
                                    </el-col>
                                </el-row>
                            </el-tab-pane>
                            <el-tab-pane label="Адреса доставки">
                                <el-row :gutter="20">
                                    <el-col :sm="24" v-for="(item, index) in createCompanyForm.addresses" :key="index">
                                        <hr class="mb-30" v-if="index !== 0"/>
                                        <el-row :gutter="20">
                                            <el-col :sm="12">
                                                <el-form-item label="Название:">
                                                    <el-input placeholder="Адрес"
                                                              v-model="item.name"></el-input>
                                                </el-form-item>
                                            </el-col>
                                            <el-col :sm="12">
                                                <el-form-item class="dadata-address" label="Адрес:">
                                                    <FormDadataAddressV2 placeholder="Адрес"
                                                                         v-model="item.address"
                                                                         @infoAddress="setCoords"></FormDadataAddressV2>
                                                </el-form-item>
                                            </el-col>

                                            <el-col :sm="12">
                                                <el-form-item label="Широта адреса доставки">
                                                    <el-input placeholder="Адрес"
                                                              v-model="item.latitude"></el-input>
                                                </el-form-item>
                                            </el-col>
                                            <el-col :sm="12">
                                                <el-form-item label="Долгота адреса доставки">
                                                    <el-input placeholder="Адрес"
                                                              v-model="item.longitude"></el-input>
                                                </el-form-item>
                                            </el-col>
                                            <el-col :sm="24">
                                                <el-link type="danger" class="mb-15"
                                                         @click="removeCompanyAddress(item)">
                                                    Удалить
                                                    адрес
                                                </el-link>
                                            </el-col>
                                        </el-row>
                                    </el-col>

                                    <el-col :sm="24">
                                        <hr class="mb-30"/>
                                        <el-button :icon="icons.plus" @click="createAddressDialog=true">Добавить новый
                                            адрес
                                        </el-button>

                                    </el-col>
                                </el-row>
                            </el-tab-pane>
                            <el-tab-pane label="Связанные компании" v-if="createCompanyForm.id">
                                <RelationGroupBlock
                                    :id="createCompanyForm.id"
                                    type="company"
                                    :account-modules="accountModules"
                                    :integrations="integrations"
                                />
                            </el-tab-pane>
                        </el-tabs>
                        <el-row :gutter="20">

                            <el-col :sm="24">
                                <hr class="mt-30 mb-30"/>
                                <el-form-item>
                                    <el-button :loading="loading" :icon="icons.check" @click="doEditCompany"
                                               type="primary">
                                        Сохранить изменения
                                    </el-button>
                                </el-form-item>
                            </el-col>
                        </el-row>
                    </template>
                    <template v-else>
                        <FormDadataINN
                            v-model="createCompanyForm.inn"
                            label="Название компании, ИНН, адрес, директор в любой форме:"
                            placeholder="ИНН или название компании"
                            @info="info"
                        />
                    </template>
                </el-form>
            </div>
        </div>

        <el-dialog
            v-model="createAddressDialog"
            title="Новый адрес доставки"
            :width="mobile ? '100%' : '40%'"
            :destroy-on-close="true"
        >
            <el-form v-model="newAddress" label-width="120px" label-position="top">
                <el-input v-model="newAddress.id" :hidden="true" style="display: none"/>

                <FormInput
                    v-model="newAddress.name"
                    label="Удобное название"
                    placeholder="Завод"
                />
                <FormDadataAddressV2
                    v-model="newAddress.address"
                    @infoAddress="setCoords"
                    label="Фактический адрес"
                    placeholder="г.Ярославль, ул.Ленина, д.3"
                />
                <hr/>
                <p class="neutral500 textRegular">Оставьте координаты пустыми, если для их получения достаточно выше
                    указанного адреса</p>
                <FormInput
                    v-model="newAddress.latitude"
                    label="Широта адреса доставки"
                    placeholder="Широта"
                />
                <FormInput
                    v-model="newAddress.longitude"
                    label="Долгота адреса доставки"
                    placeholder="Долгота"
                />
                <hr class="mb-30"/>
                <FormAddButton
                    title="Добавить адрес"
                    @action="createNewAddress()"
                />
            </el-form>
        </el-dialog>
    </Auth>
</template>

<script>
import Auth from './../../layouts/Auth'
import {httpService} from '@/services/http.service'
import iconsMixin from "@/mixins/iconsMixin";
import {mapActions} from "pinia";
import {useCommonStore} from "@/store/common";
import functionsMixin from "@/mixins/functionsMixin";
import mobileCheckMixin from "@/mixins/mobileCheckMixin";
import FormInput from "@/views/components/Form/FormInput.vue";
import FormAddButton from "@/views/components/Form/FormAddButton.vue";
import FormDadataAddressV2 from "@/views/components/Form/FormDadataAddressV2.vue";
import FormSelect from "@/views/components/Form/FormSelect.vue";
import FormDadataINN from "@/views/components/Form/FormDadataINN.vue";
import paymentMethods from "@/mixins/paymentMethods";
import FormNumber from "@/views/components/Form/FormNumber.vue";
import RelationGroupBlock from "@/views/components/RelationGroupBlock/RelationGroupBlock.vue";

export default {
    name: 'CompanyEdit',
    data() {
        return {
            createCompanyForm: {
                id: null,
                name: null,
                short_name: null,
                inn: null,
                kpp: null,
                address: null,
                fact_address: null,
                description: null,
                email: null,
                phone: null,
                latitude: null,
                longitude: null,
                addresses: [],
                carrier: false,
                type: null,
                default_payment_method: null,
                free_unload_time: null,
                children: [],
                parent: null
            },
            loading: false,
            title: 'Создание компании',
            createAddressDialog: false,
            newAddress: {
                id: 0,
                name: null,
                address: null,
                latitude: null,
                longitude: null,
            },
            types: [{
                id: 'client',
                name: 'Клиент'
            }, {
                id: 'seller',
                name: 'Поставщик'
            }, {
                'id': 'carrier',
                'name': 'Перевозчик'
            }],
            accountModules: [],
            integrations: []
        }
    },
    components: {
        RelationGroupBlock,
        FormNumber, FormDadataINN, FormSelect, FormDadataAddressV2, FormAddButton, FormInput, Auth},
    inject: ['api'],
    mixins: [iconsMixin, functionsMixin, mobileCheckMixin, paymentMethods],
    created() {
        if (this.$route.params.id ?? this.createCompanyForm.id) this.getCompany()
    },
    methods: {
        ...mapActions(useCommonStore, [
            'pullCompanies'
        ]),
        setCoords(info) {
            this.newAddress.latitude = info.data.geo_lat;
            this.newAddress.longitude = info.data.geo_lon;
        },
        info(info) {
            if (info) {
                this.createCompanyForm.name = this.createCompanyForm.name ?? info.name.short_with_opf
                this.createCompanyForm.short_name = this.createCompanyForm.short_name ?? info.name.short
                this.createCompanyForm.inn = info.inn
                this.createCompanyForm.kpp = info.kpp
                this.createCompanyForm.address = info.address.value
            }
        },
        getCompany() {
            this.loading = true

            httpService().post(this.api.company.getOne, {
                id: this.$route.params.id ?? this.createCompanyForm.id
            }).then((response) => {
                let data = response.data

                if (data.success) {
                    this.createCompanyForm = data.company
                    this.title = 'Редактирование компании - ' + this.createCompanyForm.name
                    this.accountModules = data.accountModules
                    this.integrations = data.integrations
                }

            }).catch(() => {
                this.$message({
                    message: 'Ошибка ответа от сервера',
                    showClose: true,
                    type: 'error'
                })
                this.$router.push({name: 'logout'})
            }).finally(() => {
                this.loading = false
            })
        },
        removeCompanyAddress(item) {
            if (!item.id) {
                var idx = this.createCompanyForm.addresses.indexOf(item)
                if (idx > -1) {
                    this.createCompanyForm.addresses.splice(idx, 1)
                }
            } else {
                this.$confirm(
                    "Вы действительно хотите удалить адрес?",
                    "Подтвердите удаление",
                    {
                        confirmButtonText: "Удалить",
                        cancelButtonText: "Отмена",
                        type: "warning",
                    }
                )
                    .then(() => {
                        this.loading = true;

                        httpService()
                            .post(this.api.company.removeCompanyAddress, {
                                id: item.id,
                                companyId: this.$route.params.id
                            })
                            .then((response) => {
                                let data = response.data;

                                if (data.success)
                                    this.$message({
                                        message: "Адрес удален",
                                        showClose: true,
                                        type: "success",
                                    })
                                this.getCompany()
                                this.loading = false
                                this.pullCompanies()
                            })
                    })
                    .catch(() => {
                        this.$message({
                            message: "Удаление отменено",
                            showClose: true,
                            type: "info",
                        })
                        this.loading = false;
                    })
            }
        },
        doEditCompany() {
            this.loading = true

            httpService().post(this.api.company.create, {
                company: this.createCompanyForm
            }).then((response) => {
                let data = response.data

                if (data.success) {
                    this.createCompanyForm = data.company
                    this.$message({
                        message: 'Данные сохранены',
                        showClose: true,
                        type: 'success'
                    })
                    this.getCompany()
                }

            }).catch(() => {
                this.$message({
                    message: 'Ошибка ответа от сервера',
                    showClose: true,
                    type: 'error'
                })
                this.$router.push({name: 'logout'})
            })
            this.pullCompanies()
        },
        createNewAddress() {
            this.createCompanyForm.addresses.push(this.newAddress)
            this.newAddress = {
                id: 0,
                name: null,
                address: null,
                latitude: null,
                longitude: null,
            }
            this.createAddressDialog = false
            this.$message({
                message: 'Адрес добавлен',
                showClose: true,
                type: 'success'
            })
        }
    },
    watch: {
        $route(to, from) {
            if (to.name === from.name) {
                this.getCompany()
            }
        }
    }
}
</script>

<style lang="scss">
.dadata-address {
    .el-form-item__content {
        display: block !important;
    }
}
</style>
