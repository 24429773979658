<template>
    <Auth :title="$route.params.specGoodId ? 'Редактирование спецификации' : 'Новая спецификация'" :tabs="tabs">
        <div class="card" v-loading="loading">
            <div class="card-body">
                <el-form label-position="top">
                    <FormInput
                        v-model="form.name"
                        label="Название спецификации"
                        :placeholder="$route.params.specGoodId ? 'Загрузка...' : 'Например, БСТ В12,5П3 F50W2'"
                        :required="true"
                        :disabled="loading"
                    />

                    <el-tabs v-model="tab" style="min-height: 450px;">
                        <el-tab-pane label="Продукция" name="goods">
                            <p class="textXS textRegular neutral400">
                                Продукция, к которой относится спецификация:
                            </p>

                            <el-row :gutter="15" class="mb-5">
                                <el-col :sm="22">
                                    <span class="textXS neutral700 textMedium mr-15">Продукция</span>
                                    <el-button :disabled="loading" :icon="icons.plus" type="text"
                                               class="textMedium primary600"
                                               @click.prevent="addNewRow" plain>добавить
                                    </el-button>
                                </el-col>
                            </el-row>
                            <div v-for="(good, index) in form.goods" :key="index">
                                <el-row :gutter="15">
                                    <el-col :sm="22">
                                        <FormSelect
                                            v-model="good.id"
                                            label=""
                                            placeholder="Выберите продукцию"
                                            new-item="good"
                                            :disabled="loading"
                                        />
                                    </el-col>
                                    <el-col :sm="2">
                                        <el-form-item label-width="0" class="d-flex d-flex-end">
                                            <el-button type="danger" :icon="icons.delete"
                                                       @click.prevent="deleteRow(index, good)"></el-button>
                                        </el-form-item>
                                    </el-col>
                                </el-row>
                            </div>
                        </el-tab-pane>
                        <el-tab-pane label="Рецепты" name="mixGoods">
                            <p class="textXS textRegular neutral400">
                                Рецепты с оборудования, к которым относится спецификация:
                            </p>

                            <div v-for="(good, index) in form.mixGoods" :key="index">
                                <el-row :gutter="15">
                                    <el-col :sm="5">
                                        <div class="cutOverflow" style="padding-top: 8px">
                                            <IntegrationLogo
                                                :integration-unit-id="good.integration_unit_id"
                                            />
                                        </div>
                                    </el-col>
                                    <el-col :sm="17">
                                        <FormSelect
                                            v-model="good.id"
                                            label=""
                                            placeholder="Выберите продукцию"
                                            new-item="good"
                                            :disabled="loading"
                                            :integration-unit-id="good.integration_unit_id"
                                        />
                                    </el-col>
                                    <el-col :sm="2">
                                        <el-form-item label-width="0" class="d-flex d-flex-end">
                                            <el-button :disabled="loading || !good.id" type="danger"
                                                       :icon="icons.delete"
                                                       @click="() => good.id = null"></el-button>
                                        </el-form-item>
                                    </el-col>
                                </el-row>
                            </div>
                        </el-tab-pane>
                        <el-tab-pane label="Параметры качества" name="info">
                            <el-row :gutter="20">
                                <el-col :sm="8">

                                    <FormSelect
                                        label="Марка бетона (М)"
                                        placeholder="М150"
                                        v-model="form.info.brand"
                                        :items="brands"
                                        :disabled="loading"
                                    />

                                    <FormSelect
                                        label="Класс бетона (В)"
                                        placeholder="В22,5"
                                        v-model="form.info.class"
                                        :items="classes"
                                        :disabled="loading"
                                    />

                                    <FormSelect
                                        label="Подвижность бетона (П)"
                                        placeholder="П4"
                                        v-model="form.info.mobility"
                                        :items="mobility"
                                        :disabled="loading"
                                    />

                                    <FormInput
                                        label="Прочность (Мпа)"
                                        placeholder="28,9"
                                        v-model="form.info.strength"
                                        :disabled="loading"
                                    />

                                    <FormSelect
                                        label="Класс материалов по уд. эфф. акт. естеств. радионуклидов"
                                        placeholder="I"
                                        v-model="form.info.classRadio"
                                        :items="classRadio"
                                        :disabled="loading"
                                    />

                                </el-col>
                                <el-col :sm="8">
                                    <FormInput
                                        label="Значение Аэфф (БК/л)"
                                        placeholder="370"
                                        v-model="form.info.aeff"
                                        :disabled="loading"
                                    />

                                    <FormInput
                                        label="Добавка"
                                        placeholder="Название добавки"
                                        v-model="form.info.additiveName"
                                        :disabled="loading"
                                    />

                                    <FormInput
                                        label="Масса добавки"
                                        placeholder="12"
                                        v-model="form.info.additiveWeight"
                                        :disabled="loading"
                                    />

                                    <FormInput
                                        label="№ номинального состава бетонной смеси"
                                        placeholder="6"
                                        v-model="form.info.numberComposition"
                                        :disabled="loading"
                                    />

                                    <FormInput
                                        label="Сохр. удобоукл. и других показателей, (ч-мин)"
                                        placeholder="02:00"
                                        v-model="form.info.workabilityTime"
                                        :disabled="loading"
                                    />
                                </el-col>
                                <el-col :sm="8">
                                    <FormInput
                                        label="Наибольшая крупность заполнителя (мм)"
                                        placeholder="20"
                                        v-model="form.info.aggregateSize"
                                        :disabled="loading"
                                    />

                                    <FormSelect
                                        label="Класс бетона, 28 суток (В)"
                                        placeholder="В22,5"
                                        v-model="form.info.class28d"
                                        :items="classes"
                                        :disabled="loading"
                                    />

                                    <FormInput
                                        label="Прочность, 28 суток (Мпа)"
                                        placeholder="28,9"
                                        v-model="form.info.strength28d"
                                        :disabled="loading"
                                    />

                                    <FormSelect
                                        label="Класс бетона, 7 суток (В)"
                                        placeholder="В22,5"
                                        v-model="form.info.class7d"
                                        :items="classes"
                                        :disabled="loading"
                                    />

                                    <FormInput
                                        label="Прочность, 7 суток (Мпа)"
                                        placeholder="28,9"
                                        v-model="form.info.strength7d"
                                        :disabled="loading"
                                    />
                                </el-col>
                            </el-row>
                        </el-tab-pane>
                    </el-tabs>
                </el-form>

                <div class="mt-15">
                    <el-button type="primary" :disabled="loading" size="large" @click="doCreate()">
                        {{ $route.params.specGoodId ? 'Сохранить' : 'Создать' }}
                    </el-button>
                    <el-button v-if="$route.params.specGoodId" @click="doRemoveGood()" :icon="icons.delete"/>
                </div>
            </div>
        </div>
    </Auth>
</template>


<script>
import FormInput from "@/views/components/Form/FormInput.vue";
import FormSelect from "@/views/components/Form/FormSelect.vue";
import {httpService} from "@/services/http.service";
import {mapState} from "pinia";
import {useCommonStore} from "@/store/common";
import IntegrationLogo from "@/views/components/Blocks/IntegrationLogo.vue";
import iconsMixin from "@/mixins/iconsMixin";
import mobileCheckMixin from "@/mixins/mobileCheckMixin";
import Auth from "@/views/layouts/Auth.vue";
import LabTabs from "@/mixins/tabs/labTabs";
import concreteInfo from "@/mixins/concreteInfo";

export default {
    name: "LabGoodsCreateSpec",
    components: {Auth, IntegrationLogo, FormSelect, FormInput},
    mixins: [iconsMixin, mobileCheckMixin, LabTabs, concreteInfo],
    inject: ['api'],
    data() {
        return {
            loading: false,
            tab: 'goods',
            form: {
                id: null,
                name: null,
                goods: [],
                mixGoods: [],
                info: {
                    class: null,
                    brand: null,
                    mobility: null,
                    strength: null,
                    classRadio: null,
                    aeff: null,
                    additiveName: null,
                    additiveWeight: null,
                    numberComposition: null,
                    workabilityTime: null,
                    aggregateSize: null,
                    class7d: null,
                    strength7d: null,
                    class28d: null,
                    strength28d: null,
                }
            },
        }
    },
    created() {
        if (this.$route.params.specGoodId) {
            this.getSpecGood()
        } else {
            this.addNewRow()
        }
    },
    computed: {
        ...mapState(useCommonStore, {
            integrations: 'getIntegrations',
        }),
    },
    methods: {
        getSpecGood() {
            this.loading = true

            httpService().post(this.api.lab.getSpecGood, {
                account_module_reserve_id: this.$route.params.id,
                module_key: this.$route.meta.moduleKey,
                specGoodId: this.$route.params.specGoodId
            }).then((response) => {
                let data = response.data

                if (data.success) {
                    this.form = data.specGood
                    this.loading = false

                }
            }).catch(() => {
                this.$message({
                    message: 'Операция завершилась неудачей',
                    showClose: true,
                    type: 'error'
                })
                this.loading = false
            })
        },
        addNewRow() {
            this.form.goods.unshift({
                id: null,
            })
        },
        deleteRow(index, good) {
            var idx = this.form.goods.indexOf(good)
            if (idx > -1) {
                this.form.goods.splice(idx, 1)
            }
        },
        doRemoveGood() {
            this.$confirm(
                'Вы действительно хотите удалить спецификацию?',
                'Подтвердите удаление',
                {
                    confirmButtonText: 'Удалить',
                    cancelButtonText: 'Отмена',
                    type: 'warning',
                }
            )
                .then(() => {
                    httpService().post(this.api.good.remove, {
                        goodId: this.$route.params.specGoodId
                    }).then(() => {
                        this.$message({
                            message: 'Спецификация удалена',
                            showClose: true,
                            type: 'success'
                        })
                        this.$router.push({name: 'LabGoods', params: {id: this.$route.params.id}})
                    }).catch(() => {
                        this.$message({
                            message: 'Произошла ошибка при удалении спецификации',
                            type: 'error',
                            showClose: true,
                        })
                    })
                }).catch(() => {
                this.$message({
                    message: 'Удаление отменено',
                    showClose: true,
                    type: 'info'
                })
            })
        },
        doCreate() {
            this.loading = true

            httpService().post(this.api.lab.createSpecGood, {
                account_module_reserve_id: this.$route.params.id,
                module_key: this.$route.meta.moduleKey,
                form: this.form
            }).then((response) => {
                let data = response.data

                if (data.success) {
                    let message = 'Спецификация создана'
                    if (this.$route.params.specGoodId) {
                        message = 'Спецификация обновлена'
                    }
                    this.$message({
                        message: message,
                        showClose: true,
                        type: 'success'
                    })
                    this.loading = false
                    if (!this.$route.params.specGoodId) {
                        // this.$router.push({ name: 'LabGoods', params: { id: this.$route.params.id } })
                        this.$router.push({ name: 'LabGoodsEditSpec', params: { id: this.$route.params.id, specGoodId: data.id } })
                    }
                }
            }).catch((error) => {
                this.$message({
                    message: error.response.data.message,
                    showClose: true,
                    type: 'error'
                })
                this.loading = false
            })
        },
    },
    watch: {
        integrations: {
            handler() {
                if (this.integrations.length) {
                    if (!this.$route.params.specGoodId) {
                        this.integrations.forEach((integration) => {
                            if (integration.integration.key === 'SmartMix') {
                                this.form.mixGoods.push({
                                    id: null,
                                    integration_unit_id: integration.id
                                })
                            }
                        })
                    }
                }
            },
            deep: true,
            immediate: true
        },
        '$route.params.specGoodId': {
            handler() {
                if (this.$route.params.specGoodId) {
                    this.getSpecGood()
                }
            },
            deep: false,
            immediate: false
        }
    }
}
</script>

<style scoped>

</style>
