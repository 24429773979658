<template>
    <Auth title="Спецификации" :tabs="tabs">
        <div class="card">
            <div class="card-body">

                <TableHeadBlock
                    :new="true"
                    v-model="search"
                    :loading="loading"
                    :disable-filter="true"
                    @moduleGet="moduleGet()"
                    create-button-label="Создать спецификацию"
                    @openCreateValueDrawer="() => $router.push({ name: 'LabGoodsCreateSpec' })"
                />

                <EmptyState
                    :values="displayData"
                    :search="search"
                    :loading="loading"
                    list-types="спецификаций"
                />

                <el-table
                    v-loading="loading"
                    v-if="displayData.length > 0"
                    :data="displayData"
                    class="w-100 mt-15">
                    <el-table-column
                        prop="id"
                        label="#"
                        width="90"
                    />
                    <el-table-column
                        prop="name"
                        label="Спецификация"
                    >
                        <template v-slot="scope">
                            <a class="text-none textMedium primary600"
                               size="small"
                               :href="'/settings/goods/' + scope.row.id"
                               target="_blank">
                                <span v-html="highlightMatch(scope.row.name, search)"></span>
                            </a>
                        </template>
                    </el-table-column>
                    <el-table-column
                        prop="specGoods"
                        label="Продукция"
                    >
                        <template v-slot="scope">
                            <template v-if="scope.row.specGoods.length">
                                <a class="text-none textMedium neutral900 d-block"
                                   size="small"
                                   v-for="(product, index) in scope.row.specGoods" :key="index"
                                   :href="'/settings/goods/' + product.id"
                                   target="_blank">
                                    <span v-html="highlightMatch(product.name, search)"></span>
                                    <span class="textRegular neutral300 ml-5"
                                          v-if="product.integration_unit_id">({{
                                            getIntegrationLabel(product.integration_unit_id)
                                        }})</span>
                                </a>
                            </template>
                            <div v-else class="textMedium neutral900">-</div>
                        </template>
                    </el-table-column>
                    <el-table-column
                        prop="name"
                        label="Рецепты"
                    >
                        <template v-slot="scope">
                            <div v-if="scope.row.mixGoods.length">
                                <a class="text-none textRegular neutral900 d-block"
                                   v-for="(good, index) in scope.row.mixGoods" :key="index"
                                   size="small"
                                   :href="'/settings/goods/' + good.id"
                                   target="_blank"
                                   :class="{ 'mb-5': index < scope.row.mixGoods.length - 1 }">
                                    <span><span v-html="highlightMatch(good.name, search)"></span>
                                        <span class="textRegular neutral300 ml-5"
                                              v-if="good.integration_unit_id">({{
                                                getIntegrationLabel(good.integration_unit_id)
                                            }})</span></span>
                                </a>
                            </div>
                            <div v-else>
                                -
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column
                        fixed="right"
                        label=""
                        width="120px">
                        <template v-slot="scope">
                            <el-button
                                size="small"
                                @click="() => $router.push({ name: 'LabGoodsEditSpec', params: { id: $route.params.id, specGoodId: scope.row.id } })"
                            >
                                Подробнее
                            </el-button>
                        </template>
                    </el-table-column>
                </el-table>


                <TablePagination
                    v-model="pageSize"
                    :total="valueLength"
                    @action="setPage"
                />

                <div class="card labSpecView" v-if="false">
                    <div class="card-body">
                        <template v-if="selectedSpec">
                            <h3 class="neutral900 textMedium textLG">
                                {{ selectedSpec?.name }}
                            </h3>

                            <div class="textRegular textSM"
                                 v-if="selectedSpec.relation_group?.goods?.length && selectedSpec.relation_group.goods.some(el => el.id !== selectedSpec.id)">
                                <span class="neutral300">Связь с продукцией:</span>

                                <LabSpecGoods
                                    :goods="selectedSpec.relation_group.goods.filter(el => el.id !== selectedSpec.id)"
                                />
                            </div>

                            <LabSpecComponents
                                :components="selectedSpec.components"
                            />
                        </template>
                        <template v-else>
                            <div id="emptyState">
                                <EmptyListIcon/>
                                <h3 class="mb-0 mt-0 neutral900">Не выбрана спецификация</h3>
                                <p class="mt-0 textRegular textSM neutral400">Выберите или создайте
                                    спецификацию для работы</p>
                            </div>
                        </template>
                    </div>
                </div>

            </div>
        </div>
    </Auth>
</template>

<script>
import Auth from '../layouts/Auth'
import TableHeadBlock from "@/views/components/Table/TableHeadBlock.vue";
import EmptyState from "@/views/components/EmptyState.vue";
import TablePagination from "@/views/components/Table/TablePagination.vue";
import tableMixin from "@/mixins/tableMixin";
import iconsMixin from "@/mixins/iconsMixin";
import functionsMixin from "@/mixins/functionsMixin";
import mobileCheckMixin from "@/mixins/mobileCheckMixin";
import {httpService} from "@/services/http.service";
import LabTabs from "@/mixins/tabs/labTabs";
import EmptyListIcon from "@/views/components/Icons/EmptyListIcon.vue";
import LabSpecGoods from "@/views/lab/components/LabSpecGoods.vue";
import LabSpecComponents from "@/views/lab/components/LabSpecComponents.vue";
import {mapState} from "pinia";
import {useCommonStore} from "@/store/common";

export default {
    name: 'LabGoods',
    computed: {
        ...mapState(useCommonStore, {
            integrations: 'getIntegrations',
        })
    },
    mixins: [LabTabs, tableMixin, iconsMixin, functionsMixin, mobileCheckMixin],
    data() {
        return {
            loading: false,
            createDrawerOpen: false,
            selectedSpec: null
        }
    },
    components: {
        LabSpecComponents,
        LabSpecGoods,
        EmptyListIcon,
        TablePagination, EmptyState,
        TableHeadBlock,
        Auth,
    },
    inject: [
        'api'
    ],
    created() {
        this.moduleGet()
    },
    methods: {
        highlightMatch(text, search) {
            if (!search) return text;
            const regex = new RegExp(search, 'gi');
            return text.replace(regex, (match) => `<mark>${match}</mark>`);
        },
        moduleGet() {
            this.loading = true

            httpService().post(this.api.lab.getSpecGoods, {
                account_module_reserve_id: this.$route.params.id,
                module_key: this.$route.meta.moduleKey
            }).then((response) => {
                let data = response.data

                if (data.success) {
                    this.values = data.values
                    this.loading = false
                }
            }).catch((error) => {
                this.$message({
                    message: error.response.data.message,
                    showClose: true,
                    type: 'error'
                })
                this.loading = false
            })
        },
        getIntegrationLabel(integrationUnitId) {
            let integrationUnit = this.integrations.find(integration => integration.id === integrationUnitId)
            let label = null

            if (integrationUnit) {
                let key = integrationUnit.integration.key

                label = key

                if (key === 'SmartMix') {
                    label = integrationUnit.product + ': ' + integrationUnit.account_module.name
                }
            }

            return label
        },
        setCreateDrawerOpen() {
            this.createDrawerOpen = true
        },
        filterValues() {
            this.filtered = this.values

            if (this.search) {
                this.filtered = this.values.filter(data => {
                    const searchCondition = (data.name?.toLowerCase() ?? '').includes(this.search.toLowerCase());
                    const specGoodsCondition = data.specGoods?.some(specGood => (specGood.name?.toLowerCase() ?? '').includes(this.search.toLowerCase()));
                    const mixGoodsCondition = data.mixGoods?.some(mixGood => (mixGood.name?.toLowerCase() ?? '').includes(this.search.toLowerCase()));
                    return !this.search || searchCondition || specGoodsCondition || mixGoodsCondition;
                });
            }
            this.valueLength = this.filtered.length
            return this.filtered.slice(this.pageSize * this.page - this.pageSize, this.pageSize * this.page)
        }
    }
}
</script>

<style scoped>

</style>
