<template>
    <div>
        <el-form v-loading="loading" ref="form" class="operator-form" label-position="top">
            <el-form-item label="Тип отгрузки">
                <el-radio-group v-model="createMixOrderForm.type" @change="resetForm()">
                    <el-radio label="delivery">Доставка</el-radio>
                    <el-radio label="take-away">Самовывоз</el-radio>
                    <el-radio label="production">Производство</el-radio>
                </el-radio-group>
            </el-form-item>
            <hr/>

            <el-row :gutter="20">

                <el-col :sm="10">

                    <FormSelect
                        v-model="createMixOrderForm.goodMixId"
                        label="Рецепт"
                        placeholder="Выберите рецепт"
                        :items="mixGoods"
                    />

                </el-col>
                <el-col :sm="6">

                    <FormNumber
                        v-model="createMixOrderForm.total"
                        label="Объем"
                        placeholder="8.2"
                        step="0.1"
                    />

                </el-col>
                <el-col :sm="8">

                    <FormNumber
                        v-model="createMixOrderForm.doc"
                        label="№ отгрузки в программе БСУ"
                        placeholder="345"
                        step="1"
                    />

                </el-col>
            </el-row>

            <template v-if="createMixOrderForm.type !== 'production'">
                <hr class="mb-15"/>

                <p class="textRegular textSM neutral300">Дополнительные параметры:</p>

                <el-form-item label="Тип клиента">
                    <el-radio-group v-model="createMixOrderForm.individual" @change="resetCompany">
                        <el-radio :label="false">Юр.лицо</el-radio>
                        <el-radio :label="true">Физ.лицо</el-radio>
                    </el-radio-group>
                </el-form-item>

                <FormSelect
                    v-if="!createMixOrderForm.individual"
                    v-model="createMixOrderForm.companyId"
                    label="Контрагент"
                    placeholder="Выберите контрагента"
                    new-item="company"
                    :new-item-data="createMixOrderForm.companyId"
                />

                <FormSelect
                    v-else
                    v-model="createMixOrderForm.companyId"
                    label="Контрагент"
                    placeholder="Выберите физ.лицо"
                    new-item="company"
                    :new-item-data="createMixOrderForm.companyId"
                />

                <FormSelect
                    v-model="createMixOrderForm.vehicleId"
                    :label="'Авто'"
                    placeholder="Выберите транспортное средство"
                    :items="vehicles.filter(el => el.type === 'mix')"
                />
            </template>
        </el-form>

        <el-button type="primary" @click="createMixOrder()" class="operator-button" size="large"
                   :disabled="loading">
            Добавить отгрузку
        </el-button>
    </div>
</template>

<script>
import FormSelect from "@/views/components/Form/FormSelect.vue";
import FormNumber from "@/views/components/Form/FormNumber.vue";
import {mapStores} from "pinia";
import {useMixStore} from "@/store/mix";
import {httpService} from "@/services/http.service";

export default {
    name: "OperatorOrderCreate",
    components: {FormNumber, FormSelect},
    props: ['mixId'],
    inject: ['api'],
    computed: {
        ...mapStores(useMixStore)
    },
    data() {
        return {
            loading: false,
            createMixOrderForm: {
                type: 'delivery',
                doc: null,
                goodMixId: null,
                total: null,
                companyId: null,
                vehicleId: null,
                createdAt: null,
                individual: false,
            },
            mixGoods: [],
            vehicles: []
        }
    },
    created() {
        httpService().post(this.api.mix.getGoods, {
            account_module_reserve_id: this.mixId,
            module_key: 'mix',
        }).then((response) => {
            let data = response.data

            if (data.success)
                this.mixGoods = data.values
        })

        httpService().post(this.api.vehicle.get).then((response) => {
            let data = response.data

            if (data.success)
                this.vehicles = data.vehicles
            this.loading = false
        })
    },
    methods: {
        resetCompany() {
            this.createMixOrderForm.companyId = null
        },
        resetForm() {
            this.createMixOrderForm.companyId = null
            this.createMixOrderForm.vehicleId = null
        },
        createMixOrder() {
            this.loading = true

            httpService().post(this.api.operator.createMixOrder, {
                account_module_reserve_id: this.mixId,
                module_key: 'mix',
                order: this.createMixOrderForm
            }).then((response) => {
                let answer = response.data

                if (answer.success) {
                    this.$message({
                        message: 'Отгрузка добавлена',
                        showClose: true,
                        type: 'success'
                    })
                    window.setTimeout(() => {
                        this.$emit('update')
                    }, 250)
                    this.$emit('close')
                }

            }).catch((error) => {
                this.$message({
                    message: error.response.data.message,
                    showClose: true,
                    type: 'error'
                })
                this.loading = false
            })
        }
    }
}
</script>

<style scoped>

</style>
