<template>
    <div class="widget-card widgetControlGoods">
        <div class="widget-card-title">{{ title }}
            <el-button class="widget-reload" :loading="loadingButton" @click="updateWidget" :icon="icons.refresh"
                       circle></el-button>
        </div>
        <div class="widget-card-body">
            <template v-if="!selectedGood.good">
                <template v-if="values.length > 0">
                <div class="widgetControlGoodsItem" v-for="(item, index) in values" :key="index">
                    <span class="textMD textBold neutral100">{{ getData(item.goodId, 'good') }}</span>
                    <div class="widgetControlGoodsItemTask" @click="selectGood(item, task)"
                         v-for="(task, index) in item.tasksByDate" :key="index">
                        <span class="textBold">{{ getDateFromUnix(task.date, 'DD.MM') }}</span> <span
                        class="neutral400">- {{ task.total }}
                        {{ getData(item.goodId, 'good', 'unit') }}</span>
                    </div>
                </div>
                </template>
                <template v-else>
                    <div class="widgetControlGoodsItem text-center pt-30 pb-30 mt-30">
                        <span class="textMD textBold neutral100">Нет доступных заданий</span>
                    </div>
                </template>
            </template>
            <template v-else>
                <div class="widgetControlGoodsItem">
                    <span class="textMD textBold neutral100">{{ getData(selectedGood.good.goodId, 'good') }}</span>
                    <div class="widgetControlGoodsSelected">
                        <el-button class="w-100" @click="resetGood()" :icon="icons.arrowLeft">вернуться к заданиям
                        </el-button>
                        <div class="mt-30">
                            <div class="w-100 d-block mb-10">
                                <span class="textBold">Партия от {{
                                        getDateFromUnix(selectedGood.task.date, 'DD.MM')
                                    }}</span> <span class="neutral400">- {{ selectedGood.task.total }}
                        {{ getData(selectedGood.good.goodId, 'good', 'unit') }}</span>
                            </div>
                            <el-form label-position="top" v-for="(unit, index) in selectedGood.units" :key="index">
                                <FormSelect
                                    label="Выберите внутренний склад"
                                    placeholder="Выберите склад"
                                    v-model="unit.unitId"
                                    value-key="module_reserve_id"
                                    :items="selectedGood.storehouseUnits"
                                />

                                <el-form-item label="">
                                    <el-input-number
                                        :disabled="!unit.unitId"
                                        class="w-100"
                                        v-model="unit.total"
                                        :min="0"
                                        :max="selectedGood.task.total"
                                    />
                                </el-form-item>
                                <hr/>
                            </el-form>
                            <el-link class="pt-10 pb-10 w-100" @click="selectedGood.units.push({unitId: null, total: null})">+
                                Добавить сорт
                            </el-link>
                            <hr/>
                            <el-button
                                @click="doStep(selectedGood)"
                                :disabled="getSum() !== selectedGood.task.total"
                                class="w-100 mt-15"
                                type="primary">
                                <template v-if="getSum() !== selectedGood.task.total">
                                    Распределено: {{ getSum() }} из {{ selectedGood.task.total }}
                                </template>
                                <template v-else>Подтвердить</template>
                            </el-button>
                        </div>
                    </div>
                </div>
            </template>
        </div>
    </div>
</template>

<script>
import functionsMixin from '@/mixins/functionsMixin'
import {httpService} from '@/services/http.service'
import iconsMixin from "@/mixins/iconsMixin";
import FormSelect from "@/views/components/Form/FormSelect.vue";

export default {
    name: 'ProductionControlGoodsWidget',
    components: {FormSelect},
    props: ['id',],
    inject: ['api'],
    mixins: [functionsMixin, iconsMixin],
    created() {
        this.updateWidget()
    },
    data() {
        return {
            loadingButton: false,
            loading: true,
            title: 'Контроль качества',
            values: [],
            selectedGood: {
                type: 'control',
                good: null,
                task: null,
                storehouseUnits: [],
                units: [{
                    unitId: null,
                    total: null
                }]
            },
            productionId: null,
            modules: [],
            firstLoad: true
        }
    },
    methods: {
        selectGood(good, task) {
            this.selectedGood.good = good
            this.selectedGood.task = task
            if (this.modules.length > 0) this.selectedGood.storehouseUnits = this.modules.find(el => el.module_reserve_id === this.selectedGood.good.storehouseId && el.module_key === 'storehouse').units
        },
        resetGood() {
            this.selectedGood = {
                type: 'control',
                good: null,
                task: null,
                storehouseUnits: [],
                units: [{
                    unitId: null,
                    total: null
                }]
            }
        },
        doStep(value) {
            this.$confirm(
                'Вы подтверждаете выполнение задания?',
                'Подтвердите выполнение',
                {
                    confirmButtonText: 'Да',
                    cancelButtonText: 'Отмена',
                    type: 'warning',
                }
            )
                .then(() => {
                    httpService().post(this.api.productionOrderStep.do, {
                        account_module_reserve_id: this.productionId,
                        module_key: 'production',
                        value: value
                    }).then((response) => {
                        let data = response.data
                        if (data.success) {
                            this.$message({
                                message: 'Задание выполнено',
                                showClose: true,
                                type: 'success'
                            })
                            this.resetGood()
                            this.updateWidget()
                        }
                    }).catch(() => {
                        this.$message({
                            message: 'Ошибка',
                            type: 'error',
                            showClose: true,
                        })
                    })
                }).catch(() => {
                this.$message({
                    message: 'Выполнение отменено',
                    showClose: true,
                    type: 'info'
                })
            })
        },
        getSum() {
            let sum = 0
            this.selectedGood.units.forEach(el => sum = sum + Math.floor(el.total))
            return sum
        },
        getWidget() {
            httpService().post(this.api.widget.getOne, {
                id: this.id
            }).then((response) => {
                    let data = response.data
                    if (data.success) {
                        this.values = data.data.values
                        this.productionId = data.data.productionId
                        if (this.firstLoad && this.productionId) this.getModules()
                        this.firstLoad = false
                        this.loadingButton = false
                    }
                }
            )
        },
        updateWidget() {
            this.loadingButton = true
            this.getWidget()
        },
        getModules() {
            if (this.productionId) {
                httpService().post(this.api.production.getInfoForGoodCreate, {
                    account_module_reserve_id: this.productionId,
                    module_key: 'production'
                }).then((response) => {
                    let data = response.data

                    if (data.success) {
                        this.modules = data.modules
                    }

                })
            }
        }
    }
}
</script>

<style scoped>

</style>
