<template>
    <Auth title="Отгрузки" :tabs="tabs" :settings="$route.params.id">
        <div class="card">
            <div class="card-body">
                <TableHeadBlock
                    :new="true"
                    v-model="search"
                    :disable-search="true"
                    :loading="loading"
                    :show-date-filter="true"
                    :current-date="getDate"
                    :disable-filter="true"
                    create-button-label="Добавить отгрузку"
                    :filterShow="filterShow"
                    :disable-update-button="true"
                    @moduleGet="moduleGet"
                    @openFilterBlock="openFilterBlock"
                    @openCreateValueDrawer="openCreateMixOrderDrawer"
                    @changeDate="changeDate"
                >
                    <template v-slot:searchAndFilter>
                        <el-button-group class="dateFilter changeActiveTabButtons">
                            <el-button class="group-btn" :disabled="loading"
                                       @click="changeActiveTab('table')"
                                       :type="activeTab === 'table' ? 'info' : 'default'">
                                <svg width="22" height="22" viewBox="0 0 24 24" fill="none"
                                     xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M19 4H5C4.44772 4 4 4.44772 4 5V19C4 19.5523 4.44772 20 5 20H19C19.5523 20 20 19.5523 20 19V5C20 4.44772 19.5523 4 19 4Z"
                                        stroke="#49515F" stroke-width="1.66667" stroke-linecap="round"
                                        stroke-linejoin="round"/>
                                    <path d="M12 4V20" stroke="#49515F" stroke-width="1.66667" stroke-linecap="round"
                                          stroke-linejoin="round"/>
                                    <path d="M20 15L4 15" stroke="#49515F" stroke-width="1.66667" stroke-linecap="round"
                                          stroke-linejoin="round"/>
                                    <path d="M20 9L4 9" stroke="#49515F" stroke-width="1.66667" stroke-linecap="round"
                                          stroke-linejoin="round"/>
                                </svg>
                            </el-button>
                            <el-button class="group-btn" :disabled="loading"
                                       @click="changeActiveTab('timeline')"
                                       :type="activeTab === 'timeline' ? 'info' : 'default'">
                                <svg width="20" height="20" viewBox="0 0 20 20" fill="none"
                                     xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M10.833 7.5V3.33333C10.833 2.8731 10.4599 2.5 9.99967 2.5L3.33301 2.5L3.33301 7.5L10.833 7.5Z"
                                        stroke="#49515F" stroke-width="1.66667" stroke-linecap="round"
                                        stroke-linejoin="round"/>
                                    <path
                                        d="M16.6663 11.6667V8.33333C16.6663 7.8731 16.2932 7.5 15.833 7.5L3.33301 7.5L3.33301 12.5L15.833 12.5C16.2932 12.5 16.6663 12.1269 16.6663 11.6667Z"
                                        stroke="#49515F" stroke-width="1.66667" stroke-linecap="round"
                                        stroke-linejoin="round"/>
                                    <path
                                        d="M13.333 16.6667V12.5L3.33301 12.5L3.33301 17.5L12.4997 17.5C12.9599 17.5 13.333 17.1269 13.333 16.6667Z"
                                        stroke="#49515F" stroke-width="1.66667" stroke-linecap="round"
                                        stroke-linejoin="round"/>
                                </svg>
                            </el-button>
                        </el-button-group>
                    </template>
                    <template v-slot:buttonsBlock>
                        <el-button :icon="icons.download"
                            @click="printerReportsDialogShow=true"
                            class="ci-btn ci-btn_default"
                            :disabled="values.length === 0">
                                Скачать график
                        </el-button>
                        <button
                            @click="printerDialogShow=true"
                            v-if="!mobile"
                            class="ci-btn ci-btn_default"
                            :disabled="values.length === 0"
                        >
                            <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path stroke="currentColor"
                                      d="M6 17H4C3.44772 17 3 16.5523 3 16L3 8C3 7.44772 3.44772 7 4 7L20 7C20.5523 7 21 7.44771 21 8V16C21 16.5523 20.5523 17 20 17H18"
                                      stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                <path stroke="currentColor"
                                      d="M7 21L17 21C17.5523 21 18 20.5523 18 20V16C18 15.4477 17.5523 15 17 15L7 15C6.44772 15 6 15.4477 6 16L6 20C6 20.5523 6.44772 21 7 21Z"
                                      stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                <path stroke="currentColor" d="M10 11H14" stroke-width="2" stroke-linecap="round"
                                      stroke-linejoin="round"/>
                                <path stroke="currentColor"
                                      d="M7 7L17 7C17.5523 7 18 6.55229 18 6V4C18 3.44772 17.5523 3 17 3L7 3C6.44772 3 6 3.44772 6 4L6 6C6 6.55229 6.44772 7 7 7Z"
                                      stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                            </svg>
                            <span v-if="windowWidth > 1200">Печать</span>
                        </button>
                    </template>
                </TableHeadBlock>

                <Filter
                    :filter="filter"
                    :loading="loading"
                    :filter-show="filterShow"
                />


                <template v-if="activeTab === 'table'">
                    <MixPageOrdersTable v-if="mixes.length > 0" :no-header="true" :date="getDate" :mixes="mixes" :dispatch="true" @updateCountOrders="updateCountOrders"/>
                </template>
                <template v-else-if="activeTab === 'timeline'">
                    <div class="mt-30">
                        <MixOrderGroupBigTimelineOrders
                            :mixers="mixers"
                            :account_module_mix_ids="account_module_mix_ids"
                            :mixIds="mix_ids"
                            :time="time"
                            :date="filters['filter[date]']"
                        />
                    </div>
                </template>
            </div>
        </div>

        <el-dialog
            title="Создание отгрузки"
            v-model="createMixOrderDrawer"
            direction="rtl"
            :size="!mobile ? '60%' : '100%'"
            :destroy-on-close="true">
            <MixPageOrderCreate
                :goods="goods"
                :companies="companies"
                :mixes="mixes"
                :dispatch="true"
                @closeDrawer="closeMixOrderDrawer"
                @moduleGet="moduleGet"
            />
        </el-dialog>

        <el-dialog
            v-model="printerDialogShow"
            title="Печать документов"
            :width="drawerSize"
            :destroy-on-close="true"
        >
            <MixOrderGroupOrdersPrinter
                :table-data="values.filter(el => el.delivery !== 'take-away')"
                :date="getDate"
                :moduleId="$route.params.id"
                :moduleKey="$route.meta.moduleKey"
            />
        </el-dialog>

         <el-dialog
            v-model="printerReportsDialogShow"
            title="Печать документов"
            :width="drawerSize"
            :destroy-on-close="true"
        >
            <MixOrderGroupReportsPrinter
                :orders="values"
                :orderGroups="orderGroups"
            />
        </el-dialog>
    </Auth>
</template>

<script>
import Auth from '@/views/layouts/Auth.vue'
import tableMixin from '@/mixins/tableMixin'
import basicMixin from '@/mixins/basicMixin'
import moment from 'moment/moment'
import functionsMixin from '@/mixins/functionsMixin'
import iconsMixin from "@/mixins/iconsMixin";
import mobileCheckMixin from "@/mixins/mobileCheckMixin";
import dispatchTabs from "@/mixins/tabs/dispatchTabs";
import TableHeadBlock from "@/views/components/Table/TableHeadBlock.vue";
import MixPageOrdersTable from "@/views/components/MixPage/Orders/MixPageOrdersTable.vue";
import {httpService} from "@/services/http.service";
import MixOrderGroupBigTimelineOrders from "@/views/components/MixPage/OrdersGroup/MixOrderGroupBigTimelineOrders.vue";
import {useOrderGroupsStore} from '@/store/orderGroups'
import {mapState} from 'pinia'
import MixPageOrderCreate from "@/views/components/MixPage/Orders/MixPageOrderCreate.vue";
import {useCommonStore} from "@/store/common";
import MixOrderGroupOrdersPrinter from "@/views/components/MixPage/OrdersGroup/MixOrderGroupOrdersPrinter.vue";
import MixOrderGroupReportsPrinter from "@/views/components/MixPage/OrdersGroup/MixOrderGroupReportsPrinter.vue";

export default {
    name: 'DispatchOrders',
    components: {
        MixOrderGroupOrdersPrinter,
        MixOrderGroupReportsPrinter,
        MixPageOrderCreate,
        MixOrderGroupBigTimelineOrders,
        MixPageOrdersTable,
        TableHeadBlock,
        Auth
    },
    mixins: [tableMixin, basicMixin, functionsMixin, iconsMixin, mobileCheckMixin, dispatchTabs],
    inject: ['api'],
    data() {
        return {
            loading: false,
            // filter: {
            //     date: {
            //         value: moment(),
            //         type: 'date'
            //     },
            // },
            activeTab: 'table',
            createMixOrderDrawer: false,
            printerDialogShow: false,
            printerReportsDialogShow: false,
            time: '',
            mixers: [],
            mixer_ids: [],
            mix_ids: [],
            account_module_mix_ids: [],
            mixes: [],
            ordersCount: 0,
            orderGroups: null,
        }
    },
    created() {
        this.getMixes()
        this.getSchedules()
        this.getOrdersGroups()
    },
    computed: {
        ...mapState(useOrderGroupsStore, {
            getDate: 'getDate',
            filters: 'getFilters',
        }),
        ...mapState(useCommonStore, {
            goods: 'getGoods',
            companies: 'getCompanies'
        }),
    },
    methods: {
        getMixes() {
            httpService().post(this.api.dispatch.listByAccount, {
                account_modules_id: this.$route.params.id,
                module_key: this.$route.meta.moduleKey,
            }).then((response) => {
                const {data} = response.data
                this.mixes = data
            })
        },
        getSchedules() {
            this.loading = true
            httpService().post(this.api.dispatch.schedules.getPosts, {
                account_modules_id: this.$route.params.id,
                module_key: this.$route.meta.moduleKey,
                date: this.filters['filter[date]']
            }).then((response) => {
                let data = response.data;

                if (data.success) {
                    this.mixers = response.data.posts
                    this.mixer_ids = response.data.mixer_ids
                    this.mix_ids = response.data.mix_ids
                    this.time = response.data.time
                    this.account_module_mix_ids = response.data.account_module_mix_ids
                }
                this.loading = false
            })
        },
        updateCountOrders(orders) {
            this.values = orders
            this.ordersCount = orders.length ?? 0
        },
        changeDate(date) {
            this.filters['filter[date]'] = moment(date).format('YYYY-MM-DD')
            this.getSchedules()
        },
        changeActiveTab(tab) {
            this.activeTab = tab
        },
        openCreateMixOrderDrawer() {
            this.createMixOrderDrawer = true
        },
        closeMixOrderDrawer() {
            this.createMixOrderDrawer = false
        },
        filterValues() {
            this.filtered = this.values

            if (this.search) this.filtered = this.filtered.filter(data => !this.search || data.doc.toLowerCase().includes(this.search.toLowerCase()))

            this.valueLength = this.filtered.length
            return this.filtered
        },
        getOrdersGroups() {
            httpService().post(this.api.dispatch.orderGroups.getOrderGroups, {
                account_modules_id: this.$route.params.id,
                module_key: this.$route.meta.moduleKey,
                params: this.filters
            }).then((response) => {
                let data = response.data

                if (data.success)
                this.orderGroups = data.values
                this.loading = false
            })
        },
    }
}
</script>

<style scoped>

</style>
