<template>
    <div class="change-status">
        <div class="round-warning-2">
            <img :src="require('@/assets/warning-2.svg')"/>
        </div>
        <div class="change-status__header__title neutral700">Вы действительно хотите сменить статус отгрузки?</div>

        <table class="mt-15">
            <tr>
                <td>Отгрузка</td>
                <td>#{{order.id}}</td>
            </tr>
            <tr>
                <td>Новый статус</td>
                <td>{{getNextStatus.name}}</td>
            </tr>
            <tr>
                <td>Время смены статуса</td>
                <td>
                    <div class="addDeliveryFormItem addDeliveryFormTotal">
                        <div>
                            <el-tooltip effect="dark" content="Время загрузки" placement="top">
                                <div class="addDeliveryFormItemIcon">
                                    <svg width="17" height="16" viewBox="0 0 17 16" fill="none"
                                         xmlns="http://www.w3.org/2000/svg">
                                        <path
                                            d="M14.2503 1.50443C13.9631 1.50443 13.6876 1.61853 13.4845 1.82162C13.2814 2.02472 13.1673 2.30018 13.1673 2.5874V10.8071L4.18955 1.81849C3.98563 1.61456 3.70904 1.5 3.42065 1.5C3.13225 1.5 2.85567 1.61456 2.65174 1.81849C2.44782 2.02242 2.33325 2.299 2.33325 2.5874C2.33325 2.87579 2.44782 3.15237 2.65174 3.3563L11.6403 12.3341H3.42065C3.13343 12.3341 2.85797 12.4482 2.65488 12.6513C2.45178 12.8544 2.33768 13.1298 2.33768 13.417C2.33768 13.7043 2.45178 13.9797 2.65488 14.1828C2.85797 14.3859 3.13343 14.5 3.42065 14.5H14.2503C14.3918 14.4983 14.5316 14.4689 14.6618 14.4134C14.9264 14.3035 15.1367 14.0932 15.2466 13.8286C15.3021 13.6984 15.3315 13.5586 15.3333 13.417V2.5874C15.3333 2.30018 15.2192 2.02472 15.0161 1.82162C14.813 1.61853 14.5375 1.50443 14.2503 1.50443Z"
                                            fill="#49515F"/>
                                    </svg>
                                </div>
                            </el-tooltip>
                        </div>
                        <el-input
                            v-model="time"
                            placeholder="00:00"
                            type="time"
                        />
                    </div>
                </td>
            </tr>
        </table>

        <div class="change-status__footer">
            <el-button
                class="ci-btn ci-btn_blue"
                :disabled="loading || loadingButton"
                :loading="loading || loadingButton"
                @click="changeStatus()"
            >
                Подтвердить
            </el-button>
        </div>
    </div>
</template>

<script>
import moment from "moment";
import {httpService} from '@/services/http.service';
import mixOrderInfo from "@/mixins/mixOrderInfo";
import {useCommonStore} from "@/store/common";
import {mapState} from "pinia";
export default {
    name: 'StatusChangeModal',
    inject: ['api'],
    mixins: [mixOrderInfo],
    props: ['order', 'loading'],
    data() {
        return {
            time: moment.tz(this.order.timezone).format('HH:mm'),
            statusFlag: 0,
            loadingButton: false
        }
    },
    created() {

    },
    methods: {
        changeStatus() {
            this.loadingButton = true
            if (this.order.status !== 'done') {
                this.$emit('loadingAction', true)
                //TODO находится в диспатче, если создавать для диспатча свой смену статуса отгрузки
                httpService().post(this.api.mix.changeOrderStatus, {
                    account_module_reserve_id: this.order.mix_id,
                    account_modules_id: this.$route.params.id,
                    module_key: this.$route.meta.moduleKey,
                    time: this.time,
                    status: this.getNextStatus.key,
                    order: this.order
                }).then((response) => {
                    let answer = response.data

                    if (answer.success) {
                        this.$emit('loadingAction', false)
                        this.$message({
                            message: 'Статус обновлен',
                            showClose: true,
                            type: 'success'
                        })
                        this.$emit('getOrderInfo')
                        window.setTimeout(() => {
                            this.$emit('get')
                        }, 150)
                    }

                    if(!answer.success) {
                        this.$message({
                            message: answer.message,
                            showClose: true,
                            type: 'error'
                        })
                    }
                }).catch((error) => {
                    this.$message({
                        message: error.response.data.message ,
                        showClose: true,
                        type: 'error'
                    })

                    this.loadingButton = false
                }).finally(() => {
                    this.$emit('loadingAction', false)
                    this.$emit('close')

                    this.loadingButton = false
                })
            }
        },

    },
    computed: {
        ...mapState(useCommonStore, {
            appSettings: 'getAppSettings',
        }),
        getNextStatus() {
            let status = '';
            this.statuses.forEach((element) => {
                if (this.order.type === 'take-away' && element.key === 'done' && this.order.status === 'loading') {
                    status = element;
                }

                if (element.key === this.order.status) {
                    this.statusFlag = 1;
                    return;
                }

                // проверяем включена ли настройка Заливки, если нет, пропускаем этот статус
                if (element.key === 'pouring' && this.appSettings.isPouringAvailable === '0') {
                    return;
                }

                if (this.statusFlag === 1 ) {
                    status = element;
                    this.statusFlag = 0;
                }
            })
            return status;
        },
    },
    watch: {}
}
</script>

<style scoped lang="scss">
.change-status {
    tr td:nth-child(odd) {
        min-width: 200px;
    }

    tr td:nth-child(even) {
        color: #22252c;
    }

    input {
        border-radius: 5px;
        border: 0;
        background-color: #F7F7F8;
        padding: 3px;
        cursor: pointer;
    }

    .round-warning-2 {
        background: linear-gradient(45deg, #F7DE97 0%, rgba(247, 222, 151, 0) 100%);
        width: 48px;
        height: 48px;
        display: flex;
        border-radius: 100px;
        margin-bottom: 25px;

        img {
            margin: auto;
            width: 24px;
        }
    }

    &__header__title {
        font-size: 18px;
        font-style: normal;
        font-weight: 700;
        line-height: 24px;
    }

    &__footer {
        justify-content: flex-end;
        margin-top: 25px;
        display: flex;
    }
}
</style>
