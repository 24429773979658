<template>
    <el-form-item v-if="formItem" label="Спецификация" v-bind:class="{ 'is-required': required }">
        <div class="inputModal"
             :class="[
                     {disabled: disabled},
                     {pointer: !disabled},
                     {empty: !itemId},
                 ]">
            <div class="d-flex">
                <div class="inputModalWrapper" @click="openSelectGoodDialog">
                    {{ itemName ?? 'Выберите спецификацию' }}
                </div>
                <div class="inputModalClear" v-if="itemId" @click="clearItemId()">
                    <i class="el-icon el-input__icon el-input__clear">
                        <svg xmlns="http://www.w3.org/2000/svg"
                             viewBox="0 0 1024 1024">
                            <path
                                d="m466.752 512-90.496-90.496a32 32 0 0 1 45.248-45.248L512 466.752l90.496-90.496a32 32 0 1 1 45.248 45.248L557.248 512l90.496 90.496a32 32 0 1 1-45.248 45.248L512 557.248l-90.496 90.496a32 32 0 0 1-45.248-45.248z"></path>
                            <path
                                d="M512 896a384 384 0 1 0 0-768 384 384 0 0 0 0 768m0 64a448 448 0 1 1 0-896 448 448 0 0 1 0 896"></path>
                        </svg>
                    </i>
                </div>
            </div>
        </div>
    </el-form-item>
    <template v-else>
        <div class="inputModal"
             :class="[
                     {disabled: disabled},
                     {pointer: !disabled},
                     {empty: !itemId},
                 ]">
            <div class="d-flex">
                <div class="inputModalWrapper" @click="openSelectGoodDialog">
                    {{ itemName ?? 'Выберите спецификацию' }}
                </div>
                <div class="inputModalClear" v-if="itemId" @click="clearItemId()">
                    <i class="el-icon el-input__icon el-input__clear">
                        <svg xmlns="http://www.w3.org/2000/svg"
                             viewBox="0 0 1024 1024">
                            <path
                                d="m466.752 512-90.496-90.496a32 32 0 0 1 45.248-45.248L512 466.752l90.496-90.496a32 32 0 1 1 45.248 45.248L557.248 512l90.496 90.496a32 32 0 1 1-45.248 45.248L512 557.248l-90.496 90.496a32 32 0 0 1-45.248-45.248z"></path>
                            <path
                                d="M512 896a384 384 0 1 0 0-768 384 384 0 0 0 0 768m0 64a448 448 0 1 1 0-896 448 448 0 0 1 0 896"></path>
                        </svg>
                    </i>
                </div>
            </div>
        </div>
    </template>

    <el-dialog
        v-model="selectGoodDialog"
        title="Выберите спецификацию"
        :width="dialogSize"
        :destroy-on-close="true"
    >
        <TableHeadBlock
            :new="true"
            v-model="search"
            :loading="loading"
            :disable-filter="true"
            :disable-create-button="true"
            @moduleGet="getSpecGoods()"
            create-button-label="Создать спецификацию"
        />

        <EmptyState
            :values="displayData"
            :search="search"
            :loading="loading"
            list-types="спецификаций"
            action-text="Создайте спецификацию в модуле лаборатории или справочнике"
        />

        <el-table
            v-loading="loading"
            v-if="displayData.length > 0"
            :data="displayData"
            @current-change="handleCurrentChange"
            class="w-100 mt-15">
            <el-table-column
                prop="id"
                label="#"
                width="90"
                class-name="pointer"
            >
                <template v-slot="scope">
                                    <span class="textSM textMedium"
                                          :class="[{neutral200: scope.row.id !== itemId}, {primary600: scope.row.id === itemId}]"
                                    >#{{ formatNumber(scope.row.id) }}</span>
                </template>
            </el-table-column>
            <el-table-column
                prop="name"
                label="Спецификация"
                class-name="pointer"
            >
                <template v-slot="scope">
                    <div class="text-none textMedium primary600">
                        <span v-html="highlightMatch(scope.row.name, search)"></span>
                    </div>
                </template>
            </el-table-column>
            <el-table-column
                prop="name"
                label="Рецепты"
                class-name="pointer"
            >
                <template v-slot="scope">
                    <div v-if="scope.row.mixGoods.length">
                        <div class="text-none textRegular neutral900 d-block"
                           v-for="(good, index) in scope.row.mixGoods" :key="index"
                           :class="{ 'mb-5': index < scope.row.mixGoods.length - 1 }">
                                    <span><span v-html="highlightMatch(good.name, search)"></span>
                                        <span class="textRegular neutral300 ml-5"
                                              v-if="good.integration_unit_id">({{
                                                getIntegrationLabel(good.integration_unit_id)
                                            }})</span></span>
                        </div>
                    </div>
                    <div v-else>
                        -
                    </div>
                </template>
            </el-table-column>
        </el-table>


        <TablePagination
            v-model="pageSize"
            :total="valueLength"
            @action="setPage"
        />
    </el-dialog>
</template>

<script>
import tableMixin from "@/mixins/tableMixin";
import mobileCheckMixin from "@/mixins/mobileCheckMixin";
import {httpService} from "@/services/http.service";
import TablePagination from "@/views/components/Table/TablePagination.vue";
import TableHeadBlock from "@/views/components/Table/TableHeadBlock.vue";
import EmptyState from "@/views/components/EmptyState.vue";
import {mapState} from "pinia";
import {useCommonStore} from "@/store/common";
import functionsMixin from "@/mixins/functionsMixin";

// модалка выбора спецификации для конкретной продукции

export default {
    name: "FormSelectSpecGoodByProduct",
    components: {EmptyState, TableHeadBlock, TablePagination},
    mixins: [
        tableMixin, // подключаем миксин для работы с таблицей
        mobileCheckMixin, // подключаем миксин для размеров модалок на основе ширины экрана
        functionsMixin, // основной миксин, здесь он нужен для тысячных пробелов в id
    ],
    computed: {
        ...mapState(useCommonStore, {
            integrations: 'getIntegrations', // добавляем массив интеграций из pinia для вывода названий
        })
    },
    inject: [
        'api' // инъекция, без которой не работают обращения в бек
    ],
    props: [
        'modelValue', // модель с коротой работаем выше
        'goodId', // id продукта к которому ищем спецификацию
        'disabled', // отключено ли поле
        'goodName', // название продукта из базы
        'required', // обязательное ли поле
        'active', // сигнал с формы, что можно делать забор данных,
        'formItem', // делаем элемент формы
    ],
    data() {
        return {
            itemId: this.modelValue, // id выбранного элемента
            itemName: this.goodName, // название выбранного элемента
            selectGoodDialog: false, // параметр для открытия/закрытия модалки
            loading: true
        }
    },
    methods: {
        clearItemId() {
            this.itemId = null
        },
        highlightMatch(text, search) {
            if (!search) return text;
            const regex = new RegExp(search, 'gi');
            return text.replace(regex, (match) => `<mark>${match}</mark>`);
        },
        getIntegrationLabel(integrationUnitId) {
            let integrationUnit = this.integrations.find(integration => integration.id === integrationUnitId)
            let label = null

            if (integrationUnit) {
                let key = integrationUnit.integration.key

                label = key

                if (key === 'SmartMix' || key === 'MEKA' || key === 'PromServ' || key === 'Elkon') {
                    label = integrationUnit.product + ': ' + integrationUnit.account_module.name
                }
            }

            return label
        },
        openSelectGoodDialog() {
            if (!this.disabled) {
                this.search = null
                if (!this.values.length && this.goodId) {
                    console.log('openSelectGoodDialog')
                    this.getSpecGoods()
                }
                this.selectGoodDialog = true
            }
        },
        getSpecGoods() {
            this.loading = true

            let url = this.api.dispatch.orderGroups.getSpecGoods
            if (this.$route.meta.moduleKey === 'lab') url = this.api.lab.getSpecGoodsByGood

            httpService().post(url, {
                account_modules_id: this.$route.params.id,
                account_module_reserve_id: this.$route.params.id,
                module_key: this.$route.meta.moduleKey,
                good_id: this.goodId
            }).then((response) => {
                let data = response.data

                if (data.success) {
                    this.values = data.values
                    this.loading = false
                }
            }).catch((error) => {
                this.$message({
                    message: error.response.data.message,
                    showClose: true,
                    type: 'error'
                })
                this.loading = false
            })
        },
        filterValues() {
            this.filtered = this.values

            if (this.search) {
                this.filtered = this.values.filter(data => {
                    const searchCondition = (data.name?.toLowerCase() ?? '').includes(this.search.toLowerCase());
                    const specGoodsCondition = data.specGoods?.some(specGood => (specGood.name?.toLowerCase() ?? '').includes(this.search.toLowerCase()));
                    const mixGoodsCondition = data.mixGoods?.some(mixGood => (mixGood.name?.toLowerCase() ?? '').includes(this.search.toLowerCase()));
                    return !this.search || searchCondition || specGoodsCondition || mixGoodsCondition;
                });
            }
            this.valueLength = this.filtered.length
            return this.filtered.slice(this.pageSize * this.page - this.pageSize, this.pageSize * this.page)
        },
        handleCurrentChange(val) {
            this.itemId = val.id
            this.itemName = val.name
            this.$message({
                message: 'Спецификация добавлена',
                showClose: true,
                type: 'success'
            })
            this.selectGoodDialog = false
        }
    },
    watch: {
        modelValue: {
            immediate: true,
            handler: function () {
                this.itemId = this.modelValue
            }
        },
        itemId: {
            immediate: true,
            handler: function () {
                this.$emit('update:modelValue', this.itemId)
            }
        },
        active: {
            immediate: true,
            handler: function () {
                if (this.active && this.goodId) {
                    console.log('active')
                    this.getSpecGoods()
                }
            }
        },
        goodId: {
            immediate: false,
            handler: function () {
                if (this.active && this.goodId) {
                    console.log('goodId')
                    this.getSpecGoods()
                }
            }
        }
    }

}
</script>

<style scoped>

</style>
