<template>
    <div class="widget-card widget-contracts">
        <div class="widget-card-title">{{ title }}
            <el-button class="widget-reload" :loading="loadingButton" @click="onUpdate" :icon="icons.refresh"
                       circle></el-button>
            <span class="widget-card-title_subTitle">Контракты и контрагенты</span>
        </div>
        <div class="widget-card-body" v-loading="loading">
            <div v-if="data.length > 0">
                <div class="item" v-for="(value, index) in data" :key="index">
                    <el-row v-if="!mobile">
                        <el-col :span="11">
                            <span class="item-top">{{ getData(value.company, 'company') }}</span>
                            <span class="item-bottom">{{ value.name }}</span>
                        </el-col>
                        <el-col :span="10">
                            <el-steps :active="value.done" class="progress" finish-status="success">
                                <el-step></el-step>
                                <el-step></el-step>
                                <el-step></el-step>
                                <el-step></el-step>
                                <el-step></el-step>
                                <el-step></el-step>
                                <el-step></el-step>
                                <el-step></el-step>
                                <el-step></el-step>
                                <el-step></el-step>
                            </el-steps>
                        </el-col>
                        <el-col :span="3">
                        <span v-if="value.value > 0" class="item-right colored">+ <number
                            ref="count"
                            :to="value.value"
                            :format="formatNumber"
                            :duration="1"
                            :delay="0"
                            easing="Power4.easeOut"/> {{ getUnitFromIds(value.goods) }}</span>
                            <span v-if="value.value === 0" class="item-right not-colored">{{
                                    value.value
                                }} {{ getUnitFromIds(value.goods) }}</span>
                        </el-col>
                    </el-row>
                    <el-row v-if="mobile">
                        <el-col :span="24">
                            <el-row class="mb-5">
                                <el-col :span="16">
                                    <span class="item-top">{{ getData(value.company, 'company') }}</span>
                                </el-col>
                                <el-col :span="8">
                                <span v-if="value.value > 0" class="item-right colored">+ <number
                                    ref="count"
                                    :to="value.value"
                                    :format="formatNumber"
                                    :duration="1"
                                    :delay="0"
                                    easing="Power4.easeOut"/> {{ getUnitFromIds(value.goods) }}</span>
                                    <span v-if="value.value === 0" class="item-right not-colored">{{
                                            value.value
                                        }} {{ getUnitFromIds(value.goods) }}</span>
                                </el-col>
                            </el-row>
                            <span class="item-bottom">{{ value.name }}</span>
                        </el-col>
                        <el-col :span="24">
                            <el-steps :active="value.done" class="progress" finish-status="success">
                                <el-step></el-step>
                                <el-step></el-step>
                                <el-step></el-step>
                                <el-step></el-step>
                                <el-step></el-step>
                                <el-step></el-step>
                                <el-step></el-step>
                                <el-step></el-step>
                                <el-step></el-step>
                                <el-step></el-step>
                            </el-steps>
                        </el-col>
                    </el-row>
                </div>
            </div>
            <div v-if="data.length === 0">
                <div class="no-data-block">
                    <NoDataIcon/>
                    <p>
                        В систему еще не добавлены контракты<br>Информация актуальна с 00:00 до {{ time }}
                    </p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import NoDataIcon from '../../components/NoDataIcon'
import functionsMixin from '@/mixins/functionsMixin'
import getUnitFromGoodsMixin from '@/mixins/getUnitFromGoodsMixin'
import { httpService } from '@/services/http.service'
import iconsMixin from "@/mixins/iconsMixin";

export default {
    props: [
        'id',
    ],
    inject: [
        'api'
    ],
    components: {
        NoDataIcon
    },
    mixins: [
        functionsMixin, getUnitFromGoodsMixin, iconsMixin
    ],
    data() {
        return {
            data: [],
            loadingButton: true,
            loading: true,
            active: 3,
            windowWidth: null,
            mobile: false,
            time: null,
            title: 'Актуальные контракты'
        }
    },
    created() {
        this.loadingButton = true
        this.widgetGet(this.id)
        window.setInterval(() => {
            this.loadingButton = false
        }, 800)
        this.counterInterval = setInterval(
            function () {
                this.updateWidget()
            }.bind(this), Math.floor(Math.random() * (10000 - 8000 + 1)) + 8000)
        this.$nextTick(function () {
            window.addEventListener('resize', this.getWindowWidth)

            //Init
            this.getWindowWidth()
        })
    },
    beforeUnmount: function () {
        clearInterval(this.counterInterval)
    },
    methods: {
        widgetGet(id) { // метод получения данных виджета с api
            // обращаемся в api, передаем id виджета
            httpService().post(this.api.widget.getOne, {
                id: id
            }).then((response) => {
                    // получаем массив с api
                    let data = response.data
                    if (data.success)
                        // кладем массив в виджет
                        this.data = data.data.sort((a, b) => a.date < b.date ? 1 : -1)
                    this.time = data.time
                    this.title = data.widgetName
                    this.loading = false
                }
            )
        },
        onUpdate() {
            this.loadingButton = true
            this.updateWidget()
            window.setInterval(() => {
                this.loadingButton = false
            }, 800)
        },
        updateWidget() // метод обновления виджета
        {
            this.widgetGet(this.id)
        },
        // eslint-disable-next-line no-unused-vars
        getWindowWidth(event) {
            this.windowWidth = document.documentElement.clientWidth
            if (this.windowWidth < 510) {
                this.mobile = true
            } else {
                this.mobile = false
            }
        },
    },
}
</script>

<style scoped>
</style>
