<template>
    <div>
        <el-table
            :data="displayData"
            class="w-100 mt-15">
            <el-table-column
                prop="name"
                label=""
            >
                <template v-slot="scope">
                    <div class="textRegular neutral900">{{ scope.row.name }}</div>
                </template>
            </el-table-column>
            <el-table-column
                prop="integration"
                label=""
                width="300px"
            >
                <template v-slot="scope">
                    <template v-if="scope.row.integration_unit_id">
                        <IntegrationLogo
                            :integration-unit-id="
                                        scope.row.integration_unit_id
                                    "
                        />
                    </template>
                    <template v-else>
                                <span class="textRegular textSM neutral200"
                                >-</span
                                >
                    </template>
                </template>
            </el-table-column>
        </el-table>

        <TablePagination
            v-model="pageSize"
            :total="valueLength"
            @action="setPage"
        />
    </div>
</template>

<script>
import tableMixin from "@/mixins/tableMixin";
import TablePagination from "@/views/components/Table/TablePagination.vue";
import IntegrationLogo from "@/views/components/Blocks/IntegrationLogo.vue";

export default {
    name: "LabSpecGoods",
    components: {IntegrationLogo, TablePagination},
    mixins: [tableMixin],
    props: ['goods'],
    created() {
        this.values = this.goods
    },
    methods: {
        filterValues() {
            this.filtered = this.values

            this.valueLength = this.filtered.length
            return this.filtered.slice(this.pageSize * this.page - this.pageSize, this.pageSize * this.page)
        }
    },
    watch: {
        'goods': {
            handler() {
                this.values = this.goods
            },
            deep: true
        }
    }
}
</script>

<style scoped>

</style>
