<template>
    <div class="mt-15">
        <MixOrderDetailsGroupShow v-if="!edit && !print" :orderGroup="orderGroup" :mix-order="mixOrder" :mixOrderId="mixOrderId"
        @loadingAction="loadingAction" :loading="loading" @get="getOrders" @close="$emit('close')" @edit="() => edit = true" @print="() => print = true"/>
        <MixOrderDetailsGroupEdit v-if="edit" :orderGroup="orderGroup" :mix-order="mixOrder" :mixOrderId="mixOrderId"
                             :loading="loading" @get="getOrders" @close="$emit('close')" @edit="() => edit = false"/>
        <MixOrderDetailsGroupPrint v-if="!edit && print" :orderGroup="orderGroup" :mix-order="mixOrder" :mixOrderId="mixOrderId"
                                   :loading="loading" @get="$emit('get')" @close="$emit('close')" @edit="() => edit = true" @print="() => print = false"/>
    </div>
</template>

<script>
import MixOrderDetailsGroupShow from "@/views/components/MixOrderGroup/components/MixOrderDetailsGroupShow.vue";
import {httpService} from "@/services/http.service";
import MixOrderDetailsGroupEdit from "@/views/components/MixOrderGroup/components/MixOrderDetailsGroupEdit.vue";
import MixOrderDetailsGroupPrint from "@/views/components/MixOrderGroup/components/MixOrderDetailsGroupPrint.vue";

export default {
    name: "MixOrderDetailsGroup",
    components: {MixOrderDetailsGroupPrint, MixOrderDetailsGroupEdit, MixOrderDetailsGroupShow},
    props: ['orderGroup', 'mixOrderId'],
    inject: ['api'],
    data() {
        return {
            edit: false,
            loading: true,
            mixOrder: null,
            print: false
        }
    },
    beforeUnmount: function () {
        window.Echo.leave(`laravel_database_ChannelMixOrderDeletedNew`);
        window.Echo.leave(`laravel_database_ChannelMixOrderUpdateNew`);
    },
    created() {
        this.mixOrder = this.orderGroup.orders.find(el => el.id === this.mixOrderId)
        this.getOrderInfo()

        if (this.mixOrder) {
            window.Echo.channel('laravel_database_ChannelMixOrderDeletedNew')
                .listen(`.MixOrderDeleted.${this.mixOrder.id}`, () => {
                    this.$emit('close')
                })

            window.Echo.channel('laravel_database_ChannelMixOrderUpdateNew')
                .listen(`.MixOrderUpdate.${this.mixOrder.id}`, () => {
                    this.getOrderInfo()
                });
        }
    },
    methods: {
        getOrders() {
            this.getOrderInfo()
            this.$emit('get')
        },
        getOrderInfo() {
            this.loading = true
            httpService().post(this.api.mixOrder.getOrderInfoSimple + '/' + this.mixOrderId, {
                account_modules_id: this.$route.params.id,
                $account_module_reserve_id: this.$route.params.id,
                module_key: this.$route.meta.moduleKey,
            }).then((response) => {

                let data = response.data

                if (data.success) {

                    if (
                        this.mixOrder && this.mixOrder.total && this.mixOrder.total != data.order.total ||
                        this.mixOrder && this.mixOrder.start_at && this.mixOrder.start_at != data.order.start_at ||
                        this.mixOrder && this.mixOrder.vehicle_id && this.mixOrder.vehicle_id != data.order.vehicle_id ||
                        this.mixOrder && this.mixOrder.status != data.order.status
                    )
                    {
                        this.$message({
                            message: 'Данные по отгрузке были актуализированы',
                            showClose: true,
                            type: 'info'
                        })
                        this.$emit('get')
                    }

                    this.mixOrder = data.order
                    this.loading = false


                }
            }).catch(() => {
                this.loading = false
                this.$message({
                    message: 'Это была неподтвержденная отгрузка. Алгоритм подобрал лучший вариант',
                    showClose: true,
                    type: 'warning'
                })
                this.$message({
                    message: 'Загружаем данные... Повторите снова!',
                    showClose: true,
                    type: 'success'
                })
                this.$emit('get')
                this.$emit('close')
            })
        },
        loadingAction(loading) {
            this.loading = loading
        }
    }
}
</script>

<style scoped>

</style>
