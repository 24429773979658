<template>
    <div class="w-100">
        <div class="mixTimeline"
             v-if="currentOrder.type === 'take-away'"
             :style="(currentOrder.type === 'take-away' ? 'width:200px' : '' )"
        >
            <MixPageOrderTimelineItemNew
                v-for="(status, index) in statuses.slice(0,2)" :key="index"
                :status="status.key"
                :name="status.name"
                :completed="completed(status.id)"
                :time="time(status.key)"
                :current="current(status.key)"
                :order="currentOrder"
            />
        </div>

        <div class="mixTimeline" v-else>
            <MixPageOrderTimelineItemNew
                v-for="(status, index) in statuses" :key="index"
                :status="status.key"
                :name="status.name"
                :completed="completed(status.id)"
                :time="time(status.key)"
                :current="current(status.key)"
                :order="currentOrder"
            />
        </div>
    </div>
</template>

<script>
import MixPageOrderTimelineItemNew from '@/views/components/MixPage/OrdersGroup/MixPageOrderTimelineItemNew.vue'
import functionsMixin from "@/mixins/functionsMixin";
import moment from "moment";

export default {
    name: 'MixPageOrderTimelineNew',
    props: ['order'],
    components: {MixPageOrderTimelineItemNew},
    mixins: [functionsMixin],
    data() {
        return {
            statuses: [
                {
                    id: 0,
                    key: 'loading',
                    name: 'Загрузка'
                },
                {
                    id: 10,
                    key: 'delivery',
                    name: 'Доставка'
                },
                {
                    id: 20,
                    key: 'object',
                    name: 'На объекте'
                },
                {
                    id: 40,
                    key: 'return',
                    name: 'Возвращается'
                },
                {
                    id: 50,
                    key: 'done',
                    name: 'Конец'
                }
            ],
            filteredStatuses: this.statuses,
            currentId: null,
            currentOrder: this.order,
        }
    },
    methods: {
        completed(id) {
            if (this.currentOrder.status !== 'loading') {
                let status = this.currentOrder.status === 'pouring' ? 'object' : this.currentOrder.status

                let statusId = this.statuses.find(el => el.key === status)?.id ?? 0
                if (this.currentOrder.status === 'wait') {
                    statusId = -10
                }

                return id < statusId
            } else {
                return false
            }
        },
        current(key) {
            let status = this.currentOrder.status === 'pouring' ? 'object' : this.currentOrder.status

            return key === status
        },
        time(status) {
            let time = null

            switch (status) {
                case 'loading':
                    time = this.currentOrder.load_at ?? null
                    break
                case 'delivery':
                    time = this.currentOrder.arrive_at ?? null
                    break
                case 'object':
                    time = this.currentOrder.unload_at ?? null
                    break
                case 'return':
                    time = this.currentOrder.return_at ?? null
                    break
            }

            return time ? moment.parseZone(time).format('HH:mm') : null
        }
    },
    watch: {
        'order': {
            handler: function () {
                this.currentOrder = this.order
            },
            deep: true,
            immediate: true
        },
    }
}
</script>

<style scoped>

</style>
