<template>
    <el-form class="dispatch-order-groups-create-form" label-position="left" label-width="185px">

        <el-row :gutter="30">
            <el-col :sm="windowWidth < 1600 ? 12 : 8">

                <el-row :gutter="30">
                    <el-col :sm="24">
                        <FormDateTimePicker
                            v-model="form.date_shipment"
                            label="Дата доставки"
                            size="default"
                            @changeAction="resetOrder()"
                        />
                    </el-col>
                </el-row>

                <FormSelect
                    v-if="!form.individual"
                    v-model="form.buyer_company_id"
                    label="Контрагент"
                    placeholder="Выберите контрагента"
                    new-item="company"
                    :new-item-data="form.buyer_company_id"
                    size="large"
                />

                <FormSelect
                    v-if="false"
                    v-model="form.good_id"
                    :disabled="disableForm"
                    label="Продукция"
                    placeholder="Выберите продукцию"
                    new-item="good"
                    :invoice-id="form.invoice_id"
                    :contract-id="form.contract_id"
                    @updatePrice="updatePrice"
                />

                <FormSelectDispatchGoods
                    v-model="form.good_id"
                    :disabled="disableForm || !form.buyer_company_id"
                    :good="form.good"
                    :goods="form.companyGoods"
                    :invoice-id="form.invoice_id"
                    :contract-id="form.contract_id"
                    @updatePrice="updatePrice"
                />

                <FormNumber
                    v-model="form.total"
                    label="Объем продукции"
                    placeholder="30"
                    precision="2"
                    @focusoutAction="resetOrder()"
                />
            </el-col>

            <el-col :sm="windowWidth < 1600 ? 12 : 8">
                <FormSelect
                    v-model="form.contract_id"
                    :disabled="!form.buyer_company_id || disableForm"
                    label="Договор поставки"
                    :placeholder="!form.buyer_company_id ? 'Выберите контрагента' : 'Выберите договор'"
                    new-item="document"
                    :new-item-data="form.contract_id"
                    :carrier-company="form.buyer_company_id"
                    :items="[]"
                    size="large"
                    document-type="contract"
                    :required="form.contract_required == 1"
                />

                <FormSelect
                    v-model="form.invoice_id"
                    :disabled="!form.buyer_company_id || disableForm"
                    label="Счет"
                    :placeholder="!form.buyer_company_id ? 'Выберите контрагента' : 'Выберите счет'"
                    new-item="document"
                    :new-item-data="form.invoice_id"
                    :carrier-company="form.buyer_company_id"
                    :items="[]"
                    size="large"
                    document-type="invoice"
                />

                <div class="el-form-item" v-if="payment_type === 'contract' && form.contract_id">
                    <div class="el-form-item__label" style="width: 185px;">Остаток средств по договору</div>
                    <div class="el-form-item__content textLG primary500" style="height: 38px;">
                        {{ selectedContract.length && selectedContract.total ? selectedContract.total : 0 }} ₽
                    </div>
                </div>

                <div class="el-form-item" v-if="payment_type === 'invoice' && form.invoice_id">
                    <div class="el-form-item__label" style="width: 185px;">Статус оплаты</div>
                    <div class="el-form-item__content textLG text-500" style="height: 38px;"
                         :class="[
                             {'danger300': !selectedInvoice.payment_status},
                             {'danger300': selectedInvoice.payment_status === 'not-paid'},
                             {'danger300': selectedInvoice.payment_status === 'failed'},
                             {'primary500': selectedInvoice.payment_status === 'partially-paid'},
                             {'primary500': selectedInvoice.payment_status === 'paid'},
                        ]">
                        {{ checkPaymentStatus(selectedInvoice.payment_status) }}
                    </div>
                </div>
            </el-col>
        </el-row>

        <el-tabs v-model="activeTab" class="mt-15">
            <el-tab-pane label="Доставка" name="delivery">
                <el-row :gutter="30">
                    <el-col :sm="windowWidth < 1600 ? 12 : 8">
                        <el-form-item label="Тип доставки">
                            <el-radio-group v-model="form.delivery_type" @change="resetOrder()">
                                <el-radio label="delivery">Доставка</el-radio>
                                <el-radio label="take-away">Самовывоз</el-radio>
                            </el-radio-group>
                        </el-form-item>

                        <FormSelect
                            v-if="form.delivery_type === 'delivery'"
                            v-model="form.delivery_address_id"
                            :additional-info="selectedBuyerName"
                            :disabled="!form.buyer_company_id || disableForm"
                            label-key="address"
                            label="Объект"
                            placeholder="Выберите объект"
                            new-item="companyAddress"
                            :new-item-data="form.buyer_company_id"
                            @changeValue="resetOrder()"
                            :items="address"
                        />

                        <template v-if="form.delivery_type === 'delivery'">
                            <FormSelect
                                v-model="form.company_manager_id"
                                :disabled="!form.buyer_company_id || disableForm"
                                label="Представитель контрагента"
                                placeholder="Укажите ФИО представителя"
                                new-item="companyManager"
                                :new-item-data="form.buyer_company_id"
                                :items="companyManagers"
                            />

                            <FormTextarea
                                v-model="form.comment_driver"
                                label="Комментарий водителю"
                                placeholder="Напишите комментарий"
                                :rows="4"
                            />

                            <el-form-item label="Наличие гидролотка">
                                <template #label>
                                    <el-link class="textXS neutral400" href="https://www.gidrolotok.ru/"
                                             target="_blank">Наличие гидролотка
                                    </el-link>
                                </template>
                                <el-switch size="large" v-model="form.gidrolotok"/>
                            </el-form-item>
                        </template>

                    </el-col>
                    <el-col :sm="windowWidth < 1600 ? 12 : 8">
                        <el-form-item
                            v-if="form.delivery_type === 'delivery'"
                            label="Время первой доставки"
                        >
                            <el-input
                                v-model="form.first_order_time_delivery"
                                placeholder="00:00"
                                type="time"
                            />
                        </el-form-item>
                        <el-form-item
                            v-if="form.delivery_type === 'delivery'"
                            label="Интервал между доставками"
                        >
                            <el-radio-group
                                v-model="form.type_delivery"
                            >
                                <el-radio label="withoutInterval">Без интервала</el-radio>
                                <el-radio label="withInterval">С интервалом на разгрузку</el-radio>
                                <el-radio label="withIntervalClient">Интервал от клиента</el-radio>
                            </el-radio-group>
                        </el-form-item>

                        <FormNumber
                            v-if="form.delivery_type === 'delivery'"
                            label="План. время разгрузки"
                            v-model="form.time_unloading"
                            placeholder="Укажите время для планирования"
                            precision="2"
                            step="1"
                        />

                        <FormNumber
                            v-if="form.type_delivery === 'withIntervalClient'"
                            v-model="form.time_interval_client"
                            label="Интервал от клиента (мин)"
                            placeholder="Укажите время"
                            precision="2"
                            step="1"
                        />

                        <FormNumber
                            label="Макс. объём каждого миксера"
                            v-model="form.mixer_max_volume"
                            placeholder="Укажите объем"
                            precision="2"
                            step="1"
                        />
                        <FormNumber
                            v-if="form.delivery_type === 'delivery'"
                            v-model="form.axle"
                            label="Макс. осей миксера"
                            placeholder="Укажите числом, н-р: 3"
                            precision="0"
                            step="1"
                            min="2"
                            max="4"
                        />
                        <el-form-item
                            v-if="form.delivery_type === 'delivery'"
                            label="Распределять остаток"
                        >
                            <el-switch size="large" v-model="form.max_load"/>
                        </el-form-item>
                        <template v-if="form.max_load">
                            <FormNumber
                                v-model="form.max_remainder"
                                label="Максимальный остаток"
                                placeholder="0.5"
                                precision="2"
                            />
                        </template>
                    </el-col>
                </el-row>
            </el-tab-pane>
            <el-tab-pane
                v-if="form.delivery_type === 'delivery'"
                label="Насос"
                name="pump"
                :disabled="!loadDispatchInfo"
            >

                <el-form-item label="Необходимость насоса">
                    <el-switch size="large" v-model="form.pump"/>
                </el-form-item>

                <template v-if="form.pump">
                    <DispatchMixersTable
                        v-model="form.pump_vehicle_id"
                        v-if="!form.pump_vehicle_id"
                        type="pump"
                        :order-create-view="true"
                        :date-order="form.date_shipment"
                    />
                    <template v-else>
                        <el-row :gutter="20">
                            <el-col :sm="4">
                                <el-button type="text" @click="() => form.pump_vehicle_id = null">Выбрать другой насос
                                </el-button>

                                <p class="neutral400 textXS mb-5">Выбран насос</p>
                            </el-col>
                            <el-col :sm="windowWidth < 1600 ? 12 : 8">
                                <el-form-item label="Тип оплаты">
                                    <el-select
                                        label="Тип стоимости"
                                        placeholder="Выберите тип стоимости"
                                        v-model="form.pump_cost_type"
                                    >
                                        <el-option value="by-hour" label="За час"/>
                                        <el-option value="by-delivery" label="За отгрузку"/>
                                    </el-select>
                                </el-form-item>

                                <FormNumber
                                    label="Стоимость насоса (₽, с НДС)"
                                    placeholder="Пр.: 15 000"
                                    v-model="form.pump_price"
                                />
                            </el-col>
                            <el-col :sm="windowWidth < 1600 ? 12 : 8">
                                <FormTextarea
                                    v-model="form.comment_pump_driver"
                                    label="Комментарий водителю насоса"
                                    placeholder="Напишите комментарий"
                                    :rows="4"
                                />
                            </el-col>
                        </el-row>
                    </template>
                </template>
            </el-tab-pane>
            <el-tab-pane label="Стоимость" name="price" :disabled="!loadDispatchInfo">

                <el-row :gutter="30">
                    <el-col :sm="windowWidth < 1600 ? 12 : 8">
                        <FormNumber
                            v-model="form.good_price"
                            label="Стоимость продукции"
                            placeholder="Укажите стоимость за 1 м3"
                            precision="2"
                        />
                    </el-col>
                    <el-col :sm="windowWidth < 1600 ? 12 : 8">
                        <FormSelect
                            v-model="form.payment_method"
                            label="Метод оплаты"
                            placeholder="Выберите метод"
                            :items="paymentMethods"
                        />
                    </el-col>
                    <el-col :sm="windowWidth < 1600 ? 12 : 8">
                        <FormNumber
                            v-if="form.payment_method === 'cash' || form.payment_method === 'transfer'"
                            v-model="form.prepayment"
                            label="Предоплата"
                            placeholder="5000"
                        />
                    </el-col>
                </el-row>

                <hr class="mt-15 mb-15"/>

                <el-row :gutter="30">
                    <el-col :sm="16">

                        <FormSelect
                            v-if="form.delivery_type === 'delivery'"
                            v-model="form.mix_service_tariff_id"
                            label="Тариф доставки бетона"
                            placeholder="Выберите тариф"
                            newItemPath="ServiceTariffs"
                            :new-item-data="form.mix_service_tariff_id"
                            :items="serviceTariffs.filter(el => el.type === 'mix')"
                        />

                        <FormSelect
                            v-if="form.delivery_type === 'delivery'"
                            v-model="form.downtime_service_tariff_id"
                            label="Тариф за простой"
                            placeholder="Выберите тариф"
                            newItemPath="ServiceTariffs"
                            :new-item-data="form.downtime_service_tariff_id"
                            :items="serviceTariffs.filter(el => el.type === 'downtime')"
                        />

                        <FormSelect
                            v-if="form.pump"
                            v-model="form.pump_service_tariff_id"
                            label="Тариф аренды бетононасоса"
                            placeholder="Выберите тариф"
                            newItemPath="ServiceTariffs"
                            :new-item-data="form.pump_service_tariff_id"
                            :items="serviceTariffs.filter(el => el.type === 'pump')"
                        />
                    </el-col>
                </el-row>
            </el-tab-pane>
            <el-tab-pane label="Заводы" name="mixes">
                <el-row :gutter="30">
                    <el-col :sm="windowWidth < 1600 ? 12 : 8">
                        <el-checkbox-group
                            class="inlineCheckbox"
                            v-model="form.mixer_ids"
                            @change="handleCheckedMixersChange"
                        >
                            <div
                                v-for="(mixer, mixerIndex) in mixers"
                                :key="mixerIndex"
                                class="mix-section"
                            >
                                <div class="selectedMixes cutOverflow">
                                    <el-checkbox
                                        :label="mixer.id"
                                    >
                                        {{
                                            mixer.count > 1 ? mixer.module_name + ' - ' + mixer.name : mixer.module_name
                                        }}
                                        {{ mixer.start_time }} -
                                        {{ mixer.end_time }}
                                    </el-checkbox>
                                </div>

                                <div class="d-flex mix-action">
                                    <div class="mix-load" v-show="false">
                                        100 %
                                    </div>
                                </div>
                            </div>
                        </el-checkbox-group>
                    </el-col>
                    <el-col :sm="windowWidth < 1600 ? 12 : 8">
                        <FormTextarea
                            v-model="form.comment_operator"
                            label="Комментарий оператору"
                            placeholder="Напишите комментарий"
                            :rows="4"
                        />
                    </el-col>
                </el-row>
            </el-tab-pane>
            <el-tab-pane
                v-if="form.delivery_type === 'delivery'"
                label="Миксеры"
                name="mixers"
                :disabled="!loadDispatchInfo"
            >
                <el-row :gutter="30">
                    <el-col :sm="30">
                        <el-switch
                            size="large"
                            v-model="form.custom_mixers_enabled"
                            active-text="Только выбранные"
                            inactive-text="Все"
                        />
                    </el-col>
                    <el-col v-if="form.custom_mixers_enabled" :sm="30">
                        <DispatchMixersTable
                            type="mix"
                            :date-order="form.date_shipment"
                            :order-create-view="true"
                            :selectedMixerIds="selectedMixerIds"
                            @selectMixers="selectCustomMixers"
                        />
                    </el-col>
                </el-row>
            </el-tab-pane>
            <el-tab-pane label="Дополнительно" name="other" :disabled="!loadDispatchInfo">
                <el-row :gutter="30">
                    <el-col :sm="windowWidth < 1600 ? 12 : 8">

                        <el-form-item label="Необходимость лаборатории">
                            <el-switch size="large" v-model="form.lab"/>
                        </el-form-item>

                        <FormInput
                            v-model="form.constructive"
                            label="Конструктив"
                            placeholder="Опишите"
                        />

                        <FormSelect
                            v-model="form.spec_good_id"
                            v-if="false"
                            label="Спецификация"
                            placeholder="Выберите спецификацию"
                            new-item="good"
                            :good-ids="[form.good_id]"
                        />

                        <FormSelectSpecGoodByProduct
                            v-model="form.spec_good_id"
                            v-if="!form.lab"
                            :disabled="!form.good_id"
                            :good-id="form.good_id"
                            :active="activeTab === 'other'"
                            :form-item="true"
                        />

                        <FormTextarea
                            v-if="form.lab"
                            v-model="form.comment"
                            label="Комментарий для лаборатории"
                            placeholder="Напишите комментарий"
                            :rows="3"
                        />
                    </el-col>
                    <el-col :sm="windowWidth < 1600 ? 12 : 8">
                        <FormSelect
                            v-model="form.seller_company_id"
                            label="Поставщик"
                            placeholder="Выберите поставщика"
                            new-item="company"
                            :new-item-data="form.seller_company_id"
                            company-type="seller"
                            @changeValue="resetOrder()"
                        />
                        <FormSelect
                            v-model="form.carrier_company_id"
                            label="Перевозчик"
                            placeholder="Выберите перевозчика"
                            new-item="company"
                            :carrier-company="true"
                            company-type="carrier"
                            :new-item-data="form.carrier_company_id"
                        />

                        <FormSelect
                            v-model="form.manager_id"
                            label="Менеджер"
                            placeholder="Выберите"
                            :items="users"
                        />
                    </el-col>
                </el-row>
            </el-tab-pane>
        </el-tabs>
    </el-form>
</template>

<script>
import DispatchMixersTable from "@/views/dispatch/DispatchMixersTable.vue";
import FormTextarea from "@/views/components/Form/FormTextarea.vue";
import FormInput from "@/views/components/Form/FormInput.vue";
import FormSelect from "@/views/components/Form/FormSelect.vue";
import FormNumber from "@/views/components/Form/FormNumber.vue";
import FormDateTimePicker from "@/views/components/Form/FormDateTimePicker.vue";
import mixOrderGroupFormData from "@/mixins/mixOrderGroupFormData";
import mobileCheckMixin from "@/mixins/mobileCheckMixin";
import {mapActions, mapState, mapStores} from "pinia";
import {useCommonStore} from "@/store/common";
import {useOrderGroupsStore} from "@/store/orderGroups";
import {useMixStore} from "@/store/mix";
import {httpService} from "@/services/http.service";
import functionsMixin from "@/mixins/functionsMixin";
import paymentMethods from "@/mixins/paymentMethods";
import FormSelectSpecGoodByProduct from "@/views/components/Form/Select/FormSelectSpecGoodByProduct.vue";
import FormSelectDispatchGoods from "@/views/components/Form/Select/FormSelectDispatchGoods.vue";

export default {
    name: "MixOrderGroupCreateForm",
    props: ['modelValue', 'mixers', 'disableForm', 'updateMode'],
    components: {
        FormSelectDispatchGoods,
        FormSelectSpecGoodByProduct,
        FormDateTimePicker, FormNumber, FormSelect, FormInput, FormTextarea, DispatchMixersTable
    },
    mixins: [mixOrderGroupFormData, mobileCheckMixin, functionsMixin, paymentMethods],
    data() {
        return {
            activeTab: 'delivery',
            payment_type: '',
            documentsByType: [],
            selectedContract: [],
            selectedInvoice: [],
            users: [],
            // address: [],
            // companyManagers: [],
            timeInfo: {
                toObject: 0,
                return: 0,
                loading: 0,
                unloading: 0
            },
            serviceTariffs: [],
            sellerCompanyId: null,
            carrierCompanyId: null,
            loadDispatchInfo: false
        }
    },
    created() {
        this.getAccountModuleParams()
    },
    computed: {
        ...mapState(useCommonStore, {
            companies: 'getCompanies',
            user: 'getUser',
            goods: 'getGoods',
        }),
        ...mapState(useOrderGroupsStore, {
            getDate: 'getDate',
            filters: 'getFilters',
            address: 'getAddress',
            companyManagers: 'getCompanyManagers',
        }),
        ...mapStores(useMixStore),
        selectedBuyerName: function () {
            return this.companies?.find(({id}) => id === this.form.buyer_company_id)?.name;
        },
        selectedMixerIds() {
            if (this.checkArray(this.form.custom_mixers)) {
                return this.form.custom_mixers.map(item => item.id)
            } else {
                return this.form.custom_mixers
            }
        }
    },
    methods: {
        ...mapActions(useOrderGroupsStore, ['pullAddress', 'pullCompanyManagers']),
        selectCustomMixers(e) {
            this.form.custom_mixers = e.map(user => user.vehicleId)
        },
        updatePrice(price) {
            this.form.good_price = price
        },
        async serviceTariffsGet() {
            httpService().post(this.api.serviceTariff.getAll, {
                paginate: false
            }).then((response) => {
                let data = response.data

                if (data.success) {
                    this.serviceTariffs = data.items
                }

            }).catch(() => {
                this.$message({
                    message: 'Ошибка ответа от сервера',
                    showClose: true,
                    type: 'error'
                })
            })
        },
        // setCompanyManagers(companyId) {
        //     httpService().post(this.api.company.getAttribute, {
        //         'companyId': companyId,
        //         'attribute': 'managers',
        //     }).then((response) => {
        //         const {attribute} = response.data
        //         this.companyManagers = attribute
        //     })
        // },
        // setAddress(companyId) {
        //     httpService().post(this.api.company.getAttribute, {
        //         'companyId': companyId,
        //         'attribute': 'addresses',
        //     }).then((response) => {
        //         const {attribute} = response.data
        //         this.address = attribute
        //     })
        // },
        getVehicle() {
            httpService().post(this.api.vehicle.get).then((response) => {
                let data = response.data

                if (data.success)
                    this.vehicles = data.vehicles
            })
        },
        handleCheckedMixersChange(mixer_ids) {
            const result = this.mixers.filter(item => mixer_ids.includes(item.id));
            this.form.mix_ids = [...new Set(result.map(m => m.account_module_id))]
        },
        setTimezone(hour, timezone) {
            if (hour) {
                return this.timeAddTimezone(`${hour}:00:00`, timezone)
            }
            return ''
        },
        deliveryPriceEditTrue() {
            this.form.delivery_price_manual_edit = true
        },
        resetOrder() {
            this.orders = []
            this.timeInfo = {
                toObject: 0,
                return: 0,
                loading: 0,
                unloading: 0
            }
            this.form.orders_count = 0
        },
        changeBuyer() {
            if (!this.updateMode) {
                this.form.seller_company_id = this.form.seller_company_id === this.sellerCompanyId || this.form.carrier_company_id === null ? this.form.seller_company_id : null
                this.form.carrier_company_id = this.form.carrier_company_id === this.carrierCompanyId || this.form.carrier_company_id === null ? this.form.carrier_company_id : null
                this.form.contract_id = null
                this.form.invoice_id = null
                this.form.delivery_address_id = null
                if (this.form.buyer_company_id) setTimeout(() => this.$emit('getOrderGroupInfoCreate', this.form.buyer_company_id), 150)

            }
        },
        async getUser() {
            httpService().post(this.api.users.get).then((response) => {
                let data = response.data

                if (data.success) {
                    this.users = data.users

                    if (this.user && !this.form.manager_id) this.form.manager_id = this.user.id
                }
            })
        },
        getAccountModuleParams() {
            // берем настройки модуля из pinia
            let settings = this.modules.find(el => el.accountModuleId == this.$route.params.id)?.settings ?? []
            if (settings) {
                this.form.contract_required = settings?.contract_required
                this.payment_type = settings?.payment_type
                this.sellerCompanyId = settings?.default_seller ? Number(settings?.default_seller) : null
                this.carrierCompanyId = settings?.default_carrier ? Number(settings?.default_carrier) : null
                this.form.seller_company_id = this.sellerCompanyId
                this.form.carrier_company_id = this.carrierCompanyId

                if (settings?.lab_required && settings?.lab_required == 1) {
                    this.form.lab = true
                }
            }

            if (this.payment_type) {
                this.getAccountDocuments(this.payment_type)
            }
        },
        getAccountDocuments(payment_type) {
            if (payment_type && payment_type !== '0') {
                httpService().post(this.api.document.getByType, {
                    type: payment_type
                }).then((response) => {
                    let data = response.data

                    if (data.success) {
                        this.documentsByType = data.document
                    }
                })
            }
        },
        checkPaymentStatus(status) {
            switch (status) {
                case 'not-paid':
                    return 'Не оплачен'
                case 'failed':
                    return 'Отменен'
                case 'partially-paid':
                    return 'Оплачен частично'
                case 'paid':
                    return 'Оплачен'
                default:
                    return 'Не оплачен'
            }
        },
    },
    watch: {
        'form.buyer_company_id': {
            handler: async function (value) {
                this.changeBuyer()
                if (value) {
                    this.pullCompanyManagers(value)
                    if (!this.loadDispatchInfo) {
                        await this.getUser()
                        await this.serviceTariffsGet()
                        this.loadDispatchInfo = true
                    }
                }
            },
            deep: true,
            immediate: true
        },
        'modelValue': {
            handler: function () {
                this.form = this.modelValue
            },
            deep: true,
            immediate: true
        },
        'form.contract_id': {
            handler: function (value) {
                if (this.form.contract_id && !this.updateMode) this.form.good_id = null
                if (this.payment_type) {
                    this.selectedContract = this.documentsByType.find(el => el.id === value) ?? []
                }
            },
            deep: true,
            immediate: true
        },
        'form.invoice_id': {
            handler: function (value) {
                if (this.form.invoice_id && !this.updateMode) this.form.good_id = null
                if (this.payment_type) {
                    this.selectedInvoice = this.documentsByType.find(el => el.id === value) ?? []
                }
            },
            deep: true,
            immediate: true
        },
        'form': {
            handler: function () {
                this.$emit('update:modelValue', this.form)
            },
            deep: true,
            immediate: true
        }
    }
}
</script>

<style scoped>

.selectedMixes {
    color: #393F4A;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 18px;
    display: flex;
    align-items: center;
}

.mix-action {
    align-items: center;
}

.mix-section {
    display: flex;
    justify-content: space-between;
}

.mix-load {
    color: #BD2626;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 16px;
    margin-right: 14px;
}
</style>
