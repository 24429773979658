<template>
    <div class="widget-card widget-manufacturing-products">
        <div class="widget-card-title">{{ title }}
            <el-button class="widget-reload" :loading="loadingButton" @click="onUpdate" :icon="icons.refresh"
                       circle></el-button>
        </div>
        <div class="widget-card-body">
            <div class="col">
                <div v-if="count > 0 || fisrtUpdate === 0" v-loading="loading">
                    <div class="widget-header-text">
                        <h2>
                            <number
                                ref="count"
                                :to="count"
                                :format="theFormat"
                                :duration="1"
                                :delay="0"
                                easing="Power4.easeOut"/>
                            <span class="unit">{{ countUnit }}</span>
                        </h2>
                        <p>Отгрузки за сегодня</p>
                    </div>
                    <div id="scrollDiv" class="widget-scroll-x">
                        <div :style="{ width: myWidth + 'px' }">
                            <v-chart v-model="chart" class="chart" :option="option" autoresize/>
                        </div>
                    </div>
                </div>
                <div v-if="count === 0 && fisrtUpdate === 1">
                    <div class="widget-header-text">
                        <h3>Отгрузки<br>за сегодня</h3>
                    </div>
                    <div class="no-data-block">
                        <NoDataIcon/>
                        <p>
                            Сегодня отгрузки не производились<br>Информация актуальна с 00:00 до {{ time }}
                        </p>
                    </div>
                </div>
            </div>
            <div class="col prodAndShipmentsScroll">
                <div class="item" v-for="(good, index) in shipmentGoods" :key="index">
                    <el-row>
                        <el-col :span="2">
                            <span class="indicator-active" v-if="good.value > 0"></span>
                            <span class="indicator" v-if="good.value === 0"></span>
                        </el-col>
                        <el-col :span="17">
                            <span class="item-top">{{ getData(good.id,'good') }}</span>
                        </el-col>
                        <el-col :span="5">
                        <span v-if="good.value > 0" class="item-right colored">
                            <number
                                ref="count"
                                :to="good.value"
                                :format="theFormat"
                                :duration="1"
                                :delay="0"
                                easing="Power4.easeOut"/>
                            {{ getData(good.id, 'good', 'unit') }}
                        </span>
                            <span v-if="good.value === 0" class="item-right not-colored">
                            {{ good.value }} {{ getData(good.id, 'good', 'unit') }}
                        </span>
                        </el-col>
                    </el-row>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import VChart from 'vue-echarts'
import NoDataIcon from '../../components/NoDataIcon'
import functionsMixin from '../../../mixins/functionsMixin'
import { httpService } from '@/services/http.service'
import iconsMixin from "@/mixins/iconsMixin";

var numeral = require("numeral")

export default {
    name: 'ShipmentsRealtimeWidgetLeft',
    props: [
        'id',
    ],
    inject: [
        'api'
    ],
    mixins: [
        functionsMixin, iconsMixin
    ],
    components: {
        VChart,
        NoDataIcon
    },
    data() {
        return {
            loadingButton: true,
            loading: true,
            active: 3,
            hours: [],
            shipmentGoods: [],
            total: [],
            oldLength: 0,
            newLength: 0,
            myWidth: 1200,
            chart: 1,
            count: 0,
            time: null,
            countUnit: null,
            fisrtUpdate: 0,
            title: 'Отгрузки за сегодня',
        }
    },
    created() {
        const today = new Date()
        this.loadingButton = true
        this.widgetGet(this.id)
        window.setInterval(() => {
            this.loadingButton = false
        }, 800)
        this.myWidth = today.getHours() * 44
        window.setTimeout(() => {
            if (this.count > 0) this.scrollToEnd()
        }, 450)
        this.counterInterval = setInterval(
            function () {
                this.updateWidget();
            }.bind(this), Math.floor(Math.random() * (10000 - 8000 + 1)) + 8000);
    },
    updated() {

    },
    beforeUnmount: function () {
        clearInterval(this.counterInterval)
    },
    methods: {
        theFormat(number) {
            return numeral(number).format("0,0").replace(',', ' ');
        },
        widgetGet(id) { // метод получения данных виджета с api
            // обращаемся в api, передаем id виджета
            httpService().post(this.api.widget.getOne, {
                id: id
            }).then((response) => {
                    // получаем массив с api
                    let data = response.data
                    if (data.success)

                        this.shipmentGoods = data.data.goods.sort((a, b) => a.value < b.value ? 1 : -1)
                        this.oldLength = this.hours.length
                        this.hours = data.data.hours
                        this.total = data.data.totals
                        this.countUnit = data.data.count_unit
                        this.count = data.data.count
                        this.time = data.time
                        this.fisrtUpdate = 1
                        this.title = data.widgetName

                        this.newLength = this.hours.length
                        this.getUnit()
                        this.myWidth = this.newLength * 44
                        this.loading = false
                    if (this.newLength > this.oldLength) {
                        window.setTimeout(() => {
                            if (this.count > 0)  this.scrollToEnd()
                        }, 450)
                    }

                }
            )
        },
        onUpdate() {
            this.loadingButton = true
            this.updateWidget()
            window.setInterval(() => {
                this.loadingButton = false
            }, 800);
            if (this.count > 0) this.scrollToEnd()
        },
        updateWidget() // метод обновления виджета
        {
            this.widgetGet(this.id)
        },
        scrollToEnd: function () {
            document.getElementById('scrollDiv').scrollTo({left: 2000, behavior: 'smooth'});
        },
        getUnit() {
            let units = []

            function onlyUnique(value, index, self) {
                return self.indexOf(value) === index
            }

            this.shipmentGoods.forEach(element => units.push( this.getData(element.id, 'good','unit') ))
            units = units.filter(onlyUnique)

            if (units.length === 1) {
                this.countUnit = units[0]
            } else {
                this.countUnit = 'ед.'
            }
        }
    },
    computed: {
        option() {
            return {
                backgroundColor: 'transparent',
                tooltip: {
                    trigger: 'axis',
                    backgroundColor: '#181b3b',
                    borderColor: '#181b3b',
                    textStyle: {
                        color: '#fff'
                    },
                    axisPointer: {
                        type: 'shadow'
                    }
                },
                grid: {
                    left: -10,
                    right: 15,
                    bottom: 10,
                    containLabel: true,
                    height: 150
                },
                xAxis: [
                    {
                        type: 'category',
                        data: this.hours,
                        shadowColor: 'rgba(172,34,34,0.3)',
                        borderColor: 'transparent',
                        axisTick: {
                            alignWithLabel: false
                        },
                        axisLabel: {
                            textStyle: {
                                color: '#ACAFC6'
                            }
                        },
                        axisLine: {
                            show: false
                        },
                        splitLine: {
                            show: true,
                            lineStyle: {
                                type: 'dashed',
                                color: '#2C2F4A'
                            }
                        }
                    }
                ],
                yAxis: [
                    {
                        show: false
                    }
                ],

                series: [
                    {
                        name: 'Кол-во',
                        type: 'bar',
                        barWidth: 12,
                        data: this.total,
                        itemStyle: {
                            color: '#00cdc4',
                            shadowColor: 'rgba(0, 211, 203, 0.5)',
                            borderRadius: 4,
                            shadowBlur: 12,
                            shadowOffsetY: 0,
                        },
                    },
                ]
            }
        }
    },
}
</script>

<style scoped>

</style>
