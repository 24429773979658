<template>
    <div id="main" :class="{'driver': isDriver, 'operator': isOperator}">
        <NotAvailableModuleAlert/>
        <PaymentModal/>
        <div id="mobile-header" v-if="isDriver">
            <div id="mobile-logo" class="mobile-menu">
                <router-link to="/dashboard">
                    <LogoLight
                        :only-logo="true"
                    />
                </router-link>
                <div class="textMD textMedium color-white" style="margin-top: 4px">Заявки</div>
                <div style="width: 86px; text-align: right">
                    <MenuIcon @click="openNav"/>
                </div>
            </div>
        </div>
        <div id="mobile-menu" v-if="mobileNav">
            <div id="mobile-menu-header">
                <span>{{ isDriver ? 'Меню' : 'Модули' }}</span>
                <Burger @toggle="closeNav" :status="mobileNav"/>
            </div>
            <AboutDriverInfo v-if="isDriver" :driver="user" @closeMenu="closeNav"/>
            <Navigation :reloadNav="reloadNav" @closeMenu="closeNav" :mobile="true"/>
        </div>
        <aside v-if="!mobile">
            <div id="logo">
                <router-link to="/dashboard">
                    <LogoLight/>
                </router-link>
            </div>
            <Navigation :reloadNav="reloadNav"/>
        </aside>
        <div id="content" v-if="!notAvailableModuleAlertStatus">
            <PageHeader :title="title" :integrations="integrations" :relations="relations" :settings="settings"
                        v-if="!hideTitle"/>
            <div id="headTabs" v-if="tabs && tabs.length > 0">
                <div id="headTabsBlock">
                    <router-link :to="{ name: tab.route }" class="headTab" active-class="headTabActive" v-for="(tab, index) in tabs"
                                 :key="index">
                        {{ tab.name }}
                    </router-link>
                </div>
            </div>
            <div id="content-body">
                <slot/>
            </div>
        </div>

        <div v-if="mobile && !isDriver" id="mobile-bottom-bar-wrapper">
            <router-link @click="closeNav" :class="{'router-link-exact-active__menu-opened': mobileNav}"
                         class="mobile-bottom-bar-item" to="/dashboard">
                <DashboardIcon/>
                Главная
            </router-link>
            <div @click="openNav" class="mobile-bottom-bar-item" :class="{'router-link-exact-active': mobileNav}">
                <StorehouseIcon/>
                Модули
            </div>
            <router-link @click="closeNav" :class="{'router-link-exact-active__menu-opened': mobileNav}"
                         class="mobile-bottom-bar-item" to="/settings">
                <SettingsIcon/>
                Настройки
            </router-link>
        </div>
    </div>
</template>

<script>
import LogoLight from '../components/Logo/DGLightLogo'
import Navigation from '../components/Navigation'
import AboutDriverInfo from '@/views/components/AboutDriverInfo'
import PageHeader from '../components/PageHeader'
import mobileCheckMixin from '../../mixins/mobileCheckMixin'
import StorehouseIcon from "@/views/components/Icons/StorehouseIcon.vue";
import SettingsIcon from "@/views/components/Icons/SettingsIcon.vue";
import DashboardIcon from "@/views/components/Icons/DashboardIcon.vue";
import Burger from "@/views/components/Burger.vue";
import NotAvailableModuleAlert from "@/views/components/NotAvailableModuleAlert.vue";
import {mapState, mapStores} from "pinia";
import {useCommonStore} from "@/store/common";
import {useEchoStore} from "@/store/Echo";

import PaymentModal from "@/views/components/PaymentModal.vue";
import MenuIcon from "../components/Icons/MenuIcon.vue";

export default {
    name: "Auth",
    components: {
        MenuIcon,
        PaymentModal,
        AboutDriverInfo,
        NotAvailableModuleAlert,
        Burger, DashboardIcon, SettingsIcon, StorehouseIcon, Navigation, LogoLight, PageHeader
    },
    data() {
        return {
            pageTitle: null,
            modules: [],
        }
    },
    inject: [
        'api'
    ],
    props: [
        'title',
        'integrations',
        'relations',
        'settings',
        'reloadNav',
        'hideTitle',
        'tabs'
    ],
    computed: {
        ...mapState(useCommonStore, {
            notAvailableModuleAlertStatus: 'getNotAvailableModuleAlertStatus',
            isDriver: 'isDriver',
            isOperator: 'isOperator',
            user: 'getUser',
        }),
        ...mapStores(useEchoStore)
    },
    mixins: [
        mobileCheckMixin
    ],
    methods: {
        openNav() {
            this.mobileNav = true;
            document.body.style.overflow = 'hidden'
        },
        closeNav() {
            this.mobileNav = false;
            document.body.style.overflow = 'unset';
        }
    },
    watch: {
        $route() {
            this.mobileNav = false
        }
    },
    mounted() {
        console.log("it's work?");
        // this.echoStore.testEventListener();
    }
}
</script>

<style scoped>
aside {
    overflow: hidden;
}
</style>
