export default {
    data() {
        return {
            brands: [{
                id: 'М100',
                name: 'М100'
            }, {
                id: 'М150',
                name: 'М150'
            }, {
                id: 'М200',
                name: 'М200'
            }, {
                id: 'М250',
                name: 'М250'
            }, {
                id: 'М300',
                name: 'М300'
            }, {
                id: 'М350',
                name: 'М350'
            }, {
                id: 'М400',
                name: 'М400'
            },],
            classes: [{
                id: 'В5',
                name: 'В5'
            }, {
                id: 'В7,5',
                name: 'В7,5'
            }, {
                id: 'В10',
                name: 'В10'
            }, {
                id: 'В12,5',
                name: 'В12,5'
            }, {
                id: 'В15',
                name: 'В15'
            }, {
                id: 'В20',
                name: 'В20'
            }, {
                id: 'В22,5',
                name: 'В22,5'
            }, {
                id: 'В25',
                name: 'В25'
            }, {
                id: 'В27,5',
                name: 'В27,5'
            }, {
                id: 'В30',
                name: 'В30'
            }, {
                id: 'В35',
                name: 'В35'
            }, {
                id: 'В40',
                name: 'В40'
            }, {
                id: 'В45',
                name: 'В45'
            }, {
                id: 'В50',
                name: 'В50'
            }, {
                id: 'В55',
                name: 'В55'
            }, {
                id: 'В60',
                name: 'В60'
            },],
            mobility: [{
                id: 'П2',
                name: 'П2'
            },{
                id: 'П3',
                name: 'П3'
            }, {
                id: 'П4',
                name: 'П4'
            }, {
                id: 'П5',
                name: 'П5'
            }],
            classRadio: [{
                id: 'I',
                name: 'I'
            }, {
                id: 'II',
                name: 'II'
            }, {
                id: 'III',
                name: 'III'
            }, {
                id: 'IV',
                name: 'IV'
            }],
        }
    },
}
