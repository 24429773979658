<template>
    <div class="mb-15"
         v-if="!disableSearch && mobile">
        <el-input
            v-model="search"
            :prefix-icon="icons.search"
            placeholder="Поиск" clearable/>
    </div>
    <div class="TableHeadBlock">
        <div class="d-flex">
            <div v-if="showUserStatusButtons" style="margin-right: 20px">
                <ButtonGroup
                    :options="[{label:'Активные', key: 'active'}, {label:'На модерации', key: 'wait'}]"
                    :selected="userStatusSelected"
                    @changeSelect="changeUserStatusSelected"
                />
            </div>
            <div class="searchAndFilter d-flex">
                <slot name="searchAndFilter"/>
                <el-input
                    class="searchNew"
                    v-model="search"
                    v-if="!disableSearch && !mobile"
                    :prefix-icon="icons.search"
                    placeholder="Поиск" clearable/>

                <div
                    class="dates"
                    v-if="showDateFilter && !mobile"
                >
                    <day-list-filter
                        :date="currentDate"
                        :loading="loading"
                        @onChangeDate="$emit('changeDate', $event)"
                    ></day-list-filter>
                </div>


                <button
                    @click="$emit('openFilterBlock')"
                    v-if="!disableFilter"
                    class="ci-btn ci-btn_light" :disabled="loading"
                >
                    <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M14 15L21 15" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                              stroke="currentColor"/>
                        <path d="M3 15H5" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                              stroke="currentColor"/>
                        <path
                            d="M7.5 17.5C8.88071 17.5 10 16.3807 10 15C10 13.6193 8.88071 12.5 7.5 12.5C6.11929 12.5 5 13.6193 5 15C5 16.3807 6.11929 17.5 7.5 17.5Z"
                            stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke="currentColor"/>
                        <path d="M20 9H21" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                              stroke="currentColor"/>
                        <path d="M3 9H10" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                              stroke="currentColor"/>
                        <path
                            d="M16.5 11.5C17.8807 11.5 19 10.3807 19 9C19 7.61929 17.8807 6.5 16.5 6.5C15.1193 6.5 14 7.61929 14 9C14 10.3807 15.1193 11.5 16.5 11.5Z"
                            stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke="currentColor"/>
                    </svg>
                    <span v-if="windowWidth > 1200">Фильтры</span>
                </button>
                <slot name="afterSearchAndFilter"/>
            </div>
        </div>
        <div class="btns-group">
            <el-button @click="$emit('moduleGet')"
                       v-if="!disableUpdateButton"
                       class="whiteButton" :disabled="loading" plain
                       size="mini">
                <template v-if="!updateButtonLabel">
                    <svg viewBox="0 0 24 24" class="mr-0" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M14 16H19V21" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                        <path
                            d="M4.58252 14.9971C5.1432 16.3848 6.08195 17.5874 7.29206 18.4682C8.50217 19.3491 9.93511 19.8727 11.428 19.9797C12.9209 20.0868 14.4139 19.7728 15.7373 19.0736C17.0606 18.3744 18.1613 17.318 18.9142 16.0244"
                            stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M10 8H5V3" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                        <path
                            d="M19.4176 9.00315C18.8569 7.61541 17.9182 6.41278 16.7081 5.53197C15.498 4.65116 14.065 4.12749 12.5721 4.02048C11.0792 3.91346 9.58624 4.2274 8.26287 4.92661C6.9395 5.62582 5.83882 6.68226 5.08594 7.97584"
                            stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                </template>
                <template v-else>
                    {{ updateButtonLabel }}
                </template>
            </el-button>
            <slot name="buttonsBlock"></slot>
            <el-tooltip :disabled="!tooltipText" content="Добавить отгрузку" placement="top" v-if="!disableCreateButton">
                <el-button
                    @click="$emit('openCreateValueDrawer')"
                    class="ci-btn ci-btn_blue ml-0"
                    :icon="icon ?? icons.plus"
                />
            </el-tooltip>
        </div>
    </div>
</template>

<script>
import mobileCheckMixin from "@/mixins/mobileCheckMixin";
import iconsMixin from "@/mixins/iconsMixin";
import ButtonGroup from "@/views/components/ButtonGroup.vue";
import DayListFilter from "@/views/components/Table/DayListFilter.vue";
import moment from "moment";
import {isSameDate} from '@/utils/common';

export default {
    name: "TableHeadBlock",
    components: {
        ButtonGroup,
        DayListFilter,
    },
    props: [
        'modelValue',
        'loading',
        'createButtonLabel',
        'icon',
        'updateButtonLabel',
        'disableSearch',
        'disableFilter',
        'disableUpdateButton',
        'disableCreateButton',
        'userStatusSelected',
        'showUserStatusButtons',
        'showDateFilter',
        'currentDate',
        'filterShow',
        'tooltipText'
    ],
    mixins: [
        mobileCheckMixin, iconsMixin
    ],
    data() {
        return {
            search: this.modelValue,
            dayWeeks: [
                'ПН', 'ВТ', 'СР', 'ЧТ', 'ПТ', 'СБ', 'ВС'
            ],
        }
    },
    watch: {
        'search': {
            handler: function () {
                this.$emit('update:modelValue', this.search)
            },
            deep: true,
            immediate: true
        },
        'modelValue': {
            handler: function() {
                this.search = this.modelValue
            },
            deep: true,
            immediate: true,
        }
    },
    methods: {
        changeUserStatusSelected(status) {
            this.$emit('changeUserStatusSelected', status);
        },
        checkCurrent(date) {
            let current = false


            if (this.currentDate) {
                current = isSameDate(this.currentDate, date);
            } else {
                current = moment().startOf('day').isSame(moment(date))
            }

            return current
        },
        getDays() {
            let days = []
            for (let i = 1; i >= -5; i--) {
                let date = moment().subtract(i, "days").startOf('day')
                let number = date.format('DD')
                let day = date.format('e')

                days.push({
                    number: number,
                    day: this.dayWeeks[day],
                    date: date
                })
            }

            return days
        },
        changeDate(value) {
            if (isSameDate(value, this.currentDate)) {
                return;
            }

            this.$emit('changeDate', value);
        }
    },
    mounted() {
        if (this.currentDate) {
            this.$emit('changeDate', this.currentDate)
        } else {
            this.$emit('changeDate', moment().startOf('days'))
        }
    }
}
</script>

<style scoped>
@import "../../../sass/_variables.scss";


</style>
