<template>
    <el-form-item label="Продукция" v-bind:class="{ 'is-required': required }">
        <div class="inputModal"
             :class="[
                     {disabled: disabled},
                     {pointer: !disabled},
                     {empty: !itemId},
                 ]">
            <div class="d-flex">
                <div class="inputModalWrapper" @click="openSelectGoodDialog">
                    {{ itemName ?? 'Выберите продукцию' }}
                </div>
                <div class="inputModalClear" v-if="itemId" @click="clearItemId()">
                    <i class="el-icon el-input__icon el-input__clear">
                        <svg xmlns="http://www.w3.org/2000/svg"
                             viewBox="0 0 1024 1024">
                            <path
                                d="m466.752 512-90.496-90.496a32 32 0 0 1 45.248-45.248L512 466.752l90.496-90.496a32 32 0 1 1 45.248 45.248L557.248 512l90.496 90.496a32 32 0 1 1-45.248 45.248L512 557.248l-90.496 90.496a32 32 0 0 1-45.248-45.248z"></path>
                            <path
                                d="M512 896a384 384 0 1 0 0-768 384 384 0 0 0 0 768m0 64a448 448 0 1 1 0-896 448 448 0 0 1 0 896"></path>
                        </svg>
                    </i>
                </div>
            </div>
        </div>
    </el-form-item>

    <el-dialog
        v-model="selectGoodDialog"
        title="Выберите продукцию"
        :width="dialogSize"
        :destroy-on-close="true"
    >
        <template v-if="loadingData">
            <TableDataLoader text="продукции"/>
        </template>
        <template v-else>
            <TableHeadBlock
                :new="true"
                v-model="search"
                :loading="loading"
                :disable-filter="true"
                :disable-create-button="true"
                @moduleGet="getGoodsData()"
            >
                <template v-slot:afterSearchAndFilter>
                    <el-select
                        v-model="filters.class"
                        placeholder="Класс"
                        size="large"
                        class="filterSelect"
                        style="width: 180px"
                        @change="getGoodsData()"
                        clearable
                    >
                        <el-option
                            v-for="item in classes"
                            :key="item.id"
                            :label="item.name"
                            :value="item.id"
                        />
                    </el-select>
                    <el-select
                        v-model="filters.mobility"
                        placeholder="Пластичность"
                        size="large"
                        class="filterSelect"
                        style="width: 180px"
                        @change="getGoodsData()"
                        clearable
                    >
                        <el-option
                            v-for="item in mobility"
                            :key="item.id"
                            :label="item.name"
                            :value="item.id"
                        />
                    </el-select>
                    <TableIntegrationFilter
                        v-model="filters.integrationUnitId"
                        @moduleGet="getGoodsData"
                        :table="true"
                    />
                </template>
            </TableHeadBlock>

            <EmptyState
                :values="goodsData"
                :search="search"
                :loading="loading"
                list-types="продукции"
                action-text="Создайте продукцию в справочнике или получите из 1С"
            />

            <el-table
                v-loading="loading"
                v-if="goodsData.length > 0"
                :data="goodsData"
                @current-change="handleCurrentChange"
                class="w-100 mt-15 defaultTableHeight">
                <el-table-column
                    prop="id"
                    label="#"
                    width="100"
                    class-name="pointer"
                    sortable>
                    <template v-slot="scope">
                                    <span class="textSM textMedium"
                                          :class="[{neutral200: scope.row.id !== itemId}, {primary600: scope.row.id === itemId}]"
                                    >#{{ formatNumber(scope.row.id) }}</span>
                    </template>
                </el-table-column>
                <el-table-column
                    prop="name"
                    label="Название"
                    class-name="pointer"
                    sortable>
                    <template v-slot="scope">
                        <span class="textSM textMedium primary600 cutOverflow">{{ scope.row.name }}</span>
                    </template>
                </el-table-column>
                <el-table-column
                    prop="integration"
                    label="Интеграция"
                    class-name="pointer"
                    width="220px">
                    <template v-slot="scope">
                        <template v-if="scope.row.integration_unit_id">
                            <IntegrationLogo
                                :integration-unit-id="scope.row.integration_unit_id"
                            />
                        </template>
                        <template v-else>
                            <span class="textRegular textSM neutral200">-</span>
                        </template>
                    </template>
                </el-table-column>
            </el-table>


            <TablePagination
                v-model="pageSize"
                :total="valueLength"
                @action="setPage"
            />
        </template>
    </el-dialog>
</template>

<script>
import tableMixin from "@/mixins/tableMixin";
import mobileCheckMixin from "@/mixins/mobileCheckMixin";
import {httpService} from "@/services/http.service";
import TablePagination from "@/views/components/Table/TablePagination.vue";
import TableHeadBlock from "@/views/components/Table/TableHeadBlock.vue";
import EmptyState from "@/views/components/EmptyState.vue";
import {mapState} from "pinia";
import {useCommonStore} from "@/store/common";
import functionsMixin from "@/mixins/functionsMixin";
import TableDataLoader from "@/views/components/Table/TableDataLoader.vue";
import IntegrationLogo from "@/views/components/Blocks/IntegrationLogo.vue";
import goodTypes from "@/mixins/goodTypes";
import concreteInfo from "@/mixins/concreteInfo";
import TableIntegrationFilter from "@/views/components/Table/TableIntegrationFilter.vue";

// модалка выбора продукции в диспетчерской

export default {
    name: "FormSelectDispatchGoods",
    components: {TableIntegrationFilter, IntegrationLogo, TableDataLoader, EmptyState, TableHeadBlock, TablePagination},
    mixins: [
        tableMixin, // подключаем миксин для работы с таблицей
        mobileCheckMixin, // подключаем миксин для размеров модалок на основе ширины экрана
        functionsMixin, // основной миксин, здесь он нужен для тысячных пробелов в id
        goodTypes, // миксин для получения массива с типами товаров
        concreteInfo, // миксин с данными по классам бетона
    ],
    computed: {
        ...mapState(useCommonStore, {
            integrations: 'getIntegrations', // добавляем массив интеграций из pinia для вывода названий
            goodsFirstRequest: 'getGoodsFirstRequest', // получаем массив гудсов
        }),
        itemName() {
            if (this.itemId) {
                return this.selectedGood?.name ?? this.good?.name
            }
            return null
        },
        companyGoods() {
            return this.goods
        }
    },
    inject: [
        'api' // инъекция, без которой не работают обращения в бек
    ],
    props: [
        'modelValue', // модель с коротой работаем выше
        'disabled', // отключено ли поле
        'required', // обязательное ли поле
        'formItem', // делаем элемент формы
        'invoiceId', // id счета
        'contractId', // id договора
        'goodIds', // продукция по которой нужно фильтровать
        'good', // объект выбранной/подгруженной продукции
        'goods', // массив полученный из getLast
    ],
    data() {
        return {
            itemId: this.modelValue, // id выбранного элемента
            selectedGood: this.good,
            selectGoodDialog: false, // параметр для открытия/закрытия модалки
            loading: true,
            loadingData: true,
            filters: {
                integrationUnitId: null,
                class: null,
                mobility: null
            },
            goodsData: [],
            firstOpen: true
        }
    },
    methods: {
        clearItemId() {
            this.itemId = null
        },
        openSelectGoodDialog() {
            if (!this.disabled) {
                this.search = null
                this.firstOpen = true
                this.filters.integrationUnitId = null
                this.filters.class = null
                this.filters.mobility = null
                let storeItems = this.goodsFirstRequest?.goods?.data ?? []

                // если это первый открытие модалки и нет доп условий
                if (this.page === 1 && !this.invoiceId && !this.contractId && !this.goodIds) {
                    this.loadingData = false
                    this.loading = false

                    // при первом открытии показываем данные с бека с топ 10 продукции для контрагента
                    if (this.firstOpen) {
                        this.goodsData = this.companyGoods ?? storeItems ?? []
                    } else {
                        this.goodsData = storeItems
                    }
                    this.valueLength = this.goodsFirstRequest.goods.total
                } else {
                    this.values = []
                    this.getGoodsData()
                }
                this.selectGoodDialog = true
            } else {
                this.$message({
                    message: 'Выберите контрагента',
                    showClose: true,
                    type: 'info'
                })
            }
        },
        getGoodsData() {
            this.loading = true
            httpService().post(this.api.good.getByDispatch, {
                invoiceId: this.invoiceId,
                contractId: this.contractId,
                paginate: true,
                perPage: this.pageSize,
                page: this.page,
                search: this.search,
                filter: this.filters,
                goodIds: this.goodIds,
                allData: true
            }).then((response) => {
                let data = response.data

                if (data.success) {
                    this.goodsData = data.goods.data
                    this.valueLength = data.goods.total
                    this.loadingData = false
                    this.loading = false
                }
                //this.$emit('update:modelValue', this.goodsData.find(el => el.id === this.modelValue)?.id)
            })
        },
        handleCurrentChange(val) {
            this.itemId = val.id
            this.selectedGood = val
            this.selectGoodDialog = false
        },
        filterValues() {

        }
    },
    watch: {
        modelValue: {
            immediate: true,
            handler: function () {
                this.itemId = this.modelValue
            }
        },
        itemId: {
            immediate: true,
            handler: function () {
                this.$emit('update:modelValue', this.itemId)
                if (this.contractId && this.itemId) {
                    let price = this.goodsData.find(el => el.id === this.itemId)?.price
                    if (price) this.$emit('updatePrice', price)
                }
            }
        },
        pageSize() {
            this.firstOpen = false
            this.getGoodsData()
        },
        page() {
            this.firstOpen = false
            this.getGoodsData()
        },
        search() {
            this.firstOpen = false
            this.getGoodsData()
        },
    }

}
</script>

<style scoped>

</style>
