<template>
    <div id="y-map" class="y-map"></div>
</template>

<script setup>
import {defineProps, onMounted, watch} from "vue";
import {loadYmap} from "vue-yandex-maps";

const props = defineProps({
    coords: {
        default: () => [55.811511, 37.312518],
        type: Array,
    },
    factories: {
        default: () => [],
        type: Array
    },
    address: {
        default: () => null,
        type: Object
    },
    vehicles: {
        default: () => null,
        type: Object
    }
});

let map = null;

const init = async () => {
    const settings = {
        apiKey: 'c0f99ecd-989a-47c0-a38b-4a9b08e992e4',
        lang: 'ru_RU',
        coordorder: 'latlong',
        enterprise: false,
        version: '2.1'
    };
    await loadYmap({...settings, debug: true});
    // eslint-disable-next-line no-undef
    await ymaps.ready;

    // задаем центр карты по адресу доставки
    // eslint-disable-next-line no-undef
    map = new ymaps.Map(document.getElementById("y-map"), {
        center: [...props.coords],
        zoom: 10,
    });

    displayMapContent();
};

const displayMapContent = () => {
    if (map) {
        map.geoObjects.removeAll();

        // Выводим маршруты от каждого завода к клиентскому адресу
        props.factories.forEach((factory) => {
            // eslint-disable-next-line no-undef
            ymaps.route([
                {
                    type: 'wayPoint',
                    point: [factory.latitude, factory.longitude]
                },
                {
                    type: 'wayPoint',
                    point: [props.address.latitude, props.address.longitude]
                }
            ], {
                mapStateAutoApply: props.factories.length === 1
            }).then(function (route) {
                map.geoObjects.add(route);
                route.getPaths().options.set({
                    strokeColor: '#1984e6',
                    opacity: 0.8
                })

                route.getWayPoints().options.set({
                    visible: false
                })
            });
        });

        // Выводим заводы
        props.factories.forEach(factory => {
            // eslint-disable-next-line no-undef
            const factoryPoint = new ymaps.Placemark([factory.latitude, factory.longitude], {
                iconContent: 'Завод'
            }, {
                preset: "islands#blueFactoryCircleIcon"
            });
            map.geoObjects.add(factoryPoint);
        });

        // Выводим клиентский адрес
        // eslint-disable-next-line no-undef
        const clientPoint = new ymaps.Placemark([props.address.latitude, props.address.longitude], {
            iconContent: 'Объект'
        }, {
            preset: "islands#redGovernmentCircleIcon"
        });
        map.geoObjects.add(clientPoint);

        // Выводим транспортные средства на карте
        if (props.vehicles) {
            props.vehicles.forEach(vehicle => {
                // eslint-disable-next-line no-undef
                const myPlacemark = new ymaps.Placemark([vehicle.latitude, vehicle.longitude], {
                    iconContent: vehicle.number
                }, {
                    preset: `islands#${vehicle.color}StretchyIcon`
                });
                map.geoObjects.add(myPlacemark);
            });
        }
    }
};

watch(() => props.vehicles, () => {
    displayMapContent();
});

onMounted(() => init());
</script>

<style lang="scss" scoped>
.y-map {
    height: 85vh;
    width: 100%;
}
</style>

<style lang="scss">
.yandex-container {
    width: 100%;
    height: 100%;
}
</style>
