<template>
    <Auth title="Группы товаров" :tabs="tabs">
        <div class="card">
            <div class="card-body">
                <TableHeadBlock
                    v-model="search"
                    :loading="loading"
                    :disable-filter="true"
                    :disable-create-button="true"
                    @moduleGet="getAll()"
                >
                    <template v-slot:afterSearchAndFilter>
                        <el-button
                            v-if="multipleSelection.length > 0"
                            @click="remove(multipleSelection, true)"
                            class="ci-btn ci-btn_white"
                            :icon="icons.delete"
                        />
                    </template>
                </TableHeadBlock>

                <EmptyState
                    :values="displayData"
                    :filter="filter"
                    :search="search"
                    :loading="loading"
                    list-types="гпупп"
                />

                <el-table
                    v-loading="loading"
                    :data="displayData"
                    v-if="displayData.length > 0"
                    @selection-change="handleSelectionChange"
                    class="w-100 mt-15">
                    <el-table-column type="selection" width="40" />
                    <el-table-column
                        prop="id"
                        label="#"
                        width="100"/>
                    <el-table-column
                        prop="goods"
                        label="Кол-во"
                        width="90"
                    >
                        <template v-slot="scope">
                            <template v-if="scope.row.goods.length > 0">
                                <span class="textSM textMedium primary600">{{ formatNumber(scope.row.goods.length) }}</span>
                            </template>
                            <template v-else>
                                <span class="textSM textRegular neutral200">-</span>
                            </template>
                        </template>
                    </el-table-column>
                    <el-table-column
                        prop="goods"
                        label="Товары в группе"
                    >
                        <template v-slot="scope">
                            <template v-if="scope.row.goods.length > 0">
                                <span v-for="(good, index) in scope.row.goods" :key="index"
                                      class="textSM textRegular neutral900">
                                {{
                                        good.name
                                    }}<span class="neutral200"> #{{ formatNumber(good.id) }}</span>{{ index < scope.row.goods.length - 1 ? ', ' : '' }}
                            </span>
                            </template>
                            <template v-else>
                                <span class="textSM textRegular neutral200">-</span>
                            </template>
                        </template>
                    </el-table-column>
                    <el-table-column
                        fixed="right"
                        label=""
                        width="120px">
                        <template v-slot="scope">
                            <el-button
                                size="small"
                                v-if="scope.row.goods.length === 0"
                                :disabled="true"
                            >
                                Подробнее
                            </el-button>
                            <el-button
                                size="small"
                                v-else
                                :disabled="scope.row.goods.length === 0"
                                @click="
                                    $router.push({
                                        name: 'GoodDetails',
                                        params: { id: scope.row.goods[0].id },
                                    })
                                "
                            >
                                Подробнее
                            </el-button>
                        </template>
                    </el-table-column>
                </el-table>


                <TablePagination
                    v-model="pageSize"
                    :total="valueLength"
                    @action="setPage"
                />
            </div>
        </div>
    </Auth>
</template>

<script>
import Auth from '../../layouts/Auth'
import mobileCheckMixin from '../../../mixins/mobileCheckMixin'
import TableHeadBlock from '@/views/components/Table/TableHeadBlock'
import tableMixin from '@/mixins/tableMixin'
import TablePagination from '@/views/components/Table/TablePagination'
import {httpService} from '@/services/http.service'
import EmptyState from "@/views/components/EmptyState.vue";
import iconsMixin from "@/mixins/iconsMixin";
import functionsMixin from "@/mixins/functionsMixin";

export default {
    name: 'GoodsGroupsIndex',
    data() {
        return {
            loading: true,
            tabs: [{
                route: 'goods',
                name: 'Товары'
            }, {
                route: 'GoodsGroupsIndex',
                name: 'Группы'
            }],
        }
    },
    components: {
        EmptyState,
        TablePagination,
        TableHeadBlock,
        Auth
    },
    inject: [
        'api'
    ],
    mixins: [
        mobileCheckMixin, tableMixin, iconsMixin, functionsMixin
    ],
    created() {
        this.getAll()
    },
    methods: {
        getAll() {
            this.loading = true
            this.multipleSelection = []
            httpService().post(this.api.relationGroup.getRelationGroups, {
                type: 'good'
            }).then((response) => {
                let data = response.data

                if (data.success)
                    this.values = data.values
                this.loading = false

            }).catch(() => {
                this.$message({
                    message: 'Ошибка ответа от сервера',
                    showClose: true,
                    type: 'error'
                })
                this.$router.push({name: 'logout'})
            })
        },
        remove(ids, mass) {
            this.$confirm(
                'Вы действительно хотите произвести удаление?',
                'Подтвердите удаление',
                {
                    confirmButtonText: 'Удалить',
                    cancelButtonText: 'Отмена',
                    type: 'warning',
                }
            )
                .then(() => {
                    let idsDelete = []
                    ids.forEach(el => idsDelete.push(el.id))

                    httpService().post(this.api.relationGroup.multiRemove, {
                        ids: mass ? idsDelete : [ids]
                    }).then(() => {
                        this.$message({
                            message: 'Удалено',
                            showClose: true,
                            type: 'success'
                        })
                        this.getAll()
                    }).catch(() => {
                        this.$message({
                            message: 'Ошибка удаления',
                            type: 'error',
                            showClose: true,
                        })
                    })
                }).catch(() => {
                this.$message({
                    message: 'Удаление отменено',
                    showClose: true,
                    type: 'info'
                })
            })
        },
        filterValues() {
            this.filtered = this.values


            this.valueLength = this.filtered.length
            return this.filtered.slice(this.pageSize * this.page - this.pageSize, this.pageSize * this.page)
        }
    }
}
</script>

<style scoped>

</style>
