<template>
    <div style="padding-top: 24px; padding-bottom: 24px">
        <template v-if="status === 'free'">

            <div class="card">
                <div class="card-body">
                    <h2>Начало смены</h2>
                    <p>Для начала смены выберите миксер, на котором будете работать</p>
                    <el-radio-group class="w-100" v-model="selectedVehicle">
                        <el-radio v-for="(item, index) in vehicles" :key="index" :label="item" size="large"
                            class="w-100 mb-10" :border="true">{{ item.name }}
                        </el-radio>
                    </el-radio-group>
                    <el-button
                        @click="createShift()"
                        :disabled="!selectedVehicle"
                        :loading="loading"
                        class="w-100"
                        type="primary"
                    >
                        Начать смену
                    </el-button>
                </div>
            </div>
        </template>

        <template v-if="status === 'work'">
            <div
                class="d-flex header-group"
                style="justify-content: space-between;  margin-bottom: 10px;"
            >
                <el-radio-group v-model="activeTab">
                    <el-radio-button v-for="status in statuses" :key="status" :label="status">
                        {{ status }}
                    </el-radio-button>
                </el-radio-group>

                <el-date-picker
                    v-model="date"
                    type="date"
                    @change="handleDateChange"
                    @input="handleDateChange"
                    style="width: 100%"
                    class="date-picker"
                    :editable="false"
                ></el-date-picker>

            </div>

            <div v-loading="loading" class="d-flex" style="flex-direction: column; gap: 8px">
                <div v-show="activeTab === 'Очередь'">
                    <template v-if="actualOrder && actualOrder?.status !== 'new'">
                        <MixDriverTaskActual :order="actualOrder" :tz="tz"/>
                    </template>
                    <template v-else-if="mixOrders.length < 0">
                    </template>
                </div>

                <div v-show="(mixOrders.length > 0 || actualOrder?.status === 'new') && activeTab === 'Очередь'">
                    <div class="d-flex" style="justify-content: center" v-if="actualOrder && mixOrders.length > 0">
                        <span class="textRegular neutral400 textXS"
                            style="background-color: white; border-radius: 12px; padding: 4px 10px">
                            В очереди
                        </span>
                    </div>

                    <MixDriverTaskActual :tz="tz" :order="actualOrder" v-show="actualOrder?.status === 'new'" />
                    <MixDriverTaskActual :tz="tz" :order="order" v-for="(order, index) in mixOrders" :key="index" />
                </div>
                <MixDriverTaskActual v-show="mixOrdersDone.length > 0 && activeTab === 'Выполненные'" :tz="tz" :order="order" v-for="(order, index) in mixOrdersDone" :key="index" />
            </div>

            <div v-show="!loading" class="no-orders-section">
                <NoOrdersBanner :activeTab="activeTab" :mixOrders="mixOrders" :mixOrdersDone="mixOrdersDone"/>
            </div>
        </template>

        <template v-if="!status">
<!--            <h2>Нет актуальных заявок</h2>-->
        </template>

    </div>
</template>
<script>
import { httpService } from '@/services/http.service'
import {mapActions, mapState, mapStores} from "pinia";
import { useEchoStore } from "@/store/Echo";
import { useCommonStore } from "@/store/common";
import { useMixDriverStore } from "@/store/mixDriver"
import MixDriverTaskActual from "@/views/components/Dashboards/MixDriverTaskActual.vue";
import moment from "moment";
import NoOrdersBanner from "@/views/components/Dashboards/NoOrdersBanner.vue"
export default {
    name: 'MixDriverDashboard',
    components: { MixDriverTaskActual, NoOrdersBanner },
    inject: ['api'],
    data() {
        return {
            selectedDate: new Date(),
            selectedVehicle: null,
            buttonText: 'Начать загрузку',
            activeTab: 'Очередь',
            statuses: ['Очередь', 'Выполненные'],
            date: null,
        }
    },
    computed: {
        ...mapStores(useEchoStore),
        ...mapState(useMixDriverStore, {
            dateFilter: 'getDateFilter',
            vehicles: 'getVehiclesData',
            status: 'getStatus',
            loading: 'getLoading',
            mixOrdersDone: 'getMixOrdersDone',
            actualOrder: 'getActualOrder',
            mixOrders: 'getMixOrders',
            tz: 'getTZ'
        }),
        ...mapState(useCommonStore, {
            user: 'getUser',
        }),
    },
    async created() {
        this.date = this.dateFilter
        await this.getDriverInfo()

        window.Echo.channel('laravel_database_ChannelDriverMixOrders')
            .listen(`.MixOrderCreated.${this.user.id}`, () => {
                this.getDriverInfo(true)
            })
    },
    beforeCreate: function () {
        document.body.className = 'driver-bg'
    },
    beforeUnmount: function () {
        document.body.className = ''
        clearInterval(this.counterInterval)
    },
    methods: {
        ...mapActions(useMixDriverStore, [
            'setDateFilter',
            'getDriverInfo',
        ]),
        handleDateChange(value) {
            this.date = moment(value)
            this.setDateFilter(moment(value))
            this.getDriverInfo()
        },
        changeStatus() {
            this.loading = true

            httpService().post(this.api.mix.changeOrderStatus, {
                account_module_reserve_id: this.actualOrder.mixId,
                module_key: 'mix',
                order: this.actualOrder
            }).then(({ data }) => {

                if (data.success) {
                    this.getDriverInfo()
                    this.buttonText = data.buttonText
                    this.loading = false
                }

            }).catch((error) => {
                this.$message({
                    message: error.response.data.message,
                    showClose: true,
                    type: 'error'
                })
                this.loading = false
            })
        },
        createShift() {
            this.loading = true

            httpService().post(this.api.mixVehicle.createShift, {
                account_module_reserve_id: this.selectedVehicle.mixId,
                module_key: 'mix',
                vehicle: this.selectedVehicle
            }).then(({ data }) => {

                if (data.success) {
                    this.loading = false
                    this.getDriverInfo()
                }
            }).catch((error) => {
                this.$message({
                    message: error.response.data.message,
                    showClose: true,
                    type: 'error'
                })
                this.loading = false
            })
        }
    }
}
</script>

<style scoped lang="scss">
.card{
    margin-top: 10px;
}
</style>
<style lang="scss">

.header-group {

    .el-radio-group{
        background-color: #f9f9f9;
        border-radius: 8px;
    }

    .el-radio-button{
        &.is-active{
            z-index: 3;
        }
    }

    .el-radio-button:last-child .el-radio-button__inner{
        display: flex;
        padding: 8px 12px;
        align-items: flex-start;
        gap: 10px;
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: 16px;
        border-width: 0px;
        border-style: initial;
        border-image: initial;
        background-color: #f9f9f9;
        position: relative;

        //&::before {
        //    content: "";
        //    position: absolute;
        //    top: 0;
        //    left: -8px;
        //    width: 11px;
        //    height: 32px;
        //    background-color: #f9f9f9;
        //    z-index: 1;
        //}
    }

    .el-radio-button:first-child .el-radio-button__inner{
        display: flex;
        padding: 8px 12px;
        align-items: flex-start;
        gap: 10px;
        font-size: 12px;
        font-style: normal;
        font-weight: 500;
        line-height: 16px;
        border-width: 0px;
        border-style: initial;
        border-image: initial;
        background-color: #f9f9f9;
        border-left: none!important;
    }

    .el-radio-button__inner {
        box-shadow: none!important;
        z-index: 2;
    }

    .el-radio-button__original-radio:checked + .el-radio-button__inner {
        border-radius: 8px !important;
        border: 1px solid #EEEEF0 !important;
        background: #FFF !important;
        display: flex !important;
        padding: 8px 12px !important;
        align-items: flex-start !important;
        gap: 10px !important;
        color: #393F4A !important;
        font-size: 12px !important;
        font-style: normal !important;
        font-weight: 500 !important;
        line-height: 16px !important;
    }

    .el-input__wrapper {
        height: 31.6px;
        box-shadow: none !important;
        background-color: #1984e6;
        border-color: #1984e6;
        border-radius: 8px;
        width: auto !important;
        padding: 0 20px !important;

        @media (max-width: 425px) {
            padding: 0 15px !important;
        }

    }

    .el-input__suffix {
        display: none !important;
    }

    .el-input {
        width: auto !important;
    }

    .el-radio-button__inner {
        @media (max-width: em(425)) {
            padding: 8px 12px !important;
        }
    }

    .el-input__inner {
        max-width: 100px !important;
        font-family: inherit;
        width: auto !important;
        color: #FFFFFF !important;
        font-weight: 500 !important;
        font-size: 12px !important;
        line-height: 133% !important;

        @media (max-width: 425px) {
            max-width: 70px !important;
        }

        &::placeholder {
            color: #FFFFFF !important;
            font-weight: 500 !important;
            font-size: 12px !important;
            line-height: 133% !important;
        }
    }

    .el-input__prefix-inner {
        color: #FFFFFF !important;
    }
}
</style>
