<template>
    <div v-loading="loading">
        <h2 class="neutral900 mt-0 mb-5">Подтвердите данные</h2>
        <p class="neutral300 mt-0 mb-30">Подтвердите отгрузки, товары и услуги по заказу:</p>
        <el-tabs v-model="activeTab">
            <el-tab-pane label="Отгрузки" name="orders">
                <OrderMixOrders :order-group-item="order" :paginate="true" :orders="orders ?? []"/>
            </el-tab-pane>
            <el-tab-pane label="Товары" name="goods">
                <OrderServices
                    :goods-type="true"
                    :group-id="groupId"
                    :services="order.services.filter(el => el.type === 'good')"
                    :orders="orders ?? []"
                    @update="getOrder()"
                    :loading="loading"
                    :paginate="true"
                />
            </el-tab-pane>
            <el-tab-pane label="Услуги" name="services">
                <OrderServices
                    :group-id="groupId"
                    :services="order.services.filter(el => el.type !== 'good')"
                    :orders="orders ?? []"
                    @update="getOrder()"
                    :loading="loading"
                    :paginate="true"
                />
            </el-tab-pane>
            <el-tab-pane label="1C" name="oneC" v-if="oneCNeed">
                <template #label>
                    <div class="d-flex" style="gap: 10px">
                        <div>1C</div>
                        <div>
                            <svg xmlns="http://www.w3.org/2000/svg"
                                 v-if="!checkButtonDisable"
                                 height="16" x="0" y="0" viewBox="0 0 21 21"
                                 style=" position: relative; top: 3px; enable-background:new 0 0 512 512"
                                 xml:space="preserve" fill-rule="evenodd"
                                 class=""><g><path fill="#009272" d="M10.504 1.318a9.189 9.189 0 0 1 0 18.375 9.189 9.189 0 0 1 0-18.375zM8.596 13.49l-2.25-2.252a.986.986 0 0 1 0-1.392.988.988 0 0 1 1.393 0l1.585 1.587 3.945-3.945a.986.986 0 0 1 1.392 0 .987.987 0 0 1 0 1.392l-4.642 4.642a.987.987 0 0 1-1.423-.032z" opacity="1" data-original="#009272" class=""></path></g></svg>
                            <svg xmlns="http://www.w3.org/2000/svg" version="1.1"
                                 v-else
                                 xmlns:xlink="http://www.w3.org/1999/xlink" height="16" x="0" y="0"
                                 viewBox="0 0 512 512" style=" position: relative; top: 3px; enable-background:new 0 0 512 512" xml:space="preserve"
                                 class=""><g><circle cx="256" cy="256" r="235" fill="#ed2f2f" transform="rotate(-45 255.972 256.066)" opacity="1" data-original="#ed2f2f" class=""></circle><path
                                fill="#ffffff" fill-rule="evenodd"
                                d="M256 351.464c13.177 0 23.858 10.682 23.858 23.859S269.176 399.181 256 399.181s-23.859-10.682-23.859-23.858 10.682-23.859 23.859-23.859zm0-238.645c10.854 0 19.734 8.88 19.734 19.734v162.85c0 10.854-8.88 19.734-19.734 19.734-10.854 0-19.734-8.88-19.734-19.734v-162.85c0-10.854 8.88-19.734 19.734-19.734z"
                                opacity="1" data-original="#ffffff" class=""></path></g></svg>
                        </div>
                    </div>
                </template>

                <p class="textXS neutral400">Данный заказ учавствует в обмене данных с 1С. Проверьте, что в нем указаны
                    все
                    необходимые данные для проведения реализации:</p>

                <el-form label-position="top">

                    <div v-for="(attribute, index) in attributesRequired" :key="index">
                        <FormSelect
                            v-if="attribute === 'seller'"
                            v-model="order.sellerCompanyId"
                            label="Поставщик"
                            placeholder="Выберите поставщика"
                            new-item="company"
                            :new-item-data="order.sellerCompanyId"
                            company-type="seller"
                        />

                        <FormSelect
                            v-if="attribute === 'invoice'"
                            v-model="order.invoiceId"
                            :disabled="!order.companyId"
                            label="Счет"
                            :placeholder="!order.companyId ? 'Выберите контрагента' : 'Выберите счет'"
                            new-item="document"
                            :new-item-data="order.invoiceId"
                            :carrier-company="order.companyId"
                            :items="[]"
                            size="large"
                            document-type="invoice"
                        />

                        <FormSelect
                            v-if="attribute === 'contract'"
                            v-model="order.contractId"
                            :disabled="!order.companyId"
                            label="Договор поставки"
                            :placeholder="!order.companyId ? 'Выберите контрагента' : 'Выберите договор'"
                            new-item="document"
                            :new-item-data="order.contractId"
                            :carrier-company="order.companyId"
                            :items="[]"
                            size="large"
                            document-type="contract"
                        />

                        <FormNumber
                            v-if="attribute === 'productPrice'"
                            v-model="order.productPrice"
                            label="Стоимость продукции"
                            placeholder="Укажите стоимость за 1 м3"
                            precision="2"
                        />
                    </div>

                    <FormSelect
                        v-model="order.paymentMethod"
                        label="Метод оплаты"
                        placeholder="Выберите метод"
                        :items="paymentMethods"
                    />
                </el-form>

            </el-tab-pane>
        </el-tabs>
        <hr class="mt-30 mb-30"/>
        <el-button
            :disabled="checkButtonDisable"
            :icon="icons.check"
            @click="changeStatusAction()"
            type="primary">
            Подтвердить и завершить заказ
        </el-button>
    </div>
</template>


<script>
import {httpService} from "@/services/http.service";
import OrderMixOrders from "@/views/components/MixOrderGroup/components/order/OrderMixOrders.vue";
import OrderServices from "@/views/components/MixOrderGroup/components/order/OrderServices.vue";
import FormSelect from "@/views/components/Form/FormSelect.vue";
import iconsMixin from "@/mixins/iconsMixin";
import FormNumber from "@/views/components/Form/FormNumber.vue";
import paymentMethods from "@/mixins/paymentMethods";

export default {
    name: "MixOrderGroupCompletedForm",
    components: {FormNumber, FormSelect, OrderServices, OrderMixOrders},
    mixins: [iconsMixin, paymentMethods],
    props: ['groupId'],
    inject: ['api'],
    data() {
        return {
            loading: true,
            activeTab: 'orders',
            attributes: [],
            order: {
                id: this.groupId,
                paymentMethod: null,
                companyId: null,
                goodId: null,
                total: 0,
                done: 0,
                status: null,
                services: []
            },
            events: [],
            orders: [],
            users: [],
            info: {
                pump: false,
                deliveryAddressId: null,
                companyManager: null,
                mixIds: [],
                managerId: null,
                productPrice: 0,
                deliveryPrice: 0,
                deliveryType: null,
                prepayment: 0,
                gidrolotok: false,
                intervalType: null,
                timeFreeUnloading: 0,
                timeIntervalClient: 0,
                maxVolume: null,
                maxAxle: null,
                commentDriver: null
            },
            oneCNeed: false,
            oneCSettings: null,
            attributesRequired: [],
        }
    },
    async created() {
        await this.getOrder()
        await this.checkOneCIntegration()
    },
    computed: {
        checkButtonDisable() {
            let result = false
            if (this.oneCNeed) {
                console.log(this.attributesRequired)
                this.attributesRequired.forEach(el => {
                    if (!result && el === 'seller' && !this.order.sellerCompanyId) {
                        result = true
                    } else if (!result && el === 'invoice' && !this.order.invoiceId) {
                        result = true
                    } else if (!result && el === 'contract' && !this.order.contractId) {
                        result = true
                    } else if (!result && el === 'productPrice' && (!this.order.productPrice || this.order.productPrice === 0)) {
                        result = true
                    }
                })
            }

            return result
        },
    },
    methods: {
        async getOrder() {
            await httpService().post(this.api.mixOrderGroup.getOrder, {
                account_modules_id: this.$route.params.id,
                module_key: this.$route.meta.moduleKey,
                orderId: this.groupId,
                type: 'full'
            }).then((response) => {
                let data = response.data

                if (data.success) {
                    this.order = data.order
                    this.events = data.order.events
                    this.orders = data.order.orders
                    this.info = data.order.info
                }
            }).catch((error) => {
                this.$message({
                    message: error.response.data.message,
                    showClose: true,
                    type: 'error'
                })
            })
        },
        async checkOneCIntegration() {
            await httpService().post(this.api.mixOrderGroup.checkOneCIntegration, {
                account_modules_id: this.$route.params.id,
                module_key: this.$route.meta.moduleKey,
                orderId: this.groupId
            }).then((response) => {
                let data = response.data

                if (data.success) {
                    this.oneCNeed = true
                    this.oneCSettings = data.settings
                    this.attributesRequired = data.attributes
                    this.loading = false
                } else {
                    this.loading = false
                }
            }).catch((error) => {
                this.loading = false
                this.$message({
                    message: error.response.data.message,
                    showClose: true,
                    type: 'error'
                })
            })
        },
        changeStatusAction() {
            this.loading = true

            httpService().post(this.api.mixOrderGroup.changeStatus, {
                account_modules_id: this.$route.params.id,
                module_key: this.$route.meta.moduleKey,
                orderId: this.groupId,
                status: 'completed',
                order: this.order
            }).then((response) => {
                let data = response.data

                if (data.success) {
                    this.$emit('closeCompletedForm')
                }
            }).catch((error) => {
                this.$message({
                    message: error.response.data.message,
                    showClose: true,
                    type: 'error'
                })
                this.loading = false
            })
        }
    }
}
</script>

<style scoped>

</style>
